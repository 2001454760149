import React from "react";
import { ButtonBase, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthStates } from "../utils/globalStates";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../firebase/auth";

import { signOut } from "firebase/auth";

import "./Header.css";

const logo = require("../../static/logoWhite.png");

export default function MenuDrawer({ setIsDrawerOpen }: any) {
  const authStates = useAuthStates((state) => state);
  const [user, loading, error] = useAuthState(firebaseAuth);

  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    setIsDrawerOpen(false);
    navigate(path);
  };

  const styles = {
    signoutButton: {
      color: "white",
      marginBottom: "20px",
      marginTop: "30px",
      border: `1px solid #fff`,
      padding: "3px",
      paddingLeft: "10px",
      paddingRight: "10px",
      "&:hover": {
        color: "#44a99a",
        backgroundColor: "white",
      },
    },
  };

  return (
    <div className="MenuDrawer">
      <img src={logo} className="DrawerLogo" alt="logo" />
      <div className="appMenuLinksOuter" style={{flex: 3}}>
        <ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "80%" }}
          onClick={() => handleNavigation("/dashboard")}
        >
          <p className="appMenuLinkTitle" style={{ color: "white" }}>
            DASHBOARD
          </p>
        </ButtonBase>
        <ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "80%" }}
          onClick={() => handleNavigation("/farms")}
        >
          <p className="appMenuLinkTitle" style={{ color: "white" }}>
            FARMS
          </p>
        </ButtonBase>
        <ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "80%" }}
          onClick={() => handleNavigation("/grows")}
        >
          <p className="appMenuLinkTitle" style={{ color: "white" }}>
            GROWS
          </p>
        </ButtonBase>
        <ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "80%" }}
          onClick={() => handleNavigation("/devices")}
        >
          <p className="appMenuLinkTitle" style={{ color: "white" }}>
            DEVICES
          </p>
        </ButtonBase>
        <ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "80%" }}
          onClick={() => handleNavigation("/labels")}
        >
          <p className="appMenuLinkTitle" style={{ color: "white" }}>
            LABELS
          </p>
        </ButtonBase>
      </div>
      {(authStates.role === "admin" || authStates.role === "owner") && (
        <div className="appMenuLinksOuter">
          <p className="appMenuLinkSub" style={{ color: "white" }}>
            ADMIN
          </p>
          <ButtonBase
            sx={{ padding: 0, marginTop: "0px", width: "80%" }}
            onClick={() => handleNavigation("/sweden")}
          >
            <p className="appMenuLinkTitle" style={{ color: "white" }}>
              OSKARSTRÖM
            </p>
          </ButtonBase>
        </div>
      )}
      {authStates.role === "owner" && (
        <div className="appMenuLinksOuter">
          <p className="appMenuLinkSub" style={{ color: "white" }}>
            OWNER
          </p>
          <ButtonBase
            sx={{ padding: 0, marginTop: "0px", width: "80%" }}
            onClick={() => handleNavigation("/owner/plantenums")}
          >
            <p className="appMenuLinkTitle" style={{ color: "white" }}>
              PLANT ENUMS
            </p>
          </ButtonBase>
        </div>
      )}
      <Button
        size="large"
        sx={styles.signoutButton}
        variant="text"
        onClick={() => signOut(firebaseAuth)}
      >
        Sign out
      </Button>
    </div>
  );
}
