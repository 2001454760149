import React, { useEffect, useState } from "react";
import {
  getAuth,
  signOut,
  browserSessionPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  collectionGroup,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import AddPlant from "./active/AddPlant";
import DarkPlants from "./active/DarkPlants";
import LightPlants from "./active/LightPlants";
import { firebaseDB } from "../../components/firebase/firestore";
import { plantInfoNew } from "../../components/utils/enums";

import {
  ActivePlantArrayIF,
  ActivePlantIF,
  PlantArrayIF,
} from "../../components/interfaces/Interfaces";
import "./dashboard.css";

let outerLoading = false;
const DashBoardActive = () => {
  const [firebaseLoading, setFirebaseLoading] = useState(false);
  const [activePlants, setActivePlants] = useState<ActivePlantArrayIF | null>(
    null
  );
  const [inDark, setInDark] = useState<ActivePlantArrayIF>([]);
  const [inLight, setInLight] = useState<ActivePlantArrayIF>([]);

  const sortActivePlants = async () => {
    if (activePlants === null) {
      return;
    }
    let tempDark = [];
    let tempLight = [];
    console.log("init sorting");
    for (let i = 0; i < activePlants.length; i++) {
      console.log("sorting " + i);
      if (activePlants[i].lightActive !== null) {
        tempLight.push(activePlants[i]);
      } else {
        tempDark.push(activePlants[i]);
      }
    }

    setInDark(tempDark);
    setInLight(tempLight);
  };

  useEffect(() => {
    console.log("sort");
    if (activePlants !== null) {
      console.log("sorting");
      sortActivePlants();
    }
  }, [activePlants]);

  useEffect(() => {
    const plantQuery = query(
      collectionGroup(firebaseDB, "plantData"),
      where("end", "==", null)
    );
    const unsubscribe = onSnapshot(plantQuery, (querySnapshot) => {
      outerLoading = true;
      setFirebaseLoading(true);
      let tempPlants: ActivePlantArrayIF = [];
      querySnapshot.forEach((doc) => {
        let tempObj = { firebaseId: doc.id, checked: false, ...doc.data() };
        //@ts-ignore
        tempPlants.push(tempObj);
      });
      setActivePlants(tempPlants);
      setFirebaseLoading(false);
      outerLoading = false;
    });

    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe();
  }, []);
  return (
    <div className="DashBoardTabInner">
      <AddPlant />
      {activePlants !== null ? (
        <div className="DashboardActive">
          {inDark.length > 0 ? <DarkPlants plantArr={inDark} /> : null}
          {inLight.length > 0 ? <LightPlants plantArr={inLight} /> : null}
        </div>
      ) : (
        <CircularProgress color="inherit" />
      )}
    </div>
  );
};

export default DashBoardActive;
