import React, { useEffect, useState } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import CircularProgress from "@mui/material/CircularProgress";

import { firebaseAuth } from "../../components/firebase/auth";
import DashBoard from "../dashboard/Dashboard";
import Farms from "../farms/Farms";
import LabelsNew from "../labels/LabelsNew";
import DashboardNew from "../dashboardNew/DashboardNew";
import Grows from "../grows/Grows";
import Devices from "../devices/Devices";
import Plants from "../plants/Plants";
// Owner
import OwnerStart from "../owner/OwnerStart";

import "../../App.css";

function Start() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();

  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    if (user === null && loading === false) {
      navigate("/login");
      setAuthLoading(false);
    } else if (user !== null && loading === false) {
      setAuthLoading(false);
    }
  }, [loading, user]);

  return (
    <div className="App">
      {authLoading ? <CircularProgress color="inherit" /> : (
        <Routes>
          <Route path="/" element={<DashBoard />} />
          <Route path="dashboard" element={<DashboardNew />} />
          <Route path="farms" element={<Farms />} />
          <Route path="grows" element={<Grows />} />
          <Route path="devices" element={<Devices />} />
          <Route path="plants" element={<Plants />} />
          <Route path="labels" element={<LabelsNew />} />
          <Route path="sweden" element={<DashBoard />} />
          <Route path="owner/*" element={<OwnerStart />} />
        </Routes>
      )}
    </div>
  );
}

export default Start;
