import React, { useEffect, useState } from "react";
import {
  getAuth,
  signOut,
  browserSessionPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import { useNavigate } from "react-router-dom";

import "./PlantEnums.css";

function PlantEnums() {
  const [user, loading, error] = useAuthState(firebaseAuth);
    const navigate = useNavigate();

  const [tabValue, setTabValue] = useState("1");
  const [authLoading, setAuthLoading] = useState(true);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (user === null && loading === false) {
      navigate("/login");
    } else if (user !== null && loading === false) {
      setAuthLoading(false);
    }
  }, [loading]);

  return (
    <div className="PlantEnums">
      PlantEnums
    </div>
  );
}

export default PlantEnums;