import React, { useEffect, useState } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import CircularProgress from "@mui/material/CircularProgress";

import { useAuthStates } from "../../components/utils/globalStates";
import { firebaseAuth } from "../../components/firebase/auth";

// Owner
import PlantEnums from "../owner/plantEnums/PlantEnums";

function OwnerStart() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const authStates = useAuthStates();
  const navigate = useNavigate();

  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    if (user === null && loading === false) {
      navigate("/login");
      setAuthLoading(false);
    } else if (
      user !== null &&
      loading === false &&
      authStates.userDataLoaded === true
    ) {
      if (authStates.role === "owner") {
        setAuthLoading(false);
      } else {
        navigate("/");
        setAuthLoading(false);
      }
    }
  }, [loading, user, authStates.userDataLoaded]);

  return (
    <>
      {authLoading ? (
        <CircularProgress color="inherit" />
      ) : (
        <Routes>
          <Route path="/" element={<PlantEnums />} />
          <Route path="plantenums" element={<PlantEnums />} />
        </Routes>
      )}
    </>
  );
}

export default OwnerStart;
