import { PlantArrayIF, MonitorOuterArrayIF } from "../interfaces/Interfaces";

export const plantInfoNew: PlantArrayIF = [
  {
    name: ["Asiatisk Mix", "Asia Mix"], //
    mustard: true,
    id: "asia-tray",
    link: "asiamix",
    latin: " - ",
    tray: 280,
    size: [40, 100],
    ean: ["7350127311705", "7350127311712"],
    storeData: ["Kylvara", "Refrigeration"],
    leadTime: 14,
    storeTemp: 8,
    water: 3,
    shelfLife: "7-10",
    emissionFactor: 1.1,
    rateStore: 2,
    rateTaste: 2.5,
    rateSpicy: 2.5,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fasianmix.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix4.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix5.jpeg?alt=media",
    ],
    subtitle: [
      "En blandning av Tatsoi, Pak Choi, Röd Komatsuna, Kålrot & Rädisa som tillsammans skapar ljuva toner av Asien i både färg och smak.",
      "A mixture of Tatsoi, Pak Choi, Red Komatsuna, Cabbage & Radish that together create sweet tones of Asia in both color and taste.",
    ],
    menuName: ["Asiatisk Mix - Påse", "Asia Mix - Bag"],
    color: [
      "En ljuvlig mix av grönt, lila & rött",
      "A vibrant mix of green, purple & red",
    ],
    flavor: ["Söt med peppriga inslag", "Sweet with hints of pepper"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Denna blandning är packad med en mängd olika smaker och näringsämnen, inklusive Tatsoi, Pak Choi, Rova, Komatsuna och Rädisa, vilket ger en mångsidig blandning av mikrogrönsaker att njuta av.",
        "The Asian Mix - This blend is packed with a variety of flavors and nutrients, including Tatsoi, Pak Choi, Turnip, Komatsuna, and Radish, providing a diverse mix of microgreens to enjoy.",
      ],
      [
        "Tatsoi, även känd som spenatsenap, är en bladgrönsak som innehåller mycket vitamin A, C och K, samt kalcium och järn. Dess peppriga smak ger en pigg kick till sallader, smörgåsar och röror.",
        "Tatsoi, also known as spinach mustard, is a leafy green that is high in vitamin A, C, and K, as well as calcium and iron. Its peppery flavor adds a zesty kick to salads, sandwiches, and stir-fries.",
      ],
      [
        "Pak Choi, även känd som kinakål, är en vanlig ingrediens i det asiatiska köket. Den är låg i kalorier och hög i fiber, vitamin C och vitamin K. Dess milda smak och krispiga konsistens gör den perfekt för röror, soppor och grytor.",
        "Pak Choi, also known as Chinese cabbage, is a common ingredient in Asian cuisine. It is low in calories and high in fiber, vitamin C, and vitamin K. Its mild flavor and crispy texture make it perfect for stir-fries, soups, and stews.",
      ],
      [
        "Mikrogrönt av kålrot ger en mild, söt och lätt bitter smak, vilket ger en uppfriskande smak och crunch till dina måltider. De är höga i vitamin C, K och fiber och kan användas i en mängd olika rätter.",
        "Turnip microgreens offer a mild, sweet, and slightly bitter taste, adding a refreshing flavor and crunch to your meals. They are high in vitamin C, K, and fiber, and can be used in a variety of dishes.",
      ],
      [
        "Komatsuna, även känd som japansk senapsspenat, innehåller mycket C-vitamin och järn. Dess smak är en kombination av spenat och senapsgrönsaker, med en syrlig och lite bitter smak. Den kan ätas rå i sallader eller tillagas i soppor och röror.",
        "Komatsuna, also known as Japanese mustard spinach, is high in vitamin C and iron. Its flavor is a combination of spinach and mustard greens, with a tangy and slightly bitter taste. It can be eaten raw in salads or cooked in soups and stir-fries.",
      ],
      [
        "Rädisor är rika på antioxidanter, C-vitamin och folat och ger en mild pepprig smak till varje maträtt. De är perfekta för smörgåsar, sallader och garnering av rätter.",
        "Radish microgreens are rich in antioxidants, vitamin C, and folate, and add a mild peppery flavor to any dish. They are perfect for sandwiches, salads, and garnishing dishes.",
      ],
      [
        "Vår asiatiska blandning är den perfekta mixen för dig som vill uppleva en blandning av smaker i samma förpackning. Odlade med precision och omsorg, ser vi till att våra mikrogröna är av högsta kvalitet och fräschhet. Prova vår Asiatiska Mix idag och njut av smaken av Asien i ditt eget kök!",
        "Our Asian Mix is the perfect blend for those who want to experience a mix of flavors and nutrients in one package. Grown with precision and care, we ensure that our microgreens are of the highest quality and freshness. Try our Asian Mix today and enjoy the taste of Asia in your own kitchen!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Asiatisk Mix är en bra tillsats till salladen. Dess variation av texturer och smaker kan lägga till ett extra lager av komplexitet och näring till din maträtt.",
        "Salads: The Asian Mix is a great addition to any salad. Its variety of textures and flavors can add an extra layer of complexity and nutrition to your dish.",
      ],
      [
        "Smörgåsar och wraps: Lägg till den asiatiska mixen på din favoritmacka eller wrap för extra crunch och smak.",
        "Sandwiches and wraps: Add the Asian Mix to your favorite sandwich or wrap for an extra crunch and flavor.",
      ],
      [
        "Wok-rätter: Asian Mix är en perfekt tillsats till wok-rätter. Dess milda och lätt bittra smak passar bra med en mängd olika såser och proteiner.",
        "Stir-fries: The Asian Mix is a perfect addition to stir-fries. Its mild and slightly bitter flavor pairs well with a variety of sauces and proteins.",
      ],
      [
        "Soppor: Lägg till den asiatiska mixen i soppor och grytor för en extra lager av smak och näring.",
        "Soups: Add the Asian Mix to soups and stews for an extra layer of flavor and nutrition.",
      ],
      [
        "Garnering: Använd Asiatisk Mix som garnering till valfri maträtt. Dess färgglada och unika utseende kan lägga till färg och intresse till din tallrik.",
        "Garnish: Use the Asian Mix as a garnish for any dish. Its colorful and unique appearance can add a pop of color and interest to your plate.",
      ],
      [
        "Smoothies: Tillsätt en handfull av den asiatiska mixen till ditt favoritsmoothierecept för en extra boost av näring och smak.",
        "Smoothies: Add a handful of the Asian Mix to your favorite smoothie recipe for an extra boost of nutrition and flavor.",
      ],
    ],
    nutrientList: [
      [
        "Rikt på vitaminer och mineraler: Var och en av mikrogrönsakerna i denna blandning är en stor källa till vitaminer och mineraler, som vitamin A, C, K och järn. Dessa näringsämnen är viktiga för att upprätthålla ett hälsosamt immunförsvar, starka ben och god syn.",
        "Rich in vitamins and minerals: Each of the microgreens in this blend is a great source of vitamins and minerals, such as vitamin A, C, K, and iron. These nutrients are essential for maintaining a healthy immune system, strong bones, and good vision.",
      ],
      [
        "Lågt kaloriinnehåll: Asiatiska mixen innehåller få kalorier, vilket gör dem till ett bra komplement till alla hälsosamma dieter. De är också höga i fiber, vilket hjälper till att hålla dig mätt och nöjd.",
        "Low in calories: The Asian Mix microgreens are low in calories, making them a great addition to any healthy diet. They are also high in fiber, which helps to keep you feeling full and satisfied.",
      ],
      [
        "Stödjer hjärthälsan: Kålrot innehåller föreningar som har visat sig ha en positiv effekt på hjärthälsan genom att hjälpa till att sänka kolesterolnivåerna.",
        "Supports heart health: Turnip microgreens contain compounds that have been shown to have a positive effect on heart health by helping to lower cholesterol levels.",
      ],
      [
        "Rik på antioxidanter: Rädisan i den asiatiska mixen innehåller särskilt många antioxidanter, som hjälper till att skydda dina celler från skador orsakade av fria radikaler.",
        "Antioxidant-rich: Radish microgreens in the Asian Mix are particularly high in antioxidants, which help to protect your cells from damage caused by free radicals.",
      ],
      [
        "Förbättrar matsmältningen: Pak Choi-mikrogrönt innehåller mycket fiber och kan hjälpa till att stödja en hälsosam matsmältning. De innehåller också föreningar som har visat sig ha en positiv effekt på tarmhälsan.",
        "Boosts digestion: Pak Choi microgreens are high in fiber and can help to support healthy digestion. They also contain compounds that have been shown to have a positive effect on gut health.",
      ],
      [
        "Hjälper till att reglera blodsockret: Tatsoi-mikrogrönt innehåller föreningar som kan hjälpa till att reglera blodsockernivåerna och kan vara fördelaktiga för personer med typ 2-diabetes.",
        "Helps to regulate blood sugar: Tatsoi microgreens contain compounds that can help to regulate blood sugar levels and may be beneficial for people with type 2 diabetes.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'bag'
  },
  {
    name: ["Ärtskott", "Pea Shoots"], //
    mustard: false,
    id: "peas-tray",
    link: "peas",
    latin: "Pisum sativum",
    tray: 800,
    size: [100, 200, 500],
    ean: ["7350127310005", "7350127310012", "7350127310029"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 15,
    water: 6,
    shelfLife: "14",
    emissionFactor: 1.2,
    rateStore: 3,
    rateTaste: 2,
    rateSpicy: 1,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fpeas.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpeas3.jpg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpeas1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpeas2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpeas3.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpeas4.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpeas5.jpg?alt=media",
    ],
    subtitle: [
      "Rik på proteiner, antioxidanter & vitaminer och anses vara en riktig näringsbomb. Lätt och frisk smak av ärta som är god i salladen, som garnering eller på smörgåsen.",
      "Rich in proteins, antioxidants and vitamins, it is considered a real nutritional bomb. Light and fresh taste of pea that is good in the salad, as a garnish or on the sandwich.",
    ],
    menuName: ["Ärtskott - Påse", "Pea Shoots - Bag"],
    color: ["Från ljus- till mörkgrön", "From light to dark green"],
    flavor: ["Mild söt smak av ärta", "Mild, sweet pea flavor"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Ärtskott är ett läckert och näringsrikt tillskott till vilken måltid som helst! Dessa delikata grönsaker skördas från unga ärtplantor och har en lätt söt och nötaktig smak. De är packade med vitaminer och mineraler, inklusive vitamin A, C och K, samt folat och fibrer. Ärtskott är också låga i kalorier och höga i antioxidanter, vilket gör dem till ett utmärkt val för dem som vill förbättra sin allmänna hälsa.",
        "Pea shoots are a delicious and nutritious addition to any meal! These delicate and tender greens are harvested from young pea plants and have a slightly sweet and nutty flavor. They are packed with vitamins and minerals, including vitamins A, C, and K, as well as folate and fiber. Pea shoots are also low in calories and high in antioxidants, making them a great choice for those looking to boost their overall health.",
      ],
      [
        "Förutom dess hälsofördelar är ärtskott otroligt mångsidiga och kan avnjutas på en mängd olika sätt. De är ett utmärkt tillskott till sallader, smörgåsar, röror och mer. De kan också blandas i smoothies eller användas som garnering till soppor och andra rätter. Våra ärtskott odlas lokalt med hjälp av hållbara odlingsmetoder, vilket säkerställer att du får den färskaste och högsta möjliga produkten. De skördas noggrant och förpackas för att säkerställa maximal fräschör och smak. Ge våra ärtskott en chans och upptäck en ny favoritingrediens för dina måltider!",
        "In addition to their health benefits, pea shoots are incredibly versatile and can be enjoyed in a variety of ways.They make a great addition to salads, sandwiches, stir - fries, and more.They can also be blended into smoothies or used as a garnish for soups and other dishes. Our pea shoots are grown locally using sustainable farming practices, ensuring that you get the freshest and highest- quality product possible.They are carefully harvested and packaged to ensure maximum freshness and flavor.Give our pea shoots a try and discover a new favorite ingredient for your meals!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Ärtskott ger en fräsch, söt och lätt nötig smak till sallader och ger även en krispig konsistens.",
        "Salads: Pea shoots add a fresh, sweet and slightly nutty flavor to salads, and also offer a crisp texture.",
      ],
      [
        "Smörgåsar och wraps: Använd ärtskott som ett fräscht och knaprigt tillskott till smörgåsar och wraps.",
        "Sandwiches and wraps: Use pea shoots as a fresh and crunchy addition to sandwiches and wraps.",
      ],
      [
        "Wokade rätter: Ärtskott kan snabbt tillagas i en wok för ett läckert och hälsosamt tillskott till din måltid.",
        "Stir-fries: Pea shoots can be quickly cooked in a stir-fry for a delicious and healthy addition to your meal.",
      ],
      [
        "Soppor och grytor: Lägg till ärtskott i soppor och grytor som garnering eller för extra näring och konsistens.",
        "Soups and stews: Add pea shoots to soups and stews as a garnish or for extra nutrition and texture.",
      ],
      [
        "Smoothies och juicer: Ärtskott kan blandas i smoothies eller juicer för en extra boost av vitaminer och mineraler.",
        "Smoothies and juices: Pea shoots can be blended into smoothies or juiced for an extra boost of vitamins and minerals.",
      ],
      [
        "Toppings: Strö ärtskott ovanpå pizza, pastarätter och andra måltider för extra smak och näring.",
        "Toppings: Sprinkle pea shoots on top of pizza, pasta dishes, and other meals for added flavor and nutrition.",
      ],
    ],
    nutrientList: [
      [
        "Rika på näringsämnen: Ärtskott är fullpackade med vitaminer och mineraler, inklusive vitamin A, C och K, folat och fiber.",
        "Rich in nutrients: Pea shoots are packed with vitamins and minerals, including vitamins A, C, and K, folate, and fiber.",
      ],
      [
        "Antioxidantegenskaper: Ärtskott innehåller mycket antioxidanter, vilket kan hjälpa till att skydda kroppen från skador orsakade av fria radikaler.",
        "Antioxidant properties: Pea shoots are high in antioxidants, which can help protect the body from damage caused by free radicals.",
      ],
      [
        "Kan stödja hälsosam matsmältning: Fiberinnehållet i ärtskott kan stödja en hälsosam matsmältning och kan hjälpa till att förhindra förstoppning.",
        "May support healthy digestion: The fiber content in pea shoots can support healthy digestion and may help prevent constipation.",
      ],
      [
        "Kan sänka kolesterolnivåerna: Vissa studier tyder på att ärtskott kan hjälpa till att sänka kolesterolnivåerna, vilket potentiellt minskar risken för hjärtsjukdomar.",
        "May lower cholesterol levels: Some studies suggest that pea shoots may help lower cholesterol levels, potentially reducing the risk of heart disease.",
      ],
      [
        "Kan ha antiinflammatoriska egenskaper: Ärtskott innehåller föreningar som har visat sig ha antiinflammatoriska egenskaper, vilket kan vara fördelaktigt för dem som har inflammatoriska tillstånd.",
        "May have anti-inflammatory properties: Pea shoots contain compounds that have been shown to have anti-inflammatory properties, which may be beneficial for those with inflammatory conditions.",
      ],
      [
        "Kan stödja immunförsvaret: Vitaminerna och mineralerna i ärtskott, särskilt vitamin C, kan hjälpa till att stödja immunförsvaret och skydda mot sjukdomar.",
        "May support immune function: The vitamins and minerals in pea shoots, particularly vitamin C, may help support immune function and protect against illness.",
      ],
    ],
    cool: "cool2",
    ec: "ec2",
    hum: "hum2",
    light: "light2",
    ph: "ph2",
    pump: "pump2",
    spray: "spray2",
    temp: "temp2",
    waterTemp: "waterTemp2",
    type: 'bag'
  },
  {
    name: ["Rädisa", "Radish"], //
    mustard: false,
    id: "radish-tray",
    link: "chinarose",
    latin: "Raphanus sativus",
    tray: 400,
    size: [40, 100],
    ean: ["7350127310104", "7350127310111"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 12,
    water: 2,
    shelfLife: "14",
    emissionFactor: 0.9,
    rateStore: 2,
    rateTaste: 3,
    rateSpicy: 2.5,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fradish.png?alt=media",
    logo: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2FradishLogo.png?alt=media",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fchinarose3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fchinarose1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fchinarose2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fchinarose3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fchinarose4.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fchinarose5.jpg?alt=media",
    ],
    subtitle: [
      "Krispig mikrogrönt med en distinkt smak av rädisa som är rik på kolhydrater, mineraler & vitamin C. Passar bra som garnering, i salladen eller på smörgåsen.",
      "Crispy microgreens with a distinct taste of radish that is rich in carbohydrates, minerals & vitamin C. Goes well as a garnish, in the salad or on the sandwich.",
    ],
    menuName: ["Rädisa - China Rose - Påse", "Radish - China Rose - Bag"],
    color: [
      "Gröna blad med fina rosa-vita stjälkar",
      "Green leaves with pink-white stems",
    ],
    flavor: ["Pepprig smak av rädisa", "Peppery taste of radish"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Mikrorädisor är det perfekta tillskottet till alla måltider, eftersom de ger en smak- och näringsmässig boost i en liten förpackning. Mikrogrönt är de unga plantorna av olika grönsaker och örter, som skördas när de bara är några centimeter höga. Detta gör dem till en mycket koncentrerad källa till näringsämnen.",
        "Radish microgreens are the perfect addition to any meal, providing a punch of flavor and nutrition in a small package. Microgreens are the young seedlings of various vegetables and herbs that are harvested when they are just a few inches tall, making them a highly concentrated source of nutrients.",
      ],
      [
        "Mikrorädisor har en distinkt pepprig smak som ger en explosion av smak till sallader, smörgåsar och mer. De är också otroligt näringsrika och innehåller höga halter av vitamin C, folat och antioxidanter. Forskning har visat att mikrorädisor kan innehålla upp till 40 gånger mer näringsämnen än sina mogna motsvarigheter.",
        "Radish microgreens have a distinct peppery taste, adding a burst of flavor to salads, sandwiches, and more. They are also incredibly nutritious, containing high levels of vitamin C, folate, and antioxidants. In fact, research has shown that radish microgreens can contain up to 40 times more nutrients than their mature counterparts.",
      ],
      [
        "EVåra mikrorädisor odlas endast med ekologiska, icke-GMO-frön och sköts noggrant för att säkerställa högsta kvalitet. De är lätta att odla och kan avnjutas året runt, vilket gör dem till ett utmärkt komplement till alla kök eller restauranger.",
        "Our radish microgreens are grown using only organic, non-GMO seeds and are carefully tended to ensure the highest quality product. They are easy to grow and can be enjoyed year-round, making them a great addition to any kitchen or restaurant.",
      ],
      [
        "Sammanfattningsvis är mikrorädisor ett läckert och näringsrikt tillskott till alla måltider, fyllda med smak och viktiga näringsämnen. Ge dem en chans och upplev själv vilken skillnad dessa små gröna kan göra!",
        "In summary, radish microgreens are a delicious and nutritious addition to any meal, packed with flavor and essential nutrients. Give them a try and see for yourself the difference that these tiny greens can make!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Mikrorädisor ger en pepprig crunch till sallader och är ett bra substitut för traditionella gröna sallader.",
        "Salads: Radish microgreens add a peppery crunch to salads and are a great substitute for traditional salad greens.",
      ],
      [
        "Smörgåsar och wraps: Lägg till mikrorädisor till smörgåsar och wraps för en explosion av smak och extra näring.",
        "Sandwiches and Wraps: Add radish microgreens to sandwiches and wraps for a burst of flavor and extra nutrition.",
      ],
      [
        "Tacos och burritos: Mikrorädisor passar bra i det mexikanska köket och kan inkluderas i tacos och burritos för extra crunch och smak.",
        "Tacos and Burritos: Radish microgreens pair well with Mexican cuisine and can be added to tacos and burritos for added crunch and flavor.",
      ],
      [
        "Soppor och grytor: Rädisor kan läggas till som garnering till soppor och grytor för att ge textur och smak.",
        "Soups and Stews: Radish microgreens can be added as a garnish to soups and stews to add texture and flavor.",
      ],
      [
        "Smoothies och juicer: Mikrorädisor kan läggas till smoothies och juicer för en näringsboost.",
        "Smoothies and Juices: Radish microgreens can be added to smoothies and juices for a nutrient boost.",
      ],
      [
        "Wok: Mikrorädisor kan läggas till wokrätter för en fräsch och pepprig smak.",
        "Stir-Fries: Radish microgreens can be added to stir-fries for a fresh and peppery flavor.",
      ],
    ],
    nutrientList: [
      [
        "Rika på antioxidanter: Rädisa-mikrogrönt innehåller mycket antioxidanter, vilka hjälper till att skydda kroppen mot skadliga fria radikaler och minskar risken för kroniska sjukdomar, såsom cancer, hjärtsjukdomar och Alzheimers sjukdom.",
        "Rich in Antioxidants: Radish microgreens are high in antioxidants, which help protect the body against harmful free radicals and reduce the risk of chronic diseases such as cancer, heart disease, and Alzheimer's disease.",
      ],
      [
        "Ökar immunförsvaret: Mikrogröna rädisor är rika på vitamin C, vilket är nödvändigt för ett hälsosamt immunförsvar. Vitamin C hjälper också kroppen att ta upp järn, vilket är viktigt för den allmänna hälsan.",
        "Boosts Immune System: Radish microgreens are rich in vitamin C, which is essential for a healthy immune system. Vitamin C also helps the body absorb iron, which is important for overall health.",
      ],
      [
        "Stöder matsmältningshälsan: Rädisor innehåller fibrer, vilket hjälper till att stödja matsmältningshälsan och förhindrar förstoppning. De innehåller också enzymer som hjälper till med matsmältningen.",
        "Supports Digestive Health: Radish microgreens contain fiber, which helps support digestive health and prevent constipation. They also contain enzymes that aid in digestion.",
      ],
      [
        "Antiinflammatorisk: Rädisor innehåller antiinflammatoriska föreningar som kan hjälpa till att minska inflammationen i kroppen och minska risken för kroniska sjukdomar, såsom artrit.",
        "Anti-inflammatory: Radish microgreens contain anti-inflammatory compounds that may help reduce inflammation in the body and lower the risk of chronic diseases such as arthritis.",
      ],

      [
        "Sänker kolesterolnivåerna: Rädisor innehåller föreningar som kan hjälpa till att sänka kolesterolnivåerna och minska risken för hjärtsjukdomar.",
        "Lowers Cholesterol: Radish microgreens contain compounds that may help lower cholesterol levels and reduce the risk of heart disease.",
      ],
      [
        "Högt innehåll av näringsämnen: Rädisor är en koncentrerad näringskälla som innehåller höga halter av vitaminer och mineraler, såsom vitamin A, vitamin K, kalcium och järn.",
        "High in Nutrients: Radish microgreens are a concentrated source of nutrients, containing high levels of vitamins and minerals such as vitamin A, vitamin K, calcium, and iron.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'bag'
  },
  {
    name: ["Lila Rädisa", "Purple Radish"], //
    mustard: false,
    id: "radishSango-tray",
    link: "sango",
    latin: "Raphanus sativus",
    tray: 320,
    size: [40, 100],
    ean: ["7350127310203", "7350127310210"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 12,
    water: 2,
    shelfLife: "14",
    emissionFactor: 0.9,
    rateStore: 2,
    rateTaste: 2.5,
    rateSpicy: 2,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fradish.png?alt=media",
    logo: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2FpurpleRadishLogo.png?alt=media",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fsango3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fsango1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fsango2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fsango3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fsango4.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fsango5.jpg?alt=media",
    ],
    subtitle: [
      "En näringsbomb fylld med antioxidanter och högkvalitativa proteiner. Snål på fett men rik på protein, fiber, omega-3 och mikronäringsämnen. Den lila färgen piffar upp sallad eller kötträtten.",
      "A nutritional bomb filled with antioxidants and high-quality proteins. Low in fat but rich in protein, fibre, omega-3 and micronutrients. The purple color brightens up a salad or a meat dish.",
    ],
    menuName: ["Rädisa - Sango - Påse", "Radish - Sango - Bag"],
    color: [
      "Ljuslila blad och mörklila stjälkar",
      "Light purple leaves and dark purple stems",
    ],
    flavor: ["Pepprig smak av rädisa", "Peppery taste of radish"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Mikrorädisor är det perfekta tillskottet till alla måltider, eftersom de ger en smak- och näringsmässig boost i en liten förpackning. Mikrogrönt är de unga plantorna av olika grönsaker och örter, som skördas när de bara är några centimeter höga. Detta gör dem till en mycket koncentrerad källa till näringsämnen.",
        "Radish microgreens are the perfect addition to any meal, providing a punch of flavor and nutrition in a small package. Microgreens are the young seedlings of various vegetables and herbs that are harvested when they are just a few inches tall, making them a highly concentrated source of nutrients.",
      ],
      [
        "Mikrorädisor har en distinkt pepprig smak som ger en explosion av smak till sallader, smörgåsar och mer. De är också otroligt näringsrika och innehåller höga halter av vitamin C, folat och antioxidanter. Forskning har visat att mikrorädisor kan innehålla upp till 40 gånger mer näringsämnen än sina mogna motsvarigheter.",
        "Radish microgreens have a distinct peppery taste, adding a burst of flavor to salads, sandwiches, and more. They are also incredibly nutritious, containing high levels of vitamin C, folate, and antioxidants. In fact, research has shown that radish microgreens can contain up to 40 times more nutrients than their mature counterparts.",
      ],
      [
        "EVåra mikrorädisor odlas endast med ekologiska, icke-GMO-frön och sköts noggrant för att säkerställa högsta kvalitet. De är lätta att odla och kan avnjutas året runt, vilket gör dem till ett utmärkt komplement till alla kök eller restauranger.",
        "Our radish microgreens are grown using only organic, non-GMO seeds and are carefully tended to ensure the highest quality product. They are easy to grow and can be enjoyed year-round, making them a great addition to any kitchen or restaurant.",
      ],
      [
        "Sammanfattningsvis är mikrorädisor ett läckert och näringsrikt tillskott till alla måltider, fyllda med smak och viktiga näringsämnen. Ge dem en chans och upplev själv vilken skillnad dessa små gröna kan göra!",
        "In summary, radish microgreens are a delicious and nutritious addition to any meal, packed with flavor and essential nutrients. Give them a try and see for yourself the difference that these tiny greens can make!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Mikrorädisor ger en pepprig crunch till sallader och är ett bra substitut för traditionella gröna sallader.",
        "Salads: Radish microgreens add a peppery crunch to salads and are a great substitute for traditional salad greens.",
      ],
      [
        "Smörgåsar och wraps: Lägg till mikrorädisor till smörgåsar och wraps för en explosion av smak och extra näring.",
        "Sandwiches and Wraps: Add radish microgreens to sandwiches and wraps for a burst of flavor and extra nutrition.",
      ],
      [
        "Tacos och burritos: Mikrorädisor passar bra i det mexikanska köket och kan inkluderas i tacos och burritos för extra crunch och smak.",
        "Tacos and Burritos: Radish microgreens pair well with Mexican cuisine and can be added to tacos and burritos for added crunch and flavor.",
      ],
      [
        "Soppor och grytor: Rädisor kan läggas till som garnering till soppor och grytor för att ge textur och smak.",
        "Soups and Stews: Radish microgreens can be added as a garnish to soups and stews to add texture and flavor.",
      ],
      [
        "Smoothies och juicer: Mikrorädisor kan läggas till smoothies och juicer för en näringsboost.",
        "Smoothies and Juices: Radish microgreens can be added to smoothies and juices for a nutrient boost.",
      ],
      [
        "Wok: Mikrorädisor kan läggas till wokrätter för en fräsch och pepprig smak.",
        "Stir-Fries: Radish microgreens can be added to stir-fries for a fresh and peppery flavor.",
      ],
    ],
    nutrientList: [
      [
        "Rika på antioxidanter: Rädisa-mikrogrönt innehåller mycket antioxidanter, vilka hjälper till att skydda kroppen mot skadliga fria radikaler och minskar risken för kroniska sjukdomar, såsom cancer, hjärtsjukdomar och Alzheimers sjukdom.",
        "Rich in Antioxidants: Radish microgreens are high in antioxidants, which help protect the body against harmful free radicals and reduce the risk of chronic diseases such as cancer, heart disease, and Alzheimer's disease.",
      ],
      [
        "Ökar immunförsvaret: Mikrogröna rädisor är rika på vitamin C, vilket är nödvändigt för ett hälsosamt immunförsvar. Vitamin C hjälper också kroppen att ta upp järn, vilket är viktigt för den allmänna hälsan.",
        "Boosts Immune System: Radish microgreens are rich in vitamin C, which is essential for a healthy immune system. Vitamin C also helps the body absorb iron, which is important for overall health.",
      ],
      [
        "Stöder matsmältningshälsan: Rädisor innehåller fibrer, vilket hjälper till att stödja matsmältningshälsan och förhindrar förstoppning. De innehåller också enzymer som hjälper till med matsmältningen.",
        "Supports Digestive Health: Radish microgreens contain fiber, which helps support digestive health and prevent constipation. They also contain enzymes that aid in digestion.",
      ],
      [
        "Antiinflammatorisk: Rädisor innehåller antiinflammatoriska föreningar som kan hjälpa till att minska inflammationen i kroppen och minska risken för kroniska sjukdomar, såsom artrit.",
        "Anti-inflammatory: Radish microgreens contain anti-inflammatory compounds that may help reduce inflammation in the body and lower the risk of chronic diseases such as arthritis.",
      ],

      [
        "Sänker kolesterolnivåerna: Rädisor innehåller föreningar som kan hjälpa till att sänka kolesterolnivåerna och minska risken för hjärtsjukdomar.",
        "Lowers Cholesterol: Radish microgreens contain compounds that may help lower cholesterol levels and reduce the risk of heart disease.",
      ],
      [
        "Högt innehåll av näringsämnen: Rädisor är en koncentrerad näringskälla som innehåller höga halter av vitaminer och mineraler, såsom vitamin A, vitamin K, kalcium och järn.",
        "High in Nutrients: Radish microgreens are a concentrated source of nutrients, containing high levels of vitamins and minerals such as vitamin A, vitamin K, calcium, and iron.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'bag'
  },
  {
    name: ["Rädismix", "Radish Mix"], //
    mustard: false,
    id: "radishMix-tray",
    link: "radishmix",
    latin: "Raphanus sativus",
    tray: 400,
    size: [40, 100],
    ean: ["7350127310258", "7350127310265"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 12,
    water: 2,
    shelfLife: "14",
    emissionFactor: 0.9,
    rateStore: 2,
    rateTaste: 2.5,
    rateSpicy: 2.5,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fradish.png?alt=media",
    logo: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2FradishLogo.png?alt=media",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fradishmix3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fradishmix1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fradishmix2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fradishmix3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fradishmix4.jpeg?alt=media",
    ],
    subtitle: [
      "Krispig mikrogrönt med en distinkt smak av rädisa som är rik på kolhydrater, mineraler & vitamin C. Kombinationen av färger piffar upp salladen eller kötträtten.",
      "Crispy microgreens with a distinct taste of radish that is rich in carbohydrates, minerals & vitamin C. Combination of colors brightens up a salad or meat dish.",
    ],
    menuName: ["Rädismix - Påse", "Radish Mix - Bag"],
    color: [
      "En kombination av grön, rosa, vit och lila",
      "A combination of green, pink, white and purple",
    ],
    flavor: ["Pepprig smak av rädisa", "Peppery taste of radish"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Rädisskott är det perfekta tillskottet till alla måltider, eftersom de ger en smak- och näringsmässig boost i en liten förpackning. Mikrogrönt är de unga plantorna av olika grönsaker och örter, som skördas när de bara är några centimeter höga. Detta gör dem till en mycket koncentrerad källa till näringsämnen.",
        "Radish microgreens are the perfect addition to any meal, providing a punch of flavor and nutrition in a small package. Microgreens are the young seedlings of various vegetables and herbs that are harvested when they are just a few inches tall, making them a highly concentrated source of nutrients.",
      ],
      [
        "Rädisskott har en distinkt pepprig smak som ger en explosion av smak till sallader, smörgåsar och mer. De är också otroligt näringsrika och innehåller höga halter av vitamin C, folat och antioxidanter. Forskning har visat att Rädisskott kan innehålla upp till 40 gånger mer näringsämnen än sina mogna motsvarigheter.",
        "Radish microgreens have a distinct peppery taste, adding a burst of flavor to salads, sandwiches, and more. They are also incredibly nutritious, containing high levels of vitamin C, folate, and antioxidants. In fact, research has shown that radish microgreens can contain up to 40 times more nutrients than their mature counterparts.",
      ],
      [
        "Våra Rädisskott odlas endast med ekologiska, icke-GMO-frön och sköts noggrant för att säkerställa högsta kvalitet. De är lätta att odla och kan avnjutas året runt, vilket gör dem till ett utmärkt komplement till alla kök eller restauranger.",
        "Our radish microgreens are grown using only organic, non-GMO seeds and are carefully tended to ensure the highest quality product. They are easy to grow and can be enjoyed year-round, making them a great addition to any kitchen or restaurant.",
      ],
      [
        "Sammanfattningsvis är Rädisskott ett läckert och näringsrikt tillskott till alla måltider, fyllda med smak och viktiga näringsämnen. Ge dem en chans och upplev själv vilken skillnad dessa små gröna kan göra!",
        "In summary, radish microgreens are a delicious and nutritious addition to any meal, packed with flavor and essential nutrients. Give them a try and see for yourself the difference that these tiny greens can make!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Rädisskott ger en pepprig crunch till sallader och är ett bra substitut för traditionella gröna sallader.",
        "Salads: Radish microgreens add a peppery crunch to salads and are a great substitute for traditional salad greens.",
      ],
      [
        "Smörgåsar och wraps: Lägg till Rädisskott till smörgåsar och wraps för en explosion av smak och extra näring.",
        "Sandwiches and Wraps: Add radish microgreens to sandwiches and wraps for a burst of flavor and extra nutrition.",
      ],
      [
        "Tacos och burritos: Rädisskott passar bra i det mexikanska köket och kan inkluderas i tacos och burritos för extra crunch och smak.",
        "Tacos and Burritos: Radish microgreens pair well with Mexican cuisine and can be added to tacos and burritos for added crunch and flavor.",
      ],
      [
        "Soppor och grytor: Rädisor kan läggas till som garnering till soppor och grytor för att ge textur och smak.",
        "Soups and Stews: Radish microgreens can be added as a garnish to soups and stews to add texture and flavor.",
      ],
      [
        "Smoothies och juicer: Rädisskott kan läggas till smoothies och juicer för en näringsboost.",
        "Smoothies and Juices: Radish microgreens can be added to smoothies and juices for a nutrient boost.",
      ],
      [
        "Wok: Rädisskott kan läggas till wokrätter för en fräsch och pepprig smak.",
        "Stir-Fries: Radish microgreens can be added to stir-fries for a fresh and peppery flavor.",
      ],
    ],
    nutrientList: [
      [
        "Rika på antioxidanter: Rädisa-mikrogrönt innehåller mycket antioxidanter, vilka hjälper till att skydda kroppen mot skadliga fria radikaler och minskar risken för kroniska sjukdomar, såsom cancer, hjärtsjukdomar och Alzheimers sjukdom.",
        "Rich in Antioxidants: Radish microgreens are high in antioxidants, which help protect the body against harmful free radicals and reduce the risk of chronic diseases such as cancer, heart disease, and Alzheimer's disease.",
      ],
      [
        "Ökar immunförsvaret: Mikrogröna rädisor är rika på vitamin C, vilket är nödvändigt för ett hälsosamt immunförsvar. Vitamin C hjälper också kroppen att ta upp järn, vilket är viktigt för den allmänna hälsan.",
        "Boosts Immune System: Radish microgreens are rich in vitamin C, which is essential for a healthy immune system. Vitamin C also helps the body absorb iron, which is important for overall health.",
      ],
      [
        "Stöder matsmältningshälsan: Rädisor innehåller fibrer, vilket hjälper till att stödja matsmältningshälsan och förhindrar förstoppning. De innehåller också enzymer som hjälper till med matsmältningen.",
        "Supports Digestive Health: Radish microgreens contain fiber, which helps support digestive health and prevent constipation. They also contain enzymes that aid in digestion.",
      ],
      [
        "Antiinflammatorisk: Rädisor innehåller antiinflammatoriska föreningar som kan hjälpa till att minska inflammationen i kroppen och minska risken för kroniska sjukdomar, såsom artrit.",
        "Anti-inflammatory: Radish microgreens contain anti-inflammatory compounds that may help reduce inflammation in the body and lower the risk of chronic diseases such as arthritis.",
      ],

      [
        "Sänker kolesterolnivåerna: Rädisor innehåller föreningar som kan hjälpa till att sänka kolesterolnivåerna och minska risken för hjärtsjukdomar.",
        "Lowers Cholesterol: Radish microgreens contain compounds that may help lower cholesterol levels and reduce the risk of heart disease.",
      ],
      [
        "Högt innehåll av näringsämnen: Rädisor är en koncentrerad näringskälla som innehåller höga halter av vitaminer och mineraler, såsom vitamin A, vitamin K, kalcium och järn.",
        "High in Nutrients: Radish microgreens are a concentrated source of nutrients, containing high levels of vitamins and minerals such as vitamin A, vitamin K, calcium, and iron.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'bag'
  },
  {
    name: ["Broccoli", "Broccoli"], //
    mustard: false,
    id: "broccoli-tray",
    link: "broccoli",
    latin: "Brassica oleracea var. italica",
    tray: 320,
    size: [40, 100],
    ean: ["7350127310302", "7350127310319"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 14,
    water: 3,
    shelfLife: "7-10",
    emissionFactor: 1,
    rateStore: 2,
    rateTaste: 1.5,
    rateSpicy: 2,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fbroccoli.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fbroccoli3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fbroccoli1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fbroccoli2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fbroccoli3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fbroccoli4.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fbroccoli5.JPG?alt=media",
    ],
    subtitle: [
      "Rik på mineraler & vitaminer med upp till 40 gånger så högt näringsinnehåll än i fullvuxen form. Den milda pepparsmaken gör sig bra i sallader eller smoothies.",
      "Rich in minerals & vitamins with up to 40 times higher nutritional content than in fully grown form. The mild pepper flavor works well in salads or smoothies.",
    ],
    menuName: ["Broccoli - Påse", "Broccoli - Bag"],
    color: [
      "Mörkgröna blad och vita/ljusgröna stjälkar",
      "Light purple leaves and dark purple stems",
    ],
    flavor: ["Mild smak av broccoli", "Mild taste of broccoli"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Broccoliskott är rika på antioxidanter, inklusive vitamin C och E, och innehåller höga nivåer av sulforafan – en förening som har visat sig minska inflammation, främja hjärthälsa och bekämpa cancer. De är också en utmärkt källa till fiber, som hjälper till med matsmältningen och gör att du känner dig mätt längre.",
        "These microgreens are rich in antioxidants, including vitamins C and E, and contain high levels of sulforaphane – a compound that has been linked to reducing inflammation, promoting heart health, and fighting cancer. They are also an excellent source of fiber, which aids in digestion and helps you feel full for longer periods of time.",
      ],
      [
        "Inte nog med att de är bra för din hälsa, de är också lätta att odla och mångsidiga i köket. De kan läggas till sallader, smörgåsar, soppor och smoothies för en näringsrik boost, eller användas som garnering för att lägga till smak och konsistens till vilken maträtt som helst.",
        "Not only are broccoli microgreens good for your health, but they are also easy to grow and versatile in the kitchen. They can be added to salads, sandwiches, soups, and smoothies for a nutritious boost, or used as a garnish to add flavor and texture to any dish.",
      ],
      [
        "På VERT.farm skördas broccoliskotten för hand och förpackas färska för att säkerställa högsta kvalitet och smak. Så varför inte prova våra broccoliskott idag och ta första steget mot en hälsosammare livsstil?",
        "At our farm, the Broccoli Microgreens are harvested by hand and packaged fresh to ensure the highest quality and taste. So why not try our broccoli microgreens today and take the first step towards a healthier you?",
      ],
    ],
    kitchen: [
      [
        "Sallader: Lägg till en handfull broccoliskott till din favoritsallad för en extra näringsboost och en krispig konsistens.",
        "Salads: Add a handful of broccoli microgreens to your favorite salad for an extra nutrient boost and a crunchy texture.",
      ],
      [
        "Smörgåsar och wraps: Använd broccoliskott som ett smakrikt och hälsosamt tillskott till dina favoritmackor eller wraps.",
        "Sandwiches and wraps: Use broccoli microgreens as a flavorful and healthy addition to your favorite sandwiches or wraps.",
      ],
      [
        "Smoothies: Lägg till en handfull broccoliskott till din morgonsmoothie för en extra dos vitaminer och mineraler.",
        "Smoothies: Add a handful of broccoli microgreens to your morning smoothie for an extra dose of vitamins and minerals.",
      ],
      [
        "Omeletter och äggröra: Toppa dina morgonägg med en näve broccoliskott för en hälsosam och god frukost.",
        "Omelets and scrambled eggs: Top your morning eggs with a handful of broccoli microgreens for a healthy and tasty breakfast.",
      ],
      [
        "Pesto: Använd broccoliskott istället för basilika för att göra en läcker och näringsrik broccolipesto.",
        "Pesto: Use broccoli microgreens in place of basil to make a delicious and nutritious broccoli pesto.",
      ],
      [
        "Tacos: Strö broccoliskott ovanpå dina favorittacos för en fräsch och hälsosam twist.",
        "Tacos: Sprinkle broccoli microgreens on top of your favorite tacos for a fresh and healthy twist.",
      ],
    ],
    nutrientList: [
      [
        "Broccoliskott är laddade med antioxidanter, inklusive vitamin C och E, vilka kan hjälpa till att skydda kroppen mot cellskador orsakade av fria radikaler.",
        "Rich in antioxidants: Broccoli microgreens are loaded with antioxidants, including vitamins C and E, which can help protect the body against cellular damage caused by free radicals.",
      ],
      [
        "Kan minska inflammation: Broccoliskott innehåller höga halter av sulforafan, en förening som har visat sig ha antiinflammatoriska egenskaper. Detta kan potentiellt minska risken för kroniska sjukdomar som hjärtsjukdomar, diabetes och vissa cancerformer.",
        "May reduce inflammation: Broccoli microgreens contain high levels of sulforaphane, a compound that has been shown to have anti-inflammatory properties, potentially reducing the risk of chronic diseases such as heart disease, diabetes, and certain cancers.",
      ],
      [
        "Främjar hjärthälsa: Broccoliskott är en utmärkt källa till fiber, vilket kan hjälpa till att minska kolesterolnivåerna och risken för hjärtsjukdomar.",
        "Promotes heart health: Broccoli microgreens are an excellent source of fiber, which can help reduce cholesterol levels and lower the risk of heart disease.",
      ],
      [
        "Ökar immunförsvaret: Broccoliskott innehåller mycket C-vitamin, vilket är viktigt för immunsystemets funktion och kan hjälpa kroppen att bekämpa infektioner.",
        "Boosts the immune system: Broccoli microgreens are high in vitamin C, which is essential for immune system function and can help the body fight off infections.",
      ],
      [
        "Hjälper matsmältningen: Fibrerna i broccoliskott kan bidra till att främja matsmältningens hälsa genom att reglera tarmrörelserna och minska förstoppning.",
        "Aids digestion: The fiber in broccoli microgreens can help promote digestive health by regulating bowel movements and reducing constipation.",
      ],
      [
        "Stödjer benhälsa: Broccoliskott är en bra källa till kalcium och vitamin K, vilka båda är viktiga för friska ben.",
        "Supports bone health: Broccoli microgreens are a good source of calcium and vitamin K, both of which are essential for healthy bones.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'bag'
  },
  {
    name: ["Senap", "Mustard"], //
    mustard: true,
    id: "mustard-tray",
    link: "mustard",
    latin: "Brassica juncea",
    tray: 320,
    size: [40, 100],
    ean: ["7350127310609", "7350127310616"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 14,
    water: 3,
    shelfLife: "7-10",
    emissionFactor: 1,
    rateStore: 2,
    rateTaste: 1,
    rateSpicy: 2.5,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fmustard.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmustard3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmustard1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmustard2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmustard3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmustard4.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmustard5.jpg?alt=media",
    ],
    subtitle: [
      "Rik på vitaminer, antioxidanter och mineraler. Med sin söta senapssmak gör den sig bra i sallader eller till kött.",
      "Rich in vitamins, antioxidants and minerals. With its sweet mustard taste, it works well in salads or with meat.",
    ],
    menuName: ["Senap - Påse", "Mustard - Bag"],
    color: [
      "Vit-gula stjälkar med gröna blad",
      "White & yellow stems with green leafs",
    ],
    flavor: ["Söt senapssmak", "Sweet mustard taste"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Senapsskott är det perfekta tillskottet till alla kök eller restauranger som vill höja sina rätter med en touch av djärv och syrlig smak. Våra senapsskott skördas vid toppen av färskhet, vilket gör dem till en näringsspäckad superfood som ger en smakupplevelse till varje måltid. Med en distinkt pepprig smak och en öm, krispig konsistens är dessa mikrogrönsaker perfekta för sallader, smörgåsar, soppor och mycket mer.",
        "Mustard microgreens, the perfect addition to any kitchen or restaurant seeking to elevate their dishes with a touch of bold and tangy flavor. Our mustard microgreens are harvested at the peak of freshness, making them a nutrient-packed superfood that adds a burst of flavor to any meal. With a distinct peppery taste and a tender, crisp texture, these microgreens are perfect for salads, sandwiches, soups, and more.",
      ],
      [
        "Dessa microgreens ger inte bara smak och konsistens till dina måltider utan ger också en mängd näringsfördelar. De har högt innehåll av antioxidanter, vitaminer och mineraler, inklusive vitamin C, vitamin K och kalium.",
        "Not only do these microgreens add flavor and texture to your meals, but they also bring a wealth of nutritional benefits. They are high in antioxidants, vitamins, and minerals, including vitamin C, vitamin K, and potassium.",
      ],
      [
        "Sammantaget är våra senapsskott det perfekta valet för alla som vill sätta en läcker och näringsrik touch på sina rätter. Med sin livfulla smak och många hälsofördelar kommer dessa microgreens garanterat att bli en stapelvara i ditt kök.",
        "Overall, our mustard microgreens are the perfect choice for anyone who wants to add a delicious and nutritious touch to their dishes. With their vibrant flavor and numerous health benefits, these microgreens are sure to become a staple in your kitchen.",
      ],
    ],
    kitchen: [
      [
        "Sallader: Senapsskott ger en utsökt pepprig smak till vilken sallad som helst. De passar särskilt bra tillsammans med andra grönsaker, såsom ruccola, spenat eller sallad.",
        "Salads: Mustard microgreens add a delicious peppery flavor to any salad. They pair particularly well with other greens, such as arugula, spinach, or lettuce.",
      ],
      [
        "Smörgåsar och wraps: Lägg till en handfull senapsskott till din smörgås eller wrap för extra smak och konsistens.",
        "Sandwiches and wraps: Add a handful of mustard microgreens to your sandwich or wrap for an extra pop of flavor and texture.",
      ],
      [
        "Soppor och grytor: Använd senapsskott för att garnera dina soppor eller grytor, eller lägg till dem i slutet för en extra smakupplevelse.",
        "Soups and stews: Use mustard microgreens to garnish your soups or stews, or add them in at the end for an extra burst of flavor.",
      ],
      [
        "Tacos och burritos: Senapsskott kan ge en fin crunch och smak till dina mexikanskinspirerade rätter.",
        "Tacos and burritos: Mustard microgreens can add a nice crunch and flavor to your Mexican-inspired dishes.",
      ],
      [
        "Smoothies och juicer: Prova att lägga till en handfull senapsskott till dina smoothies eller juicer för en näringstillskott och en unik smak.",
        "Smoothies and juices: Try adding a handful of mustard microgreens to your smoothies or juices for a boost of nutrition and a unique flavor.",
      ],
      [
        "Omeletter och frittatas: Tillsätt lite senapsskott till dina äggrätter för extra smak och näring.",
        "Omelets and frittatas: Add some mustard microgreens to your egg dishes to add some extra flavor and nutrition.",
      ],
    ],
    nutrientList: [
      [
        "Rik på antioxidanter: Senapsskott innehåller en mängd olika antioxidanter, som kan hjälpa till att neutralisera skadliga fria radikaler i kroppen och minska risken för kroniska sjukdomar.",
        "Rich in antioxidants: Mustard microgreens contain a variety of antioxidants, which can help to neutralize harmful free radicals in the body and reduce the risk of chronic diseases.",
      ],
      [
        "Högt innehåll av vitaminer och mineraler: Senapsskott är en bra källa till viktiga vitaminer och mineraler, såsom vitamin C, vitamin K, kalium, kalcium och järn.",
        "High in vitamins and minerals: Mustard microgreens are a good source of important vitamins and minerals, such as vitamin C, vitamin K, potassium, calcium, and iron.",
      ],
      [
        "Kan hjälpa till att minska inflammation: Föreningarna i senapsskott har antiinflammatoriska egenskaper, vilket kan hjälpa till att minska inflammation i kroppen och lindra symtom på tillstånd som artrit och astma.",
        "May help reduce inflammation: The compounds in mustard microgreens have anti-inflammatory properties, which may help to reduce inflammation in the body and alleviate symptoms of conditions such as arthritis and asthma.",
      ],
      [
        "Kan förbättra hjärthälsa: Senapsskott innehåller föreningar som har visat sig hjälpa till att sänka blodtrycket, minska kolesterolnivåerna och förbättra hjärthälsa.",
        "May improve heart health: Mustard microgreens contain compounds that have been shown to help lower blood pressure, reduce cholesterol levels, and improve overall heart health.",
      ],
      [
        "Kan stödja matsmältningen: Fibrerna och andra föreningar som finns i senapsskott kan hjälpa till att främja hälsosam matsmältning och förhindra problem som förstoppning och uppblåsthet.",
        "May support digestion: The fiber and other compounds found in mustard microgreens may help to promote healthy digestion and prevent issues such as constipation and bloating.",
      ],
      [
        "Kan ha anticancereffekter: Vissa studier har föreslagit att föreningarna som finns i senapsmikrogrönt kan ha anticanceregenskaper, vilket potentiellt kan hjälpa till att förhindra tillväxt och spridning av cancerceller.",
        "May have anticancer effects: Some studies have suggested that the compounds found in mustard microgreens may have anticancer properties, potentially helping to prevent the growth and spread of cancer cells.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'bag'
  },
  {
    name: ["Ruccola", "Rocket"], //
    mustard: false,
    id: "ruccola-tray",
    link: "ruccola",
    latin: "Eruca sativa",
    tray: 240,
    size: [40, 100],
    ean: ["7350127310401", "7350127310418"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 14,
    water: 2,
    shelfLife: "7-8",
    emissionFactor: 1.2,
    rateStore: 1.5,
    rateTaste: 1,
    rateSpicy: 2,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fruccola.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fruccola3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fruccola1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fruccola2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fruccola3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fruccola4.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fruccola5.JPG?alt=media",
    ],
    subtitle: [
      "En ört som odlats sedan romartiden. Med sin peppriga smak är den ett gott tillskott i salladen, pastan eller på kötträtten.",
      "An herb cultivated since Roman times. With its peppery taste, it is a good addition to salad, pasta or on meat dishes.",
    ],
    menuName: ["Ruccola - Påse", "Rocket - Bag"],
    color: ["Vit stjälkar med gröna blad", "White stems with green leafs"],
    flavor: ["Kryddig smak av ruccola", "Peppery taste of rocket"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Ruccola microgreens är den yngre versionen av ruccolaväxten, skördad när plantorna är bara några centimeter höga. Dessa små grönsaker ger en stor smak- och näringsmässig punch, vilket gör dem till ett utmärkt komplement till alla måltider.",
        "Rocket microgreens are the younger version of the arugula plant, harvested when the seedlings are just a few inches tall. These tiny greens pack a big punch in terms of taste and nutritional value, making them an excellent addition to any meal.",
      ],
      [
        "En av de viktigaste fördelarna med Ruccola microgreens är deras höga koncentration av vitaminer och mineraler. De är en utmärkt källa till vitamin A, C och K, samt kalcium, järn och kalium. Detta gör dem till det perfekta valet för alla som vill öka sitt näringsintag och förbättra sin allmänna hälsa.",
        "One of the most significant benefits of Rocket microgreens is their high concentration of vitamins and minerals. They are an excellent source of vitamins A, C, and K, as well as calcium, iron, and potassium. This makes them an ideal choice for anyone looking to boost their nutrient intake and improve their overall health.",
      ],
      [
        "Förutom sina näringsmässiga fördelar är Ruccola microgreens också otroligt smakrika. De har en distinkt pepprig smak som ger djup och komplexitet till varje maträtt. De är särskilt läckra när de kombineras med sallader, smörgåsar och pizzor, men kan också användas för att förhöja smaken på soppor och grytor.",
        "In addition to their nutritional benefits, Rocket microgreens are also incredibly flavorful. They have a distinct peppery taste that adds depth and complexity to any dish. They are particularly delicious when paired with salads, sandwiches, and pizzas, but can also be used to enhance the flavor of soups, stews, and other savory dishes.",
      ],
      [
        "Sammanfattningsvis är Ruccola microgreens ett läckert och näringsrikt tillskott till alla måltider. Med sin unika smak och höga näringsinnehåll är de det perfekta valet för alla som vill förbättra sin hälsa och tillföra god smak till sina favoriträtter. Så varför inte prova dem själv och upplev fördelarna med Ruccola mikrogrönt!",
        "In summary, Rocket microgreens are a delicious and nutritious addition to any meal. With their unique flavor and high nutrient content, they are an excellent choice for anyone looking to improve their health and add a burst of flavor to their favorite dishes. So why not try them out for yourself today and experience the incredible benefits of Rocket microgreens!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Ruccola microgreens har en pepprig smak som ger en smakexplosion till vilken sallad som helst. De passar bra tillsammans med andra grönsaker och kan också användas som garnering.",
        "Salads: Rocket microgreens have a peppery flavor that adds a punch of flavor to any salad. They pair well with other greens and vegetables, and can also be used as a garnish.",
      ],
      [
        "Smörgåsar och wraps: Ruccola microgreens är ett utmärkt tillägg till smörgåsar och wraps. De ger en frisk och krispig konsistens samt en smakexplosion.",
        "Sandwiches and Wraps: Rocket microgreens make an excellent addition to sandwiches and wraps. They add a fresh, crisp texture and a burst of flavor.",
      ],
      [
        "Pizzor och tunnbröd: Ruccola microgreens kan användas som topping för pizzor och tunnbröd, vilket ger en unik smak och konsistens till rätten.",
        "Pizzas and Flatbreads: Rocket microgreens can be used as a topping for pizzas and flatbreads, adding a unique flavor and texture to the dish.",
      ],
      [
        "Smoothies och juicer: Ruccola microgreens kan läggas till smoothies och juicer för extra näring. De har en mild smak som passar bra med andra frukter och grönsaker.",
        "Smoothies and Juices: Rocket microgreens can be added to smoothies and juices for an extra nutrient boost. They have a mild flavor that blends well with other fruits and vegetables.",
      ],
      [
        "Pastarätter: Ruccola microgreens kan användas som topping för pastarätter och ger en frisk, grön smak och konsistens.",
        "Pasta Dishes: Rocket microgreens can be used as a topping for pasta dishes, adding a fresh, green flavor and texture.",
      ],
      [
        "Omeletter och frittatas: Ruccola-mikrogrönt kan läggas till i omeletter och frittatas för extra smak och näring.",
        "Omelets and Frittatas: Rocket microgreens can be added to omelets and frittatas for an extra burst of flavor and nutrients.",
      ],
    ],
    nutrientList: [
      [
        "Näringsrikt: Ruccola-mikrogrönt är fullpackat med viktiga vitaminer och mineraler, inklusive vitamin A, C och K, kalcium, järn och kalium, vilket gör dem till en näringstät mat.",
        "High in Nutrients: Rocket microgreens are packed with essential vitamins and minerals, including vitamins A, C, and K, calcium, iron, and potassium, making them a nutrient-dense food.",
      ],
      [
        "Antioxidanteffekter: Ruccola-mikrogrönt innehåller höga halter av antioxidanter, vilket hjälper till att skydda kroppen från oxidativ stress och minskar risken för kroniska sjukdomar som cancer, hjärtsjukdomar och Alzheimers.",
        "Antioxidant Properties: Rocket microgreens contain high levels of antioxidants, which help to protect the body from oxidative stress, reducing the risk of chronic diseases such as cancer, heart disease, and Alzheimer's.",
      ],
      [
        "Immunstärkande: Ruccola-mikrogrönt är rikt på vitamin C, som är känt för sina immunstärkande egenskaper. Att äta Ruccola-microgreens kan hjälpa till att förbättra kroppens förmåga att bekämpa infektioner och sjukdomar.",
        "Immune Boosting: Rocket microgreens are rich in vitamin C, which is known for its immune-boosting properties. Eating Rocket microgreens may help to improve the body's ability to fight off infections and diseases.",
      ],
      [
        "Stödjer hälsosam matsmältning: Fiberinnehållet i Ruccola-microgreens stödjer hälsosam matsmältning, vilket minskar risken för förstoppning, uppblåsthet och andra matsmältningsproblem.",
        "Supports Healthy Digestion: The fiber content in Rocket microgreens supports healthy digestion, reducing the risk of constipation, bloating, and other digestive issues.",
      ],
      [
        "Kan förbättra benhälsan: Ruccola-mikrogrönt är en bra källa till kalcium och vitamin K, vilket är avgörande för att bibehålla starka och friska ben.",
        "May Improve Bone Health: Rocket microgreens are a good source of calcium and vitamin K, which are essential for maintaining strong and healthy bones.",
      ],
      [
        "Kan främja frisk hud: De höga nivåerna av vitamin A i ruccola-microgreens kan hjälpa till att främja frisk hud genom att stödja celltillväxt och reparation, vilket minskar uppkomsten av fina linjer och rynkor.",
        "May Promote Healthy Skin: The high levels of vitamin A in Rocket microgreens may help to promote healthy skin by supporting cell growth and repair, reducing the appearance of fine lines and wrinkles.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'bag'
  },
  {
    name: ["Krasse", "Cress"], //
    mustard: false,
    id: "cress-tray",
    link: "cress",
    latin: "Lepidium sativum",
    tray: 320,
    size: [40, 100],
    ean: ["7350127310500", "7350127310517"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 12,
    water: 2,
    shelfLife: "10",
    emissionFactor: 1.2,
    rateStore: 2,
    rateTaste: 1,
    rateSpicy: 2,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fcress.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fcress3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fcress1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fcress2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fcress3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fcress4.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fcress5.jpg?alt=media",
    ],
    subtitle: [
      "Rik på mineraler, antioxidanter och vitaminer. Med sin tydligt kryddiga smak är den perfekt att strö över på allt som behöver en extra smak-boost.",
      "Rich in minerals, antioxidants and vitamins. With its distinctly peppery taste, it is perfect for sprinkling on anything that needs an extra flavor boost.",
    ],
    menuName: ["Krasse - Påse", "Cress - Bag"],
    color: ["Vita stjälkar med gröna blad", "White stems with green leafs"],
    flavor: ["Kryddig smak", "Peppery taste"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Krasse - det perfekta tillskottet till alla måltider! Dessa små växter ger en kraftfull smak och är fullproppade med viktiga näringsämnen, vilket gör dem till ett måste för alla hälsomedvetna matälskare.",
        "Cress microgreens, the perfect addition to any meal! These tiny plants pack a powerful punch of flavor and are packed with essential nutrients, making them a must-have ingredient for any health-conscious foodie.",
      ],
      [
        "Trots sin lilla storlek är de ett näringspaket med höga halter av vitaminerna A, C och K, samt mineraler som kalcium och järn. Dessutom är de kalorisnåla och fiberrika, vilket gör dem till ett bra komplement till alla dieter.",
        "Despite their small size, they are a nutritional powerhouse, containing high levels of vitamins A, C, and K, as well as minerals such as calcium and iron. Plus, they are low in calories and high in fiber, making them a great addition to any diet.",
      ],
      [
        "Krasse är inte bara hälsosamt, utan ger också en härlig smak till varje maträtt. De har en kryddig och pepprig smak som passar bra med en mängd olika livsmedel, från sallader till smörgåsar till soppor.",
        "Not only are cress microgreens healthy, but they also add a delightful burst of flavor to any dish. They have a spicy and peppery taste that pairs well with a variety of foods, from salads to sandwiches to soups.",
      ],
      [
        "Vår krasse odlas med hållbara odlingsmetoder, vilket säkerställer att de både är hälsosamma för dig och miljön. Vi är mycket noga med att odla våra växter för att säkerställa att de är färska och smakrika, och vi är angelägna om att förse dig med microgreens av högsta kvalitet.",
        "Our cress microgreens are grown using sustainable, organic farming practices, ensuring that they are both healthy for you and the environment. We take great care in cultivating our plants to ensure that they are fresh and flavorful, and we are committed to providing you with the highest quality microgreens available.",
      ],
      [
        "Så om du letar efter näringsrikt sätt att piffa upp smaken på dina måltider, testa VERT.farms krasse och märk skillnaden!",
        "So if you're looking for a delicious and nutritious way to add some flavor to your meals, look no further than our cress microgreens. Try them today and taste the difference!",
      ],
    ],
    kitchen: [
      [
        "Som garnering: Krasse-microgreens har en delikat, pepprig smak och ett vackert utseende, vilket gör dem till en utmärkt garnering för sallader, soppor och andra rätter.",
        "As a garnish: Cress microgreens have a delicate, peppery flavor and a beautiful appearance, making them a great garnish for salads, soups, and other dishes.",
      ],
      [
        "I smörgåsar och wraps: Lägg till en handfull krasse-microgreens till din favoritsmörgås eller wrap för en smakexplosion och extra näring.",
        "In sandwiches and wraps: Add a handful of cress microgreens to your favorite sandwich or wrap for a pop of flavor and extra nutrition.",
      ],
      [
        "I smoothies: Krasse-microgreens kan vara ett bra tillskott till gröna smoothies och tillföra en explosion av smak och näring.",
        "In smoothies: Cress microgreens can be a great addition to green smoothies, adding a burst of flavor and nutrition.",
      ],
      [
        "I omeletter och frittatas: Lägg till krasse-microgreens till dina favoritäggrätter för en extra boost av vitaminer och mineraler.",
        "In omelets and frittatas: Add cress microgreens to your favorite egg dishes for an extra boost of vitamins and minerals.",
      ],
      [
        "I röror och sautéer: Krasse-microgreens kan snabbt sauteras eller stekas med andra grönsaker och proteinkällor, vilket ger en explosion av smak och näring till dina måltider.",
        "In stir-fries and sautés: Cress microgreens can be quickly sautéed or stir-fried with other vegetables and protein sources, adding a burst of flavor and nutrition to your meals.",
      ],
      [
        "I sallader: Krasse-microgreens kan användas som bas i en sallad, vilket ger en näringsrik och smakrik grund för dina favoritingridienser.",
        "n salads: Cress microgreens can be used as the base of a salad, providing a nutrient-dense and flavorful foundation for your favorite toppings.",
      ],
    ],
    nutrientList: [
      [
        "Högt innehåll av viktiga vitaminer och mineraler: Krasse är en utmärkt källa till flera viktiga näringsämnen, inklusive vitamin A, C och K, samt mineraler som kalcium, järn och kalium.",
        "High in essential vitamins and minerals: Cress microgreens are an excellent source of several important nutrients, including vitamins A, C, and K, as well as minerals such as calcium, iron, and potassium.",
      ],
      [
        "Bra för immunförsvaret: De höga nivåerna av C-vitamin i krasse  kan hjälpa till att stärka immunförsvaret och skydda kroppen mot sjukdomar.",
        "Good for the immune system: The high levels of vitamin C in cress microgreens can help boost the immune system, protecting the body against illness and disease.",
      ],
      [
        "Kan hjälpa till att minska inflammation: Viss forskning tyder på att föreningarna som finns i krasse  kan ha antiinflammatoriska egenskaper, vilket kan bidra till att minska inflammation i kroppen.",
        "May help reduce inflammation: Some research suggests that the compounds found in cress microgreens may have anti-inflammatory properties, potentially helping to reduce inflammation in the body.",
      ],
      [
        "Kan ha cancerbekämpande egenskaper: Vissa föreningar i krasse , såsom sulforafan, har visat sig ha anti-canceregenskaper, vilket potentiellt kan hjälpa till att förhindra utvecklingen eller tillväxten av cancerceller.",
        "May have cancer-fighting properties: Certain compounds in cress microgreens, such as sulforaphane, have been shown to have anti-cancer properties, potentially helping to prevent the development or growth of cancer cells.",
      ],
      [
        "Kan hjälpa till att reglera blodsockernivåerna: Det höga fiberinnehållet i krasse  kan hjälpa till att reglera blodsockernivåerna, vilket gör det till ett bra val för personer med diabetes eller de som riskerar att utveckla tillståndet.",
        "May help regulate blood sugar levels: The high fiber content in cress microgreens may help regulate blood sugar levels, making them a good choice for people with diabetes or those at risk of developing the condition.",
      ],
      [
        "Kan förbättra matsmältningen: Krasse  är en bra källa till kostfiber, som kan hjälpa till att stödja matsmältningens hälsa. Fiber hjälper till att främja regelbundna tarmrörelser, förhindra förstoppning och stödja tillväxten av nyttiga tarmbakterier, vilket alla kan bidra till förbättrad matsmältning och allmän hälsa.",
        "May improve digestion: Cress microgreens are a good source of dietary fiber, which can help support digestive health. Fiber helps to promote regular bowel movements, prevent constipation, and support the growth of beneficial gut bacteria, all of which can contribute to improved digestion and overall health.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'bag'
  },
  {
    name: ["Pak Choi", "Pak Choi"], //
    mustard: false,
    id: "pakchoi-tray",
    link: "pakchoi",
    latin: "Brassica rapa subsp. chinensis",
    tray: 400,
    size: [40, 100],
    ean: ["7350127311408", "7350127311415"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 12,
    water: 3,
    shelfLife: "10",
    emissionFactor: 0.9,
    rateStore: 2.5,
    rateTaste: 1.5,
    rateSpicy: 1,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2FPakChoi-Icon.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpakchoi3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpakchoi1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpakchoi2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpakchoi3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpakchoi4.jpg?alt=media",
    ],
    subtitle: [
      "En kinesisk kål som med sina ljusgröna blad har en robust & söt smak. En utmärkt källa till vitamin B2, B6, C, A, K, järn, kalcium, magnesium, fosfor, kalium och zink.",
      "A Chinese cabbage that with its light green leaves has a robust & sweet taste. An excellent source of vitamins B2, B6, C, A, K, iron, calcium, magnesium, phosphorus, potassium and zinc.",
    ],
    menuName: ["Pak Choi - Påse", "Pak Choi - Bag"],
    color: ["Vita stammar med gröna bladpar", "White stems and green leafs"],
    flavor: ["Söt smak av kål", "Sweet taste of kale"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Pak Choi Microgreens är små versioner av den populära kinesiska bladgrönsaken, packade med en rad viktiga näringsämnen och en utsökt smak. Dessa babygrönsaker är en stor källa till vitamin A, C och K, såväl som antioxidanter, fibrer och mineraler som kalcium, järn och kalium. De är också låga i kalorier och kolhydrater, vilket gör dem till ett idealiskt komplement till vilken diet som helst.",
        "Pak Choi Microgreens are tiny versions of the popular Chinese leafy vegetable, packed with a range of essential nutrients and a delicious flavor. These baby greens are a great source of vitamins A, C, and K, as well as antioxidants, fiber, and minerals such as calcium, iron, and potassium. They are also low in calories and carbohydrates, making them an ideal addition to any diet.",
      ],
      [
        "Pak Choi Microgreens har en mild och lätt söt smak som passar bra med en rad ingredienser, och deras delikata konsistens ger en fräsch crunch till varje maträtt. De är också ett bra sätt att lägga till lite färg och näring till dina måltider.",
        "Pak Choi Microgreens have a mild and slightly sweet flavor that pairs well with a range of ingredients, and their delicate texture adds a fresh crunch to any dish. They are also a great way to add a pop of color and nutrition to your meals.",
      ],
      [
        "Våra Pak Choi Microgreens odlas med de högsta standarderna för jordbruk, vilket säkerställer att du får den färskaste och hälsosammaste produkten möjligt. De är redo att lägga till en hälsosam twist till dina kulinariska skapelser.",
        "Our Pak Choi Microgreens are grown using the highest standards of farming, ensuring that you receive the freshest and healthiest product possible. They are ready to add a healthy twist to your culinary creations.",
      ],
      [
        "Sammanfattningsvis, om du letar efter ett näringsrikt, smakrikt och lättodlat tillskott till din kost, är Pak Choi Microgreens det perfekta valet. Prova dem idag och upplev fördelarna med dessa läckra och hälsosamma mikrogrönsaker!",
        "In conclusion, if you are looking for a nutritious, flavorful, and easy-to-grow addition to your diet, Pak Choi Microgreens are the perfect choice. Try them today and experience the benefits of these delicious and healthy microgreens for yourself!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Pak Choi microgreens är en utmärkt komplettering till sallader och ger en fräsch och krispig konsistens som passar bra med andra grönsaker och ingredienser.",
        "Salads: Pak choi microgreens make a great addition to salads, providing a fresh and crunchy texture that pairs well with other greens and vegetables.",
      ],
      [
        "Smörgåsar och wraps: Dessa mikrogrönsaker kan användas för att tillföra smak och näring till dina smörgåsar eller wraps och ersätta traditionella salladsblad med ett mer näringsrikt alternativ.",
        "Sandwiches and Wraps: These microgreens can be used to add a pop of flavor and nutrition to your sandwiches or wraps, replacing traditional lettuce with a more nutrient-dense option.",
      ],
      [
        "Wokrätter och soppor: Pak Choi microgreens är en god tillägg till röror och soppor, som ger en subtil smak och konsistens till rätten.",
        "Stir-Fries and Soups: Pak choi microgreens are a great addition to stir-fries and soups, adding a subtle flavor and texture to the dish.",
      ],
      [
        "Smoothies och juicer: Du kan tillsätta Pak Choi microgreens till dina smoothies eller juicer för en näringstillskott och en fräsch smak.",
        "Smoothies and Juices: You can add Pak choi microgreens to your smoothies or juices for a boost of nutrition and a fresh taste.",
      ],
      [
        "Toppings: Pak Choi microgreens kan användas som garnering eller topping för en rad olika rätter, inklusive pizza, omeletter och mer.",
        "Toppings: Pak choi microgreens can be used as a garnish or topping for a range of dishes, including pizzas, omelets, and more.",
      ],
      [
        "Sushi: Pak Choi microgreens kan vara ett bra tillägg till sushirullar, vilket ger en fräsch och krispig konsistens till rätten.",
        "Sushi Rolls: Pak Choi microgreens can be a great addition to sushi rolls, adding a fresh and crunchy texture to the dish.",
      ],
    ],
    nutrientList: [
      [
        "Rik på vitaminer: Pak choi mikrogrönsaker är en utmärkt källa till vitamin A, C och K, vilket hjälper till att stärka ditt immunförsvar, främja en sund syn och stödja benhälsan.",
        "Rich in Vitamins: Pak choi microgreens are an excellent source of vitamins A, C, and K, which help boost your immune system, promote healthy vision, and support bone health.",
      ],
      [
        "Högt innehåll av antioxidanter: Dessa mikrogrönsaker är också rika på antioxidanter, som hjälper till att skydda dina celler från skador orsakade av fria radikaler och minskar risken för kroniska sjukdomar.",
        "High in Antioxidants: These microgreens are also rich in antioxidants, which help protect your cells from damage caused by free radicals and reduce the risk of chronic diseases.",
      ],
      [
        "Avgiftning: Svavelföreningarna i Pak choi microgreens har visat sig hjälpa kroppen att avgifta, vilket främjar en hälsosam leverfunktion.",
        "Detoxification: The sulfur compounds in Pak choi microgreens have been shown to help the body detoxify, promoting healthy liver function.",
      ],
      [
        "Främjar matsmältningen: Dessa mikrogrönsaker är rika på fiber, vilket hjälper till att stödja en hälsosam matsmältning, förhindrar förstoppning och minskar risken för matsmältningsbesvär.",
        "Promotes Digestion: These microgreens are rich in fiber, which helps support healthy digestion, prevent constipation, and reduce the risk of digestive disorders.",
      ],
      [
        "Sänker blodtrycket: Kalium som finns i Pak choi microgreens hjälper till att reglera blodtrycksnivåerna, vilket minskar risken för hjärtsjukdomar och stroke.",
        "Lowers Blood Pressure: The potassium found in Pak choi microgreens helps regulate blood pressure levels, reducing the risk of heart disease and stroke.",
      ],
      [
        "Viktminskning: Pak choi microgreens är låga i kalorier och höga i fiber, vilket gör dem till ett utmärkt komplement till alla viktminskningsdieter.",
        "Weight Loss: Pak choi microgreens are low in calories and high in fiber, making them an excellent addition to any weight loss diet.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'bag'
  },
  {
    name: ["Rödkål", "Red Cabbage"], //
    mustard: false,
    id: "redKale-tray",
    link: "redcabbage",
    latin: "Brassica sativum",
    tray: 400,
    size: [40, 100],
    ean: ["7350127310807", "7350127310814"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 14,
    water: 2,
    shelfLife: "7-10",
    emissionFactor: 0.9,
    rateStore: 2,
    rateTaste: 3,
    rateSpicy: 2,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fredcabbage.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredcabbage3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredcabbage1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredcabbage2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredcabbage3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredcabbage4.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredcabbage5.jpg?alt=media",
    ],
    subtitle: [
      "Rik på vitamin C, E och K samt järn. Lila och röda stjälkar och mörkgröna blad med söt och kryddig eftersmak för extra smak & färg i salladen.",
      "Rich in vitamins C, E and K as well as iron. Purple and red stems and dark green leaves with a sweet and spicy aftertaste for extra flavor & color in the salad.",
    ],
    menuName: ["Rödkål - Påse", "Red Cabbage - Bag"],
    color: [
      "Lila stjälkar med blad i nyanser av grönt, lila & rött",
      "Purple stems with leafs in green, purple & red",
    ],
    flavor: ["Söt & Kryddig", "Sweet & Spicy"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Rödkålsskott är ett läckert och näringsrikt tillskott till varje måltid. Dessa små grönsaker är packade med antioxidanter, vitaminer och mineraler, vilket gör dem till en utmärkt näringskälla för alla som vill förbättra sin kost.",
        "Red cabbage microgreens are a delicious and nutritious addition to any meal. These tiny greens are packed with antioxidants, vitamins, and minerals, making them an excellent source of nutrition for anyone looking to improve their diet.",
      ],
      [
        "Rödkålsskott är inte bara bra för dig, de har också en fantastisk smak. Dess lätt söta och milt kryddiga smak gör det till ett perfekt komplement till sallader, smörgåsar, smoothies och mer.",
        "Not only are red cabbage microgreens good for you, but they also have a fantastic flavor. Their slightly sweet and mildly spicy taste makes them a perfect addition to salads, sandwiches, smoothies, and more.",
      ],
      [
        "På det microgreens-stadiet har rödkålsväxter sitt högsta näringsvärde och innehåller upp till 40 gånger mer näringsämnen än sina mogna motsvarigheter.",
        "At the microgreen stage, red cabbage plants are at their peak nutritional value, containing up to 40 times more nutrients than their mature counterparts.",
      ],
      [
        "Rödkålsskott passar alla som är intresserade av hälsosam kost, hållbarhet och att stödja lokal matproduktion. Med sin livfulla färg, utsökta smak och höga näringsinnehåll kommer de garanterat att bli en hit hos alla som provar det.",
        "Red cabbage microgreens are an excellent choice for anyone interested in healthy eating, sustainability, and supporting local food production. With their vibrant color, delicious taste, and high nutritional content, these microgreens are sure to be a hit with anyone who tries them.",
      ],
    ],
    kitchen: [
      [
        "Sallader: Rödkålsskott ger en klar färg och en mild, söt smak till sallader. Använd dem som garnering eller blanda in dem i salladsgrönsakerna för extra textur och näring.",
        "Salads: Red cabbage microgreens add a bright pop of color and a mild, sweet flavor to salads. Use them as a garnish or mix them into the salad greens for added texture and nutrition.",
      ],
      [
        "Smörgåsar: Lägg till ett lager Rödkålsskott till din smörgås för en smakrik och hälsosam crunch.",
        "Sandwiches: Add a layer of red cabbage microgreens to your sandwich for a tasty and healthy crunch.",
      ],
      [
        "Tacos och wraps:  Rödkålsskott är ett perfekt tillskott till tacos och wraps och ger smak och näring.",
        "Tacos and Wraps: Red cabbage microgreens are a perfect addition to tacos and wraps, providing a burst of flavor and nutrition.",
      ],
      [
        "Smoothies: Lägg till en handfull rödkålsskott till din smoothie för en extra boost av vitaminer och mineraler. De fungerar bra med en mängd olika frukter och grönsaker, inklusive bär, bananer och grönkål.",
        "Smoothies: Add a handful of red cabbage microgreens to your smoothie for an extra boost of vitamins and minerals. They work well with a variety of fruits and vegetables, including berries, bananas, and kale.",
      ],
      [
        "Wokade rätter: Lägg till rödkålsskott till ditt favorit wok-recept för en näringsrik och smakrik tillsats.",
        "Stir-Fries: Add red cabbage microgreens to your favorite stir-fry recipe for a nutritious and flavorful addition.",
      ],
      [
        "Omeletter och frittata: Lägg till rödkålsskott till din omelett eller frittata för en läcker och färgstark tillsats.",
        "Omelets and Frittatas: Add red cabbage microgreens to your omelet or frittata for a delicious and colorful addition.",
      ],
    ],
    nutrientList: [
      [
        "Högt innehåll av antioxidanter: Rödkålsskott är en rik källa till antioxidanter såsom antocyaniner, vilka hjälper till att skydda kroppen från skador som orsakas av skadliga fria radikaler.",
        "High in Antioxidants: Red cabbage microgreens are a rich source of antioxidants such as anthocyanins, which help protect the body from damage caused by harmful free radicals.",
      ],
      [
        "Ökar immunförsvaret: Dessa microgreens innehåller höga nivåer av vitamin C och K, vilka är avgörande för ett hälsosamt immunförsvar. Vitamin C hjälper kroppen att bekämpa infektioner och främjar tillväxt och reparation av vävnader, medan vitamin K är nödvändigt för korrekt blodkoagulering.",
        "Boost Immune System: These microgreens contain high levels of vitamins C and K, which are essential for a healthy immune system. Vitamin C helps the body fight off infections and helps promote the growth and repair of tissues, while Vitamin K is necessary for proper blood clotting.",
      ],
      [
        "Minskar inflammation: Rödkålsskott har antiinflammatoriska egenskaper som kan hjälpa till att minska inflammation i kroppen. Detta gör dem till ett utmärkt val för alla med inflammatoriska tillstånd, såsom artrit.",
        "Reduce Inflammation: Red cabbage microgreens have anti-inflammatory properties that can help reduce inflammation in the body. This makes them an excellent choice for anyone with inflammatory conditions such as arthritis.",
      ],
      [
        "Reglerar blodsocker: Rödkålsskott innehåller föreningar som hjälper till att reglera blodsockernivåerna, vilket gör dem till en utmärkt mat för personer med diabetes.",
        "Regulate Blood Sugar: Red cabbage microgreens contain compounds that help regulate blood sugar levels, making them a great food for people with diabetes.",
      ],
      [
        "Stödjer hjärthälsan: De höga nivåerna av antioxidanter och antiinflammatoriska föreningar i rödkålsskott kan hjälpa till att sänka kolesterolnivåerna och minska risken för hjärtsjukdomar.",
        "Support Heart Health: The high levels of antioxidants and anti-inflammatory compounds in red cabbage microgreens can help lower cholesterol levels and reduce the risk of heart disease.",
      ],
      [
        "Hjälper matsmältningen: Rödkålsskott är en bra källa till fiber, vilket kan hjälpa till att förbättra matsmältningen och främja regelbunden tarmrörelse.",
        "Aid Digestion: Red cabbage microgreens are a good source of fiber, which can help improve digestion and promote regular bowel movements.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'bag'
  },
  {
    name: ["Mizuna", "Mizuna"], //
    mustard: true,
    id: "mizuna-tray",
    link: "mizuna",
    latin: "Brassica rapa nipposinica",
    tray: 400,
    size: [40, 100],
    ean: ["7350127310708", "7350127310715"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 14,
    water: 2,
    shelfLife: "7-8",
    emissionFactor: 0.9,
    rateStore: 1.5,
    rateTaste: 2.5,
    rateSpicy: 2.5,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fmizuna.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmizuna3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmizuna1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmizuna2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmizuna3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmizuna4.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmizuna5.JPG?alt=media",
    ],
    subtitle: [
      "En japansk senapskål med mild senap- och kryddsmak. Rik på antioxidanter såsom glukosinolater. Ett gott tillskott i salladen!",
      "A Japanese mustard cabbage with a mild mustard and spice flavor. Rich in antioxidants such as glucosinolates. A good addition to the salad!",
    ],
    menuName: ["Mizuna - Påse", "Mizuna - Bag"],
    color: [
      "Vita stjälkar med grön-lila blad",
      "Light purple leaves and dark purple stems",
    ],
    flavor: ["Mild kryddig senapsmak", "Mild spicy mustard taste"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Mizunaskott är de unga plantorna från Mizuna-växten, som tillhör Brassica-familjen. Dessa microgreens har en distinkt pepprig smak som liknar ruccola men med en liten beska. De är höga i vitamin A, C och K, samt folat och kalcium, vilket gör dem till ett utmärkt tillskott till vilken diet som helst.",
        "Mizuna microgreens are the immature seedlings of the Mizuna plant, which is part of the Brassica family. These microgreens have a distinct peppery flavor that is similar to arugula but with a slight bitterness. They are high in vitamins A, C, and K, as well as folate and calcium, making them an excellent addition to any diet.",
      ],
      [
        "Mizunaskott ger inte bara en näringsboost utan ger också en skvätt färg till varje maträtt. De livfulla gröna bladen med känsliga stjälkar ger en touch av elegans till din måltid.",
        "Not only do Mizuna microgreens pack a nutritional punch, but they also add a splash of color to any dish. The vibrant green leaves with delicate stems add a touch of elegance to your meal presentation.",
      ],
      [
        "Så oavsett om du är en erfaren kock eller en hälsoentusiast är Mizunaskott det perfekta tillskottet till din kulinariska repertoar. Prova dem idag och upplev de underbara smak- och hälsofördelarna de har att erbjuda!",
        "So, whether you are a seasoned chef or a health enthusiast, Mizuna microgreens are the perfect addition to your culinary repertoire. Try them out today and experience the wonderful taste and health benefits they have to offer!",
      ],
    ],
    kitchen: [
      [
        "Sallad: Mizunaskott är ett utmärkt tillskott till vilken sallad som helst. De ger en pepprig smak och en färgklick som kan göra vilken sallad som helst mer intressant.",
        "Salad: Mizuna microgreens make a great addition to any salad. They add a peppery flavor and a burst of color that can make any salad look and taste more interesting.",
      ],
      [
        "Smörgåsar: Tillsätt mizunaskott till smörgåsar för en fräsch och hälsosam tvist. De passar bra med olika fyllningar, från kött till vegetariska alternativ.",
        "Sandwiches: Add mizuna microgreens to sandwiches for a fresh and healthy twist. They pair well with a variety of sandwich fillings, from meats to vegetarian options.",
      ],
      [
        "Wok: Mizunaskott kan läggas till wokrätter för att ge en extra smaksättning och näringsboost. De tillagas snabbt, så lägg till dem mot slutet av tillagningsprocessen för att undvika överkokning.",
        "Stir-fry: Mizuna microgreens can be added to stir-fries to provide an extra boost of flavor and nutrition. They cook quickly, so add them towards the end of the cooking process to prevent overcooking.",
      ],
      [
        "Soppa: Använd mizunaskott som garnering för soppor och ge en extra touch av färg och smak till skålen.",
        "Soup: Use mizuna microgreens as a garnish for soups, adding a pop of color and flavor to the bowl.",
      ],
      [
        "Pizza: Toppa din favoritpizza med mizunaskott för en fräsch och hälsosam tvist på denna klassiska komfortmat.",
        "Pizza: Top your favorite pizza with mizuna microgreens for a fresh and healthy twist on this classic comfort food.",
      ],
      [
        "Smoothies: Tillsätt en handfull mizunaskott till din nästa smoothie för en extra näringsboost. De passar bra med frukt och grönsaker och kan hjälpa till att lägga till mer fibrer och näringsämnen till din dryck.",
        "Smoothies: Add a handful of mizuna microgreens to your next smoothie for an extra nutritional boost. They pair well with fruits and vegetables and can help add more fiber and nutrients to your drink.",
      ],
    ],
    nutrientList: [
      [
        "Rika på vitaminer: Mizunaskott är packade med vitamin A, C och K, vilka är viktiga för att upprätthålla en sund syn, immunfunktion och benhälsa.",
        "Rich in vitamins: Mizuna microgreens are packed with vitamins A, C, and K, which are essential for maintaining healthy vision, immune function, and bone health.",
      ],
      [
        "Bra källa till folat: Folat är ett viktigt näringsämne för gravida kvinnor, eftersom det hjälper till att förhindra fosterskador. Mizunaskott är en bra källa till folat, vilket gör dem till ett bra komplement till en graviditetsdiet.",
        "Good source of folate: Folate is an important nutrient for pregnant women as it helps prevent birth defects. Mizuna microgreens are a good source of folate, making them a great addition to a pregnancy diet.",
      ],
      [
        "Högt innehåll av antioxidanter: Antioxidanter skyddar våra celler från skador orsakade av fria radikaler, vilket kan leda till kroniska sjukdomar. Mizunaskott är rika på antioxidanter såsom karotenoider och flavonoider, vilket gör dem till ett utmärkt komplement till en antiinflammatorisk kost.",
        "High in antioxidants: Antioxidants protect our cells from damage caused by free radicals, which can lead to chronic diseases. Mizuna microgreens are rich in antioxidants such as carotenoids and flavonoids, making them a great addition to an anti-inflammatory diet.",
      ],
      [
        "Kan stödja viktminskning: Mizunaskott är låga i kalorier men höga i fiber, vilket kan hjälpa dig att känna dig mätt och nöjd under längre perioder. Detta kan hjälpa till att stödja viktminskning genom att minska det totala kaloriintaget.",
        "May support weight loss: Mizuna microgreens are low in calories but high in fiber, which can help you feel full and satisfied for longer periods. This can help support weight loss by reducing the overall calorie intake.",
      ],
      [
        "Kan stödja kardiovaskulär hälsa: Mizunaskott innehåller nitrat, vilket kan hjälpa till att sänka blodtrycket och förbättra blodflödet. Dessutom kan det höga antioxidantinnehållet i dessa mikrogröna hjälpa till att minska inflammation och förbättra den allmänna kardiovaskulära hälsan. Att införliva mizuna microgreens i din kost kan bidra till att minska risken för hjärtsjukdomar och stroke.",
        "May support cardiovascular health: Mizuna microgreens contain nitrate, which can help lower blood pressure and improve blood flow. Additionally, the high antioxidant content in these microgreens may help reduce inflammation and improve overall cardiovascular health. Incorporating mizuna microgreens into your diet may help reduce the risk of heart disease and stroke.",
      ],
      [
        "Kan förbättra matsmältningen: Mizunaskott är en bra källa till fiber, som kan hjälpa till att stödja en hälsosam matsmältning. Fiber hjälper till att hålla maten i rörelse genom mag-tarmkanalen och kan också främja tillväxten av nyttiga tarmbakterier. Att införliva mizuna microgreens i din kost kan hjälpa till att förbättra den allmänna matsmältningshälsan och minska risken för förstoppning och andra matsmältningsproblem.",
        "May improve digestion: Mizuna microgreens are a good source of fiber, which can help support healthy digestion. Fiber helps keep food moving through the digestive tract and can also promote the growth of beneficial gut bacteria. Incorporating mizuna microgreens into your diet may help improve overall digestive health and reduce the risk of constipation and other digestive issues.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'bag'
  },

  {
    name: ["Röd Amarant", "Red Amaranth"], //
    mustard: false,
    id: "amaranth-tray",
    link: "amaranth",
    latin: "Amaranthus tricolour",
    tray: 105,
    size: [15, 100],
    ean: ["7350127311651", "7350127311668"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 20,
    water: 2,
    shelfLife: "7-10",
    emissionFactor: 1.1,
    rateStore: 2,
    rateTaste: 3,
    rateSpicy: 1.5,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Famaranth.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Famaranth3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Famaranth1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Famaranth2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Famaranth3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Famaranth4.jpg?alt=media",
    ],
    subtitle: [
      "Starkt röda skott med mild och kryddig smak av senap. Sätter vacker färg på maträtten!",
      "Strong red shoots with a mild and spicy taste of mustard. Adds beautiful color to the dish!",
    ],
    menuName: ["Röd Amarant - Påse", "Red Amaranth - Bag"],
    color: ["Starka nyanser av rött & rosa", "Vibrant red and pink"],
    flavor: ["Mild/nötig senapsmak", "Mild, nutty taste of mustard"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Röd Amarant microgreens är det perfekta tillskottet till alla kök eller restauranger som vill höja smaken och färgen på sina rätter.",
        "Red Amaranth Microgreens - the perfect addition to any kitchen or restaurant looking to elevate their dishes with a pop of color and flavor.",
      ],
      [
        "De små skotten är packade med näringsämnen och har en utsökt, något nötaktig smak. Röd Amarant microgreens är de unga plantorna från Amaranth-växten, vilken är en traditionell basgröda i många delar av världen.",
        "These tiny sprouts are packed with nutrients and have a delicious, slightly nutty flavor. Red Amaranth Microgreens are the young seedlings of the Amaranth plant, which is a traditional staple crop in many parts of the world.",
      ],
      [
        "De är inte bara otroligt smakrika och visuellt tilltalande, utan dessa microgreens är också en stor källa till vitamin A, C och K, samt järn, kalcium och antioxidanter. De är ett utmärkt tillskott till en hälsosam kost och kan enkelt läggas till i sallader, smörgåsar, smoothies eller som garnering på valfri maträtt.",
        "Not only are they incredibly flavorful and visually appealing, but these microgreens are also a great source of vitamins A, C, and K, as well as iron, calcium, and antioxidants. They're an excellent addition to any healthy diet, and can easily be incorporated into salads, sandwiches, smoothies, or as a garnish on any dish.",
      ],
      [
        "Våra Röda Amarant microgreens odlas med hållbara jordbruksmetoder, vilket säkerställer högsta kvalitet på varje planta. Vi skördar noggrant och förpackar dem färska åt dig, så att du kan njuta av deras livfulla färg och utsökta smak.",
        "Our Red Amaranth Microgreens are grown using sustainable farming practices, ensuring that each seedling is of the highest quality. We carefully harvest and package them fresh for you, so that you can enjoy their vibrant color and delicious taste.",
      ],
      [
        "Lägg till en touch av livfullhet till dina måltider med Röd Amarant microgreens - det perfekta sättet att förbättra din matlagning och din hälsa.",
        "Add a touch of freshness and vibrancy to your meals with Red Amaranth Microgreens - the perfect way to enhance your cooking and improve your health.",
      ],
    ],
    kitchen: [
      [
        "Sallader: Lägg till Röd Amarant microgreens i ditt favoritsalladsrecept för extra smak och textur.",
        "Salads: Add Red Amaranth Microgreens to your favorite salad recipe for an extra burst of flavor and texture.",
      ],
      [
        "Smörgåsar och wraps: Använd Röd Amarant microgreens som en hälsosam och smakrik topping till smörgåsar och wraps.",
        "Sandwiches and Wraps: Use Red Amaranth Microgreens as a healthy and flavorful topping for sandwiches and wraps.",
      ],
      [
        "Smoothies: Lägg till en handfull Röda Amarant microgreens i ditt favoritsmoothierecept för en näringsrik boost.",
        "Smoothies: Add a handful of Red Amaranth Microgreens to your favorite smoothie recipe for a nutrient-packed boost.",
      ],
      [
        "Soppor och grytor: Strö Röd Amarant microgreens över soppor och grytor för en pop av färg och smak.",
        "Soups and Stews: Sprinkle Red Amaranth Microgreens on top of soups and stews for a pop of color and flavor.",
      ],
      [
        "Tacos och Burrito Bowls: Använd Röd Amarant microgreens som en hälsosam och god topping till tacos och burrito bowls.",
        "Tacos and Burrito Bowls: Use Red Amaranth Microgreens as a healthy and tasty topping for tacos and burrito bowls.",
      ],
      [
        "Garnering: Använd Röd Amarant microgreens som garnering på alla rätter, inklusive pasta, pizza, rostade grönsaker eller grillat kött.",
        "Garnishes: Use Red Amaranth Microgreens as a garnish on any dish, including pasta, pizza, roasted vegetables, or grilled meats.",
      ],
    ],
    nutrientList: [
      [
        "Vitaminer: De är en utmärkt källa till vitamin A, C och K, järn, kalcium och antioxidanter, som kan hjälpa till att stödja ett hälsosamt immunsystem, syn och benhälsa.",
        "Rich in Nutrients: Red Amaranth Microgreens are an excellent source of vitamins A, C, and K, iron, calcium, and antioxidants, which can help support a healthy immune system, vision, and bone health.",
      ],
      [
        "Antiinflammatoriska egenskaper: Röd Amarant microgreens innehåller föreningar som kan hjälpa till att minska inflammation i kroppen, vilket potentiellt minskar risken för kroniska sjukdomar som hjärtsjukdomar, cancer och diabetes.",
        "Anti-Inflammatory Properties: Red Amaranth Microgreens contain compounds that may help reduce inflammation in the body, potentially lowering the risk of chronic diseases like heart disease, cancer, and diabetes.",
      ],
      [
        "Matsmältningshälsa: Fiberinnehållet i Röd Amarant microgreens kan stödja matsmältningen.",
        "Digestive Health: The fiber content in Red Amaranth Microgreens can help support digestive health and regularity.",
      ],
      [
        "Potentiella cancerbekämpande egenskaper: De är rika på antioxidanter, vilket kan bidra till att minska risken för cancer genom att skydda cellerna från skador orsakade av fria radikaler.",
        "Potential Cancer-Fighting Properties: Red Amaranth Microgreens are rich in antioxidants, which may help reduce the risk of cancer by protecting cells from damage caused by free radicals.",
      ],
      [
        "Viktkontroll: Röd Amarant microgreens är låga i kalorier och höga i fiber, vilket kan hjälpa till att stödja viktkontroll genom att främja en känsla av mättnad och minska det totala kaloriintaget.",
        "Weight Management: Red Amaranth Microgreens are low in calories and high in fiber, which can help support weight management by promoting feelings of fullness and reducing overall caloric intake.",
      ],
      [
        "Blodsockerkontroll: De kan hjälpa till att reglera blodsockernivåerna på grund av deras höga fiberinnehåll och låga glykemiska index, vilket gör dem till ett potentiellt användbart livsmedel för personer med diabetes eller insulinresistens.",
        "Blood Sugar Control: Red Amaranth Microgreens may help regulate blood sugar levels due to their high fiber content and low glycemic index, making them a potentially helpful food for those with diabetes or insulin resistance.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'bag'
  },
  {
    name: ["Koriander", "Coriander"], //
    mustard: false,
    id: "coriander-tray",
    link: "coriander",
    latin: "Coriandrum sativum",
    tray: 200,
    size: [20, 100],
    ean: ["7350127311507", "7350127311514"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 20,
    water: 1,
    shelfLife: "7-10",
    emissionFactor: 1,
    rateStore: 2,
    rateTaste: 1.5,
    rateSpicy: 3,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fcoriander.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fcoriander2.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fcoriander1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fcoriander2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fcoriander3.jpeg?alt=media",
    ],
    subtitle: [
      "En mycket populär ört med sin citrus-aktiga smak. Används ofta i det mexikanska & asiatiska köket och är en fin smakkomponent i fisk- och skaldjursrätter. Rik på vitamin A, C, E & K samt kalcium & järn.",
      "A very popular herb with its citrusy taste. Often used in Mexican & Asian cuisine and is a nice flavor component in fish and seafood dishes. Rich in vitamins A, C, E & K as well as calcium & iron.",
    ],
    menuName: ["Koriander - Påse", "Coriander - Bag"],
    color: ["Vita stjälkar med gröna blad", "White stems with green leafs"],
    flavor: [
      "Stark smak av citrus/koriander",
      "Strong taste of citrus/coriander",
    ],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Koriandermicrogreens, är ett näringsrikt och smakrikt tillskott till alla måltider. Dessa små växter är packade med näringsämnen och har en utsökt, fräsch smak som kan liva upp vilken maträtt som helst.",
        "Coriander microgreens, also known as cilantro microgreens, are a nutritious and flavorful addition to any meal. These tiny plants are packed with nutrients and have a delicious, fresh taste that can liven up any dish.",
      ],
      [
        "En av de främsta fördelarna med koriandermicrogreens är deras höga näringstäthet. De innehåller höga halter av vitaminerna A, C och K, samt mineraler som kalcium, järn och kalium. De innehåller också antioxidanter, vilka kan hjälpa till att skydda dina celler från skador orsakade av fria radikaler.",
        "One of the main benefits of coriander microgreens is their high nutrient density. They contain high levels of vitamins A, C, and K, as well as minerals such as calcium, iron, and potassium. They also contain antioxidants, which can help protect your cells from damage caused by free radicals.",
      ],
      [
        "Förutom sina näringsmässiga fördelar är koriandermicrogreens också otroligt mångsidiga i köket. De har en ljus, fräsch smak som passar bra med många olika typer av mat. Lägg till dem i sallader, smörgåsar, soppor, tacos eller använd dem som garnering på dina favoriträtter. De är också ett bra komplement till smoothies eller gröna juicer.",
        "In addition to their nutritional benefits, coriander microgreens are also incredibly versatile in the kitchen. They have a bright, fresh flavor that pairs well with many different types of cuisine. Add them to salads, sandwiches, soups, tacos, or use them as a garnish on your favorite dishes. They also make a great addition to smoothies or green juices.",
      ],
      [
        "Oavsett om du är en matälskare som vill prova något nytt eller en hälsomedveten individ som vill lägga till fler näringsämnen i din kost, är våra koriandermicrogreens det perfekta valet. Prova dem idag och upplev deras läckra smak och imponerande näringsfördelar!",
        "Whether you're a foodie looking to try something new or a health-conscious individual looking to add more nutrients to your diet, our coriander microgreens are the perfect choice. Try them today and experience their delicious flavor and impressive nutritional benefits!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Lägg till en näve koriander mikrogrönt till din favoritsallad för att ge en fräsch, pepprig smak.",
        "Salads: Add a handful of coriander microgreens to your favorite salad to add a fresh, peppery flavor.",
      ],
      [
        "Smörgåsar och wraps: Lägg till koriander mikrogrönt till din favoritmacka eller wrap för ett extra lager av smak och näring.",
        "Sandwiches and Wraps: Add coriander microgreens to your favorite sandwich or wrap for an extra layer of flavor and nutrition.",
      ],
      [
        "Tacos och burritos: Strö koriander mikrogrönt ovanpå dina favorittacos eller burritos för en fräsch och livlig finish.",
        "Tacos and Burritos: Sprinkle coriander microgreens on top of your favorite tacos or burritos for a fresh and vibrant finish.",
      ],
      [
        "Soppor och grytor: Lägg till koriander mikrogrönt till dina favoritsoppor och grytor för en fräsch, örtig smak.",
        "Soups and Stews: Add coriander microgreens to your favorite soups and stews for a fresh, herbaceous flavor.",
      ],
      [
        "Smoothies och juicer: Lägg till en handfull koriander mikrogrönt till dina smoothies eller gröna juicer för en extra näringsboost.",
        "Smoothies and Juices: Add a handful of coriander microgreens to your smoothies or green juices for an added nutritional boost.",
      ],
      [
        "Garnering: Använd koriander mikrogrönt som garnering på dina favoriträtter för att lägga till lite färg och smak.",
        "Garnish: Use coriander microgreens as a garnish on your favorite dishes to add a pop of color and a burst of flavor.",
      ],
    ],
    nutrientList: [
      [
        "Rik på antioxidanter: Mikrogrönt koriander är rikt på antioxidanter, som kan hjälpa till att skydda dina celler från skador orsakade av skadliga fria radikaler.",
        "Rich in Antioxidants: Coriander microgreens are rich in antioxidants, which can help protect your cells from damage caused by harmful free radicals.",
      ],
      [
        "Stöder matsmältningshälsa: Koriandermikrogrönt har naturliga matsmältningsenzymer som kan hjälpa till att förbättra matsmältningen och minska inflammation i tarmen.",
        "Supports Digestive Health: Coriander microgreens have natural digestive enzymes that can help improve digestion and reduce inflammation in the gut.",
      ],
      [
        "Ökar immunförsvaret: Mikrogrönt av koriander är fullmatat med vitaminer och mineraler som stödjer ett hälsosamt immunförsvar, såsom vitamin C, som hjälper till att bekämpa infektioner och virus.",
        "Boosts Immune System: Coriander microgreens are packed with vitamins and minerals that support a healthy immune system, such as vitamin C, which helps to fight infections and viruses.",
      ],
      [
        "Sänker blodsockernivåerna: Koriandermikrogrönt har visat sig hjälpa till att sänka blodsockernivåerna och kan vara ett användbart komplement till en diabetesvänlig kost.",
        "Lowers Blood Sugar Levels: Coriander microgreens have been found to help lower blood sugar levels and can be a helpful addition to a diabetes-friendly diet.",
      ],
      [
        "Antiinflammatoriska egenskaper: Mikrogrönt koriander har antiinflammatoriska egenskaper som kan hjälpa till att minska inflammation i kroppen, vilket är fördelaktigt för att hantera kroniska sjukdomar som artrit och astma.",
        "Anti-inflammatory Properties: Coriander microgreens have anti-inflammatory properties that can help reduce inflammation in the body, which is beneficial for managing chronic diseases such as arthritis and asthma.",
      ],
      [
        "Främjar frisk hud och hår: Mikrogröna koriander är rika på vitamin A och C, som är avgörande för frisk hud och hår. Dessa vitaminer kan hjälpa till att förhindra för tidigt åldrande och stödja tillväxten av starkt, friskt hår.",
        "Promotes Healthy Skin and Hair: Coriander microgreens are rich in vitamins A and C, which are essential for healthy skin and hair. These vitamins can help prevent premature aging and support the growth of strong, healthy hair.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'bag'
  },
  {
    name: ["Röd Senap", "Red Mustard"], //
    mustard: true,
    id: "redMustard-tray",
    link: "redmustard",
    latin: "Brassica juncea",
    tray: 280,
    size: [40, 100],
    ean: ["7350127310654", "7350127310661"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 14,
    water: 3,
    shelfLife: "7-10",
    emissionFactor: 1.2,
    rateStore: 2,
    rateTaste: 2,
    rateSpicy: 2.5,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fmustard.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredmustard3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredmustard1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredmustard2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredmustard3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredmustard4.jpeg?alt=media",
    ],
    subtitle: [
      "Rik på vitaminer, antioxidanter och mineraler. Med sin söta smak av senap gör den sig bra i sallader eller till kött. En japansk variant med vackra färger som skiftar mellan grönt, rött & lila.",
      "Rich in vitamins, antioxidants and minerals. With its sweet mustard taste, it works well in salads or with meat. A Japanese variant with beautiful colors that shift between green, red & purple.",
    ],
    menuName: ["Röd Senap - Påse", "Red Mustard - Bag"],
    color: [
      "Vita stjälkar med grön-röda blad",
      "White stems with green-red leafs",
    ],
    flavor: ["Kryddig senapsmak", "Peppery mustard"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Senapsskott är det perfekta tillskottet till alla kök eller restauranger som vill höja sina rätter med en touch av djärv och syrlig smak. Våra senapsskott skördas vid toppen av färskhet, vilket gör dem till en näringsspäckad superfood som ger en smakupplevelse till varje måltid. Med en distinkt pepprig smak och en öm, krispig konsistens är dessa mikrogrönsaker perfekta för sallader, smörgåsar, soppor och mycket mer.",
        "Mustard microgreens, the perfect addition to any kitchen or restaurant seeking to elevate their dishes with a touch of bold and tangy flavor. Our mustard microgreens are harvested at the peak of freshness, making them a nutrient-packed superfood that adds a burst of flavor to any meal. With a distinct peppery taste and a tender, crisp texture, these microgreens are perfect for salads, sandwiches, soups, and more.",
      ],
      [
        "Dessa microgreens ger inte bara smak och konsistens till dina måltider utan ger också en mängd näringsfördelar. De har högt innehåll av antioxidanter, vitaminer och mineraler, inklusive vitamin C, vitamin K och kalium.",
        "Not only do these microgreens add flavor and texture to your meals, but they also bring a wealth of nutritional benefits. They are high in antioxidants, vitamins, and minerals, including vitamin C, vitamin K, and potassium.",
      ],
      [
        "Sammantaget är våra senapsskott det perfekta valet för alla som vill sätta en läcker och näringsrik touch på sina rätter. Med sin livfulla smak och många hälsofördelar kommer dessa microgreens garanterat att bli en stapelvara i ditt kök.",
        "Overall, our mustard microgreens are the perfect choice for anyone who wants to add a delicious and nutritious touch to their dishes. With their vibrant flavor and numerous health benefits, these microgreens are sure to become a staple in your kitchen.",
      ],
    ],
    kitchen: [
      [
        "Sallader: Senapsskott ger en utsökt pepprig smak till vilken sallad som helst. De passar särskilt bra tillsammans med andra grönsaker, såsom ruccola, spenat eller sallad.",
        "Salads: Mustard microgreens add a delicious peppery flavor to any salad. They pair particularly well with other greens, such as arugula, spinach, or lettuce.",
      ],
      [
        "Smörgåsar och wraps: Lägg till en handfull senapsskott till din smörgås eller wrap för extra smak och konsistens.",
        "Sandwiches and wraps: Add a handful of mustard microgreens to your sandwich or wrap for an extra pop of flavor and texture.",
      ],
      [
        "Soppor och grytor: Använd senapsskott för att garnera dina soppor eller grytor, eller lägg till dem i slutet för en extra smakupplevelse.",
        "Soups and stews: Use mustard microgreens to garnish your soups or stews, or add them in at the end for an extra burst of flavor.",
      ],
      [
        "Tacos och burritos: Senapsskott kan ge en fin crunch och smak till dina mexikanskinspirerade rätter.",
        "Tacos and burritos: Mustard microgreens can add a nice crunch and flavor to your Mexican-inspired dishes.",
      ],
      [
        "Smoothies och juicer: Prova att lägga till en handfull senapsskott till dina smoothies eller juicer för en näringstillskott och en unik smak.",
        "Smoothies and juices: Try adding a handful of mustard microgreens to your smoothies or juices for a boost of nutrition and a unique flavor.",
      ],
      [
        "Omeletter och frittatas: Tillsätt lite senapsskott till dina äggrätter för extra smak och näring.",
        "Omelets and frittatas: Add some mustard microgreens to your egg dishes to add some extra flavor and nutrition.",
      ],
    ],
    nutrientList: [
      [
        "Rik på antioxidanter: Senapsskott innehåller en mängd olika antioxidanter, som kan hjälpa till att neutralisera skadliga fria radikaler i kroppen och minska risken för kroniska sjukdomar.",
        "Rich in antioxidants: Mustard microgreens contain a variety of antioxidants, which can help to neutralize harmful free radicals in the body and reduce the risk of chronic diseases.",
      ],
      [
        "Högt innehåll av vitaminer och mineraler: Senapsskott är en bra källa till viktiga vitaminer och mineraler, såsom vitamin C, vitamin K, kalium, kalcium och järn.",
        "High in vitamins and minerals: Mustard microgreens are a good source of important vitamins and minerals, such as vitamin C, vitamin K, potassium, calcium, and iron.",
      ],
      [
        "Kan hjälpa till att minska inflammation: Föreningarna i senapsskott har antiinflammatoriska egenskaper, vilket kan hjälpa till att minska inflammation i kroppen och lindra symtom på tillstånd som artrit och astma.",
        "May help reduce inflammation: The compounds in mustard microgreens have anti-inflammatory properties, which may help to reduce inflammation in the body and alleviate symptoms of conditions such as arthritis and asthma.",
      ],
      [
        "Kan förbättra hjärthälsa: Senapsskott innehåller föreningar som har visat sig hjälpa till att sänka blodtrycket, minska kolesterolnivåerna och förbättra hjärthälsa.",
        "May improve heart health: Mustard microgreens contain compounds that have been shown to help lower blood pressure, reduce cholesterol levels, and improve overall heart health.",
      ],
      [
        "Kan stödja matsmältningen: Fibrerna och andra föreningar som finns i senapsskott kan hjälpa till att främja hälsosam matsmältning och förhindra problem som förstoppning och uppblåsthet.",
        "May support digestion: The fiber and other compounds found in mustard microgreens may help to promote healthy digestion and prevent issues such as constipation and bloating.",
      ],
      [
        "Kan ha anticancereffekter: Vissa studier har föreslagit att föreningarna som finns i senapsmikrogrönt kan ha anticanceregenskaper, vilket potentiellt kan hjälpa till att förhindra tillväxt och spridning av cancerceller.",
        "May have anticancer effects: Some studies have suggested that the compounds found in mustard microgreens may have anticancer properties, potentially helping to prevent the growth and spread of cancer cells.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'bag'
  },
  {
    name: ["Röd Pak Choi", "Red Pak Choi"], //
    mustard: false,
    id: "redPakChoi-tray",
    link: "redpakchoi",
    latin: "Brassica rapa subsp. chinensis",
    tray: 280,
    size: [40, 100],
    ean: ["7350127311422", "7350127311439"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 14,
    water: 2,
    shelfLife: "7-10",
    emissionFactor: 1.1,
    rateStore: 2,
    rateTaste: 2,
    rateSpicy: 1.5,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2FPakChoi-Icon.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredpakchoi3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredpakchoi1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredpakchoi2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredpakchoi3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredpakchoi4.jpg?alt=media",
    ],
    subtitle: [
      "En kinesisk kål med robust & söt smak och vackra färger som skiftar mellan grönt, rött & lila. Den är en utmärkt källa till vitamin B2, B6, C, A, K, järn, kalcium, magnesium, fosfor, kalium och zink.",
      "A Chinese cabbage with a robust & sweet taste and beautiful colors that shift between green, red & purple. It is an excellent source of vitamins B2, B6, C, A, K, iron, calcium, magnesium, phosphorus, potassium and zinc.",
    ],
    menuName: ["Röd Pak Choi - Påse", "Red Pak Choi - Bag"],
    color: [
      "Vita stammar med grön-röda bladpar",
      "White stems and green & red leafs",
    ],
    flavor: ["Söt smak av kål", "Sweet taste of kale"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Pak Choi Microgreens är små versioner av den populära kinesiska bladgrönsaken, packade med en rad viktiga näringsämnen och en utsökt smak. Dessa babygrönsaker är en stor källa till vitamin A, C och K, såväl som antioxidanter, fibrer och mineraler som kalcium, järn och kalium. De är också låga i kalorier och kolhydrater, vilket gör dem till ett idealiskt komplement till vilken diet som helst.",
        "Pak Choi Microgreens are tiny versions of the popular Chinese leafy vegetable, packed with a range of essential nutrients and a delicious flavor. These baby greens are a great source of vitamins A, C, and K, as well as antioxidants, fiber, and minerals such as calcium, iron, and potassium. They are also low in calories and carbohydrates, making them an ideal addition to any diet.",
      ],
      [
        "Pak Choi Microgreens har en mild och lätt söt smak som passar bra med en rad ingredienser, och deras delikata konsistens ger en fräsch crunch till varje maträtt. De är också ett bra sätt att lägga till lite färg och näring till dina måltider.",
        "Pak Choi Microgreens have a mild and slightly sweet flavor that pairs well with a range of ingredients, and their delicate texture adds a fresh crunch to any dish. They are also a great way to add a pop of color and nutrition to your meals.",
      ],
      [
        "Våra Pak Choi Microgreens odlas med de högsta standarderna för jordbruk, vilket säkerställer att du får den färskaste och hälsosammaste produkten möjligt. De är redo att lägga till en hälsosam twist till dina kulinariska skapelser.",
        "Our Pak Choi Microgreens are grown using the highest standards of farming, ensuring that you receive the freshest and healthiest product possible. They are ready to add a healthy twist to your culinary creations.",
      ],
      [
        "Sammanfattningsvis, om du letar efter ett näringsrikt, smakrikt och lättodlat tillskott till din kost, är Pak Choi Microgreens det perfekta valet. Prova dem idag och upplev fördelarna med dessa läckra och hälsosamma mikrogrönsaker!",
        "In conclusion, if you are looking for a nutritious, flavorful, and easy-to-grow addition to your diet, Pak Choi Microgreens are the perfect choice. Try them today and experience the benefits of these delicious and healthy microgreens for yourself!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Pak Choi microgreens är en utmärkt komplettering till sallader och ger en fräsch och krispig konsistens som passar bra med andra grönsaker och ingredienser.",
        "Salads: Pak choi microgreens make a great addition to salads, providing a fresh and crunchy texture that pairs well with other greens and vegetables.",
      ],
      [
        "Smörgåsar och wraps: Dessa mikrogrönsaker kan användas för att tillföra smak och näring till dina smörgåsar eller wraps och ersätta traditionella salladsblad med ett mer näringsrikt alternativ.",
        "Sandwiches and Wraps: These microgreens can be used to add a pop of flavor and nutrition to your sandwiches or wraps, replacing traditional lettuce with a more nutrient-dense option.",
      ],
      [
        "Wokrätter och soppor: Pak Choi microgreens är en god tillägg till röror och soppor, som ger en subtil smak och konsistens till rätten.",
        "Stir-Fries and Soups: Pak choi microgreens are a great addition to stir-fries and soups, adding a subtle flavor and texture to the dish.",
      ],
      [
        "Smoothies och juicer: Du kan tillsätta Pak Choi microgreens till dina smoothies eller juicer för en näringstillskott och en fräsch smak.",
        "Smoothies and Juices: You can add Pak choi microgreens to your smoothies or juices for a boost of nutrition and a fresh taste.",
      ],
      [
        "Toppings: Pak Choi microgreens kan användas som garnering eller topping för en rad olika rätter, inklusive pizza, omeletter och mer.",
        "Toppings: Pak choi microgreens can be used as a garnish or topping for a range of dishes, including pizzas, omelets, and more.",
      ],
      [
        "Sushi: Pak Choi microgreens kan vara ett bra tillägg till sushirullar, vilket ger en fräsch och krispig konsistens till rätten.",
        "Sushi Rolls: Pak Choi microgreens can be a great addition to sushi rolls, adding a fresh and crunchy texture to the dish.",
      ],
    ],
    nutrientList: [
      [
        "Rik på vitaminer: Pak choi mikrogrönsaker är en utmärkt källa till vitamin A, C och K, vilket hjälper till att stärka ditt immunförsvar, främja en sund syn och stödja benhälsan.",
        "Rich in Vitamins: Pak choi microgreens are an excellent source of vitamins A, C, and K, which help boost your immune system, promote healthy vision, and support bone health.",
      ],
      [
        "Högt innehåll av antioxidanter: Dessa mikrogrönsaker är också rika på antioxidanter, som hjälper till att skydda dina celler från skador orsakade av fria radikaler och minskar risken för kroniska sjukdomar.",
        "High in Antioxidants: These microgreens are also rich in antioxidants, which help protect your cells from damage caused by free radicals and reduce the risk of chronic diseases.",
      ],
      [
        "Avgiftning: Svavelföreningarna i Pak choi microgreens har visat sig hjälpa kroppen att avgifta, vilket främjar en hälsosam leverfunktion.",
        "Detoxification: The sulfur compounds in Pak choi microgreens have been shown to help the body detoxify, promoting healthy liver function.",
      ],
      [
        "Främjar matsmältningen: Dessa mikrogrönsaker är rika på fiber, vilket hjälper till att stödja en hälsosam matsmältning, förhindrar förstoppning och minskar risken för matsmältningsbesvär.",
        "Promotes Digestion: These microgreens are rich in fiber, which helps support healthy digestion, prevent constipation, and reduce the risk of digestive disorders.",
      ],
      [
        "Sänker blodtrycket: Kalium som finns i Pak choi microgreens hjälper till att reglera blodtrycksnivåerna, vilket minskar risken för hjärtsjukdomar och stroke.",
        "Lowers Blood Pressure: The potassium found in Pak choi microgreens helps regulate blood pressure levels, reducing the risk of heart disease and stroke.",
      ],
      [
        "Viktminskning: Pak choi microgreens är låga i kalorier och höga i fiber, vilket gör dem till ett utmärkt komplement till alla viktminskningsdieter.",
        "Weight Loss: Pak choi microgreens are low in calories and high in fiber, making them an excellent addition to any weight loss diet.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'bag'
  },{
    name: ["Asiatisk Mix", "Asia Mix"], //
    mustard: true,
    id: "asia-tray",
    link: "asiamix",
    latin: " - ",
    tray: 280,
    size: [800],
    ean: ["17350127311702"],
    storeData: ["Kylvara", "Refrigeration"],
    leadTime: 14,
    storeTemp: 8,
    water: 3,
    shelfLife: "7-10",
    emissionFactor: 1.1,
    rateStore: 2,
    rateTaste: 2.5,
    rateSpicy: 2.5,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fasianmix.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix4.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix5.jpeg?alt=media",
    ],
    subtitle: [
      "En blandning av Tatsoi, Pak Choi, Röd Komatsuna, Kålrot & Rädisa som tillsammans skapar ljuva toner av Asien i både färg och smak.",
      "A mixture of Tatsoi, Pak Choi, Red Komatsuna, Cabbage & Radish that together create sweet tones of Asia in both color and taste.",
    ],
    menuName: ["Asiatisk Mix - 20 x 40g", "Asia Mix - 20 x 40g"],
    color: [
      "En ljuvlig mix av grönt, lila & rött",
      "A vibrant mix of green, purple & red",
    ],
    flavor: ["Söt med peppriga inslag", "Sweet with hints of pepper"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Denna blandning är packad med en mängd olika smaker och näringsämnen, inklusive Tatsoi, Pak Choi, Rova, Komatsuna och Rädisa, vilket ger en mångsidig blandning av mikrogrönsaker att njuta av.",
        "The Asian Mix - This blend is packed with a variety of flavors and nutrients, including Tatsoi, Pak Choi, Turnip, Komatsuna, and Radish, providing a diverse mix of microgreens to enjoy.",
      ],
      [
        "Tatsoi, även känd som spenatsenap, är en bladgrönsak som innehåller mycket vitamin A, C och K, samt kalcium och järn. Dess peppriga smak ger en pigg kick till sallader, smörgåsar och röror.",
        "Tatsoi, also known as spinach mustard, is a leafy green that is high in vitamin A, C, and K, as well as calcium and iron. Its peppery flavor adds a zesty kick to salads, sandwiches, and stir-fries.",
      ],
      [
        "Pak Choi, även känd som kinakål, är en vanlig ingrediens i det asiatiska köket. Den är låg i kalorier och hög i fiber, vitamin C och vitamin K. Dess milda smak och krispiga konsistens gör den perfekt för röror, soppor och grytor.",
        "Pak Choi, also known as Chinese cabbage, is a common ingredient in Asian cuisine. It is low in calories and high in fiber, vitamin C, and vitamin K. Its mild flavor and crispy texture make it perfect for stir-fries, soups, and stews.",
      ],
      [
        "Mikrogrönt av kålrot ger en mild, söt och lätt bitter smak, vilket ger en uppfriskande smak och crunch till dina måltider. De är höga i vitamin C, K och fiber och kan användas i en mängd olika rätter.",
        "Turnip microgreens offer a mild, sweet, and slightly bitter taste, adding a refreshing flavor and crunch to your meals. They are high in vitamin C, K, and fiber, and can be used in a variety of dishes.",
      ],
      [
        "Komatsuna, även känd som japansk senapsspenat, innehåller mycket C-vitamin och järn. Dess smak är en kombination av spenat och senapsgrönsaker, med en syrlig och lite bitter smak. Den kan ätas rå i sallader eller tillagas i soppor och röror.",
        "Komatsuna, also known as Japanese mustard spinach, is high in vitamin C and iron. Its flavor is a combination of spinach and mustard greens, with a tangy and slightly bitter taste. It can be eaten raw in salads or cooked in soups and stir-fries.",
      ],
      [
        "Rädisor är rika på antioxidanter, C-vitamin och folat och ger en mild pepprig smak till varje maträtt. De är perfekta för smörgåsar, sallader och garnering av rätter.",
        "Radish microgreens are rich in antioxidants, vitamin C, and folate, and add a mild peppery flavor to any dish. They are perfect for sandwiches, salads, and garnishing dishes.",
      ],
      [
        "Vår asiatiska blandning är den perfekta mixen för dig som vill uppleva en blandning av smaker i samma förpackning. Odlade med precision och omsorg, ser vi till att våra mikrogröna är av högsta kvalitet och fräschhet. Prova vår Asiatiska Mix idag och njut av smaken av Asien i ditt eget kök!",
        "Our Asian Mix is the perfect blend for those who want to experience a mix of flavors and nutrients in one package. Grown with precision and care, we ensure that our microgreens are of the highest quality and freshness. Try our Asian Mix today and enjoy the taste of Asia in your own kitchen!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Asiatisk Mix är en bra tillsats till salladen. Dess variation av texturer och smaker kan lägga till ett extra lager av komplexitet och näring till din maträtt.",
        "Salads: The Asian Mix is a great addition to any salad. Its variety of textures and flavors can add an extra layer of complexity and nutrition to your dish.",
      ],
      [
        "Smörgåsar och wraps: Lägg till den asiatiska mixen på din favoritmacka eller wrap för extra crunch och smak.",
        "Sandwiches and wraps: Add the Asian Mix to your favorite sandwich or wrap for an extra crunch and flavor.",
      ],
      [
        "Wok-rätter: Asian Mix är en perfekt tillsats till wok-rätter. Dess milda och lätt bittra smak passar bra med en mängd olika såser och proteiner.",
        "Stir-fries: The Asian Mix is a perfect addition to stir-fries. Its mild and slightly bitter flavor pairs well with a variety of sauces and proteins.",
      ],
      [
        "Soppor: Lägg till den asiatiska mixen i soppor och grytor för en extra lager av smak och näring.",
        "Soups: Add the Asian Mix to soups and stews for an extra layer of flavor and nutrition.",
      ],
      [
        "Garnering: Använd Asiatisk Mix som garnering till valfri maträtt. Dess färgglada och unika utseende kan lägga till färg och intresse till din tallrik.",
        "Garnish: Use the Asian Mix as a garnish for any dish. Its colorful and unique appearance can add a pop of color and interest to your plate.",
      ],
      [
        "Smoothies: Tillsätt en handfull av den asiatiska mixen till ditt favoritsmoothierecept för en extra boost av näring och smak.",
        "Smoothies: Add a handful of the Asian Mix to your favorite smoothie recipe for an extra boost of nutrition and flavor.",
      ],
    ],
    nutrientList: [
      [
        "Rikt på vitaminer och mineraler: Var och en av mikrogrönsakerna i denna blandning är en stor källa till vitaminer och mineraler, som vitamin A, C, K och järn. Dessa näringsämnen är viktiga för att upprätthålla ett hälsosamt immunförsvar, starka ben och god syn.",
        "Rich in vitamins and minerals: Each of the microgreens in this blend is a great source of vitamins and minerals, such as vitamin A, C, K, and iron. These nutrients are essential for maintaining a healthy immune system, strong bones, and good vision.",
      ],
      [
        "Lågt kaloriinnehåll: Asiatiska mixen innehåller få kalorier, vilket gör dem till ett bra komplement till alla hälsosamma dieter. De är också höga i fiber, vilket hjälper till att hålla dig mätt och nöjd.",
        "Low in calories: The Asian Mix microgreens are low in calories, making them a great addition to any healthy diet. They are also high in fiber, which helps to keep you feeling full and satisfied.",
      ],
      [
        "Stödjer hjärthälsan: Kålrot innehåller föreningar som har visat sig ha en positiv effekt på hjärthälsan genom att hjälpa till att sänka kolesterolnivåerna.",
        "Supports heart health: Turnip microgreens contain compounds that have been shown to have a positive effect on heart health by helping to lower cholesterol levels.",
      ],
      [
        "Rik på antioxidanter: Rädisan i den asiatiska mixen innehåller särskilt många antioxidanter, som hjälper till att skydda dina celler från skador orsakade av fria radikaler.",
        "Antioxidant-rich: Radish microgreens in the Asian Mix are particularly high in antioxidants, which help to protect your cells from damage caused by free radicals.",
      ],
      [
        "Förbättrar matsmältningen: Pak Choi-mikrogrönt innehåller mycket fiber och kan hjälpa till att stödja en hälsosam matsmältning. De innehåller också föreningar som har visat sig ha en positiv effekt på tarmhälsan.",
        "Boosts digestion: Pak Choi microgreens are high in fiber and can help to support healthy digestion. They also contain compounds that have been shown to have a positive effect on gut health.",
      ],
      [
        "Hjälper till att reglera blodsockret: Tatsoi-mikrogrönt innehåller föreningar som kan hjälpa till att reglera blodsockernivåerna och kan vara fördelaktiga för personer med typ 2-diabetes.",
        "Helps to regulate blood sugar: Tatsoi microgreens contain compounds that can help to regulate blood sugar levels and may be beneficial for people with type 2 diabetes.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'box'
  },
  {
    name: ["Ärtskott", "Pea Shoots"], //
    mustard: false,
    id: "peas-tray",
    link: "peas",
    latin: "Pisum sativum",
    tray: 800,
    size: [3000],
    ean: ["17350127310002"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 15,
    water: 6,
    shelfLife: "14",
    emissionFactor: 1.2,
    rateStore: 3,
    rateTaste: 2,
    rateSpicy: 1,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fpeas.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpeas3.jpg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpeas1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpeas2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpeas3.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpeas4.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpeas5.jpg?alt=media",
    ],
    subtitle: [
      "Rik på proteiner, antioxidanter & vitaminer och anses vara en riktig näringsbomb. Lätt och frisk smak av ärta som är god i salladen, som garnering eller på smörgåsen.",
      "Rich in proteins, antioxidants and vitamins, it is considered a real nutritional bomb. Light and fresh taste of pea that is good in the salad, as a garnish or on the sandwich.",
    ],
    menuName: ["Ärtskott - 30 x 100g", "Pea Shoots - 30 x 100g"],
    color: ["Från ljus- till mörkgrön", "From light to dark green"],
    flavor: ["Mild söt smak av ärta", "Mild, sweet pea flavor"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Ärtskott är ett läckert och näringsrikt tillskott till vilken måltid som helst! Dessa delikata grönsaker skördas från unga ärtplantor och har en lätt söt och nötaktig smak. De är packade med vitaminer och mineraler, inklusive vitamin A, C och K, samt folat och fibrer. Ärtskott är också låga i kalorier och höga i antioxidanter, vilket gör dem till ett utmärkt val för dem som vill förbättra sin allmänna hälsa.",
        "Pea shoots are a delicious and nutritious addition to any meal! These delicate and tender greens are harvested from young pea plants and have a slightly sweet and nutty flavor. They are packed with vitamins and minerals, including vitamins A, C, and K, as well as folate and fiber. Pea shoots are also low in calories and high in antioxidants, making them a great choice for those looking to boost their overall health.",
      ],
      [
        "Förutom dess hälsofördelar är ärtskott otroligt mångsidiga och kan avnjutas på en mängd olika sätt. De är ett utmärkt tillskott till sallader, smörgåsar, röror och mer. De kan också blandas i smoothies eller användas som garnering till soppor och andra rätter. Våra ärtskott odlas lokalt med hjälp av hållbara odlingsmetoder, vilket säkerställer att du får den färskaste och högsta möjliga produkten. De skördas noggrant och förpackas för att säkerställa maximal fräschör och smak. Ge våra ärtskott en chans och upptäck en ny favoritingrediens för dina måltider!",
        "In addition to their health benefits, pea shoots are incredibly versatile and can be enjoyed in a variety of ways.They make a great addition to salads, sandwiches, stir - fries, and more.They can also be blended into smoothies or used as a garnish for soups and other dishes. Our pea shoots are grown locally using sustainable farming practices, ensuring that you get the freshest and highest- quality product possible.They are carefully harvested and packaged to ensure maximum freshness and flavor.Give our pea shoots a try and discover a new favorite ingredient for your meals!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Ärtskott ger en fräsch, söt och lätt nötig smak till sallader och ger även en krispig konsistens.",
        "Salads: Pea shoots add a fresh, sweet and slightly nutty flavor to salads, and also offer a crisp texture.",
      ],
      [
        "Smörgåsar och wraps: Använd ärtskott som ett fräscht och knaprigt tillskott till smörgåsar och wraps.",
        "Sandwiches and wraps: Use pea shoots as a fresh and crunchy addition to sandwiches and wraps.",
      ],
      [
        "Wokade rätter: Ärtskott kan snabbt tillagas i en wok för ett läckert och hälsosamt tillskott till din måltid.",
        "Stir-fries: Pea shoots can be quickly cooked in a stir-fry for a delicious and healthy addition to your meal.",
      ],
      [
        "Soppor och grytor: Lägg till ärtskott i soppor och grytor som garnering eller för extra näring och konsistens.",
        "Soups and stews: Add pea shoots to soups and stews as a garnish or for extra nutrition and texture.",
      ],
      [
        "Smoothies och juicer: Ärtskott kan blandas i smoothies eller juicer för en extra boost av vitaminer och mineraler.",
        "Smoothies and juices: Pea shoots can be blended into smoothies or juiced for an extra boost of vitamins and minerals.",
      ],
      [
        "Toppings: Strö ärtskott ovanpå pizza, pastarätter och andra måltider för extra smak och näring.",
        "Toppings: Sprinkle pea shoots on top of pizza, pasta dishes, and other meals for added flavor and nutrition.",
      ],
    ],
    nutrientList: [
      [
        "Rika på näringsämnen: Ärtskott är fullpackade med vitaminer och mineraler, inklusive vitamin A, C och K, folat och fiber.",
        "Rich in nutrients: Pea shoots are packed with vitamins and minerals, including vitamins A, C, and K, folate, and fiber.",
      ],
      [
        "Antioxidantegenskaper: Ärtskott innehåller mycket antioxidanter, vilket kan hjälpa till att skydda kroppen från skador orsakade av fria radikaler.",
        "Antioxidant properties: Pea shoots are high in antioxidants, which can help protect the body from damage caused by free radicals.",
      ],
      [
        "Kan stödja hälsosam matsmältning: Fiberinnehållet i ärtskott kan stödja en hälsosam matsmältning och kan hjälpa till att förhindra förstoppning.",
        "May support healthy digestion: The fiber content in pea shoots can support healthy digestion and may help prevent constipation.",
      ],
      [
        "Kan sänka kolesterolnivåerna: Vissa studier tyder på att ärtskott kan hjälpa till att sänka kolesterolnivåerna, vilket potentiellt minskar risken för hjärtsjukdomar.",
        "May lower cholesterol levels: Some studies suggest that pea shoots may help lower cholesterol levels, potentially reducing the risk of heart disease.",
      ],
      [
        "Kan ha antiinflammatoriska egenskaper: Ärtskott innehåller föreningar som har visat sig ha antiinflammatoriska egenskaper, vilket kan vara fördelaktigt för dem som har inflammatoriska tillstånd.",
        "May have anti-inflammatory properties: Pea shoots contain compounds that have been shown to have anti-inflammatory properties, which may be beneficial for those with inflammatory conditions.",
      ],
      [
        "Kan stödja immunförsvaret: Vitaminerna och mineralerna i ärtskott, särskilt vitamin C, kan hjälpa till att stödja immunförsvaret och skydda mot sjukdomar.",
        "May support immune function: The vitamins and minerals in pea shoots, particularly vitamin C, may help support immune function and protect against illness.",
      ],
    ],
    cool: "cool2",
    ec: "ec2",
    hum: "hum2",
    light: "light2",
    ph: "ph2",
    pump: "pump2",
    spray: "spray2",
    temp: "temp2",
    waterTemp: "waterTemp2",
    type: 'box'
  },
  {
    name: ["Rädisa - Sango", "Radish - Sango"], //
    mustard: false,
    id: "radishSango-tray",
    link: "sango",
    latin: "Raphanus sativus",
    tray: 320,
    size: [800],
    ean: ["17350127310200"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 12,
    water: 2,
    shelfLife: "14",
    emissionFactor: 0.9,
    rateStore: 2,
    rateTaste: 2.5,
    rateSpicy: 2,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fradish.png?alt=media",
    logo: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2FpurpleRadishLogo.png?alt=media",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fsango3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fsango1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fsango2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fsango3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fsango4.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fsango5.jpg?alt=media",
    ],
    subtitle: [
      "En näringsbomb fylld med antioxidanter och högkvalitativa proteiner. Snål på fett men rik på protein, fiber, omega-3 och mikronäringsämnen. Den lila färgen piffar upp sallad eller kötträtten.",
      "A nutritional bomb filled with antioxidants and high-quality proteins. Low in fat but rich in protein, fibre, omega-3 and micronutrients. The purple color brightens up a salad or a meat dish.",
    ],
    menuName: ["Rädisa - Sango - 20 x 40g", "Radish - Sango - 20 x 40g"],
    color: [
      "Ljuslila blad och mörklila stjälkar",
      "Light purple leaves and dark purple stems",
    ],
    flavor: ["Pepprig smak av rädisa", "Peppery taste of radish"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Mikrorädisor är det perfekta tillskottet till alla måltider, eftersom de ger en smak- och näringsmässig boost i en liten förpackning. Mikrogrönt är de unga plantorna av olika grönsaker och örter, som skördas när de bara är några centimeter höga. Detta gör dem till en mycket koncentrerad källa till näringsämnen.",
        "Radish microgreens are the perfect addition to any meal, providing a punch of flavor and nutrition in a small package. Microgreens are the young seedlings of various vegetables and herbs that are harvested when they are just a few inches tall, making them a highly concentrated source of nutrients.",
      ],
      [
        "Mikrorädisor har en distinkt pepprig smak som ger en explosion av smak till sallader, smörgåsar och mer. De är också otroligt näringsrika och innehåller höga halter av vitamin C, folat och antioxidanter. Forskning har visat att mikrorädisor kan innehålla upp till 40 gånger mer näringsämnen än sina mogna motsvarigheter.",
        "Radish microgreens have a distinct peppery taste, adding a burst of flavor to salads, sandwiches, and more. They are also incredibly nutritious, containing high levels of vitamin C, folate, and antioxidants. In fact, research has shown that radish microgreens can contain up to 40 times more nutrients than their mature counterparts.",
      ],
      [
        "EVåra mikrorädisor odlas endast med ekologiska, icke-GMO-frön och sköts noggrant för att säkerställa högsta kvalitet. De är lätta att odla och kan avnjutas året runt, vilket gör dem till ett utmärkt komplement till alla kök eller restauranger.",
        "Our radish microgreens are grown using only organic, non-GMO seeds and are carefully tended to ensure the highest quality product. They are easy to grow and can be enjoyed year-round, making them a great addition to any kitchen or restaurant.",
      ],
      [
        "Sammanfattningsvis är mikrorädisor ett läckert och näringsrikt tillskott till alla måltider, fyllda med smak och viktiga näringsämnen. Ge dem en chans och upplev själv vilken skillnad dessa små gröna kan göra!",
        "In summary, radish microgreens are a delicious and nutritious addition to any meal, packed with flavor and essential nutrients. Give them a try and see for yourself the difference that these tiny greens can make!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Mikrorädisor ger en pepprig crunch till sallader och är ett bra substitut för traditionella gröna sallader.",
        "Salads: Radish microgreens add a peppery crunch to salads and are a great substitute for traditional salad greens.",
      ],
      [
        "Smörgåsar och wraps: Lägg till mikrorädisor till smörgåsar och wraps för en explosion av smak och extra näring.",
        "Sandwiches and Wraps: Add radish microgreens to sandwiches and wraps for a burst of flavor and extra nutrition.",
      ],
      [
        "Tacos och burritos: Mikrorädisor passar bra i det mexikanska köket och kan inkluderas i tacos och burritos för extra crunch och smak.",
        "Tacos and Burritos: Radish microgreens pair well with Mexican cuisine and can be added to tacos and burritos for added crunch and flavor.",
      ],
      [
        "Soppor och grytor: Rädisor kan läggas till som garnering till soppor och grytor för att ge textur och smak.",
        "Soups and Stews: Radish microgreens can be added as a garnish to soups and stews to add texture and flavor.",
      ],
      [
        "Smoothies och juicer: Mikrorädisor kan läggas till smoothies och juicer för en näringsboost.",
        "Smoothies and Juices: Radish microgreens can be added to smoothies and juices for a nutrient boost.",
      ],
      [
        "Wok: Mikrorädisor kan läggas till wokrätter för en fräsch och pepprig smak.",
        "Stir-Fries: Radish microgreens can be added to stir-fries for a fresh and peppery flavor.",
      ],
    ],
    nutrientList: [
      [
        "Rika på antioxidanter: Rädisa-mikrogrönt innehåller mycket antioxidanter, vilka hjälper till att skydda kroppen mot skadliga fria radikaler och minskar risken för kroniska sjukdomar, såsom cancer, hjärtsjukdomar och Alzheimers sjukdom.",
        "Rich in Antioxidants: Radish microgreens are high in antioxidants, which help protect the body against harmful free radicals and reduce the risk of chronic diseases such as cancer, heart disease, and Alzheimer's disease.",
      ],
      [
        "Ökar immunförsvaret: Mikrogröna rädisor är rika på vitamin C, vilket är nödvändigt för ett hälsosamt immunförsvar. Vitamin C hjälper också kroppen att ta upp järn, vilket är viktigt för den allmänna hälsan.",
        "Boosts Immune System: Radish microgreens are rich in vitamin C, which is essential for a healthy immune system. Vitamin C also helps the body absorb iron, which is important for overall health.",
      ],
      [
        "Stöder matsmältningshälsan: Rädisor innehåller fibrer, vilket hjälper till att stödja matsmältningshälsan och förhindrar förstoppning. De innehåller också enzymer som hjälper till med matsmältningen.",
        "Supports Digestive Health: Radish microgreens contain fiber, which helps support digestive health and prevent constipation. They also contain enzymes that aid in digestion.",
      ],
      [
        "Antiinflammatorisk: Rädisor innehåller antiinflammatoriska föreningar som kan hjälpa till att minska inflammationen i kroppen och minska risken för kroniska sjukdomar, såsom artrit.",
        "Anti-inflammatory: Radish microgreens contain anti-inflammatory compounds that may help reduce inflammation in the body and lower the risk of chronic diseases such as arthritis.",
      ],

      [
        "Sänker kolesterolnivåerna: Rädisor innehåller föreningar som kan hjälpa till att sänka kolesterolnivåerna och minska risken för hjärtsjukdomar.",
        "Lowers Cholesterol: Radish microgreens contain compounds that may help lower cholesterol levels and reduce the risk of heart disease.",
      ],
      [
        "Högt innehåll av näringsämnen: Rädisor är en koncentrerad näringskälla som innehåller höga halter av vitaminer och mineraler, såsom vitamin A, vitamin K, kalcium och järn.",
        "High in Nutrients: Radish microgreens are a concentrated source of nutrients, containing high levels of vitamins and minerals such as vitamin A, vitamin K, calcium, and iron.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'box'
  },
  {
    name: ["Rädismix", "Radish Mix"], //
    mustard: false,
    id: "radishMix-tray",
    link: "radishmix",
    latin: "Raphanus sativus",
    tray: 400,
    size: [800],
    ean: ["17350127310255"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 12,
    water: 2,
    shelfLife: "14",
    emissionFactor: 0.9,
    rateStore: 2,
    rateTaste: 2.5,
    rateSpicy: 2.5,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fradish.png?alt=media",
    logo: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2FradishLogo.png?alt=media",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fradishmix3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fradishmix1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fradishmix2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fradishmix3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fradishmix4.jpeg?alt=media",
    ],
    subtitle: [
      "Krispig mikrogrönt med en distinkt smak av rädisa som är rik på kolhydrater, mineraler & vitamin C. Kombinationen av färger piffar upp salladen eller kötträtten.",
      "Crispy microgreens with a distinct taste of radish that is rich in carbohydrates, minerals & vitamin C. Combination of colors brightens up a salad or meat dish.",
    ],
    menuName: ["Rädismix - 20 x 40g", "Radish Mix - 20 x 40g"],
    color: [
      "En kombination av grön, rosa, vit och lila",
      "A combination of green, pink, white and purple",
    ],
    flavor: ["Pepprig smak av rädisa", "Peppery taste of radish"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Rädisskott är det perfekta tillskottet till alla måltider, eftersom de ger en smak- och näringsmässig boost i en liten förpackning. Mikrogrönt är de unga plantorna av olika grönsaker och örter, som skördas när de bara är några centimeter höga. Detta gör dem till en mycket koncentrerad källa till näringsämnen.",
        "Radish microgreens are the perfect addition to any meal, providing a punch of flavor and nutrition in a small package. Microgreens are the young seedlings of various vegetables and herbs that are harvested when they are just a few inches tall, making them a highly concentrated source of nutrients.",
      ],
      [
        "Rädisskott har en distinkt pepprig smak som ger en explosion av smak till sallader, smörgåsar och mer. De är också otroligt näringsrika och innehåller höga halter av vitamin C, folat och antioxidanter. Forskning har visat att Rädisskott kan innehålla upp till 40 gånger mer näringsämnen än sina mogna motsvarigheter.",
        "Radish microgreens have a distinct peppery taste, adding a burst of flavor to salads, sandwiches, and more. They are also incredibly nutritious, containing high levels of vitamin C, folate, and antioxidants. In fact, research has shown that radish microgreens can contain up to 40 times more nutrients than their mature counterparts.",
      ],
      [
        "Våra Rädisskott odlas endast med ekologiska, icke-GMO-frön och sköts noggrant för att säkerställa högsta kvalitet. De är lätta att odla och kan avnjutas året runt, vilket gör dem till ett utmärkt komplement till alla kök eller restauranger.",
        "Our radish microgreens are grown using only organic, non-GMO seeds and are carefully tended to ensure the highest quality product. They are easy to grow and can be enjoyed year-round, making them a great addition to any kitchen or restaurant.",
      ],
      [
        "Sammanfattningsvis är Rädisskott ett läckert och näringsrikt tillskott till alla måltider, fyllda med smak och viktiga näringsämnen. Ge dem en chans och upplev själv vilken skillnad dessa små gröna kan göra!",
        "In summary, radish microgreens are a delicious and nutritious addition to any meal, packed with flavor and essential nutrients. Give them a try and see for yourself the difference that these tiny greens can make!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Rädisskott ger en pepprig crunch till sallader och är ett bra substitut för traditionella gröna sallader.",
        "Salads: Radish microgreens add a peppery crunch to salads and are a great substitute for traditional salad greens.",
      ],
      [
        "Smörgåsar och wraps: Lägg till Rädisskott till smörgåsar och wraps för en explosion av smak och extra näring.",
        "Sandwiches and Wraps: Add radish microgreens to sandwiches and wraps for a burst of flavor and extra nutrition.",
      ],
      [
        "Tacos och burritos: Rädisskott passar bra i det mexikanska köket och kan inkluderas i tacos och burritos för extra crunch och smak.",
        "Tacos and Burritos: Radish microgreens pair well with Mexican cuisine and can be added to tacos and burritos for added crunch and flavor.",
      ],
      [
        "Soppor och grytor: Rädisor kan läggas till som garnering till soppor och grytor för att ge textur och smak.",
        "Soups and Stews: Radish microgreens can be added as a garnish to soups and stews to add texture and flavor.",
      ],
      [
        "Smoothies och juicer: Rädisskott kan läggas till smoothies och juicer för en näringsboost.",
        "Smoothies and Juices: Radish microgreens can be added to smoothies and juices for a nutrient boost.",
      ],
      [
        "Wok: Rädisskott kan läggas till wokrätter för en fräsch och pepprig smak.",
        "Stir-Fries: Radish microgreens can be added to stir-fries for a fresh and peppery flavor.",
      ],
    ],
    nutrientList: [
      [
        "Rika på antioxidanter: Rädisa-mikrogrönt innehåller mycket antioxidanter, vilka hjälper till att skydda kroppen mot skadliga fria radikaler och minskar risken för kroniska sjukdomar, såsom cancer, hjärtsjukdomar och Alzheimers sjukdom.",
        "Rich in Antioxidants: Radish microgreens are high in antioxidants, which help protect the body against harmful free radicals and reduce the risk of chronic diseases such as cancer, heart disease, and Alzheimer's disease.",
      ],
      [
        "Ökar immunförsvaret: Mikrogröna rädisor är rika på vitamin C, vilket är nödvändigt för ett hälsosamt immunförsvar. Vitamin C hjälper också kroppen att ta upp järn, vilket är viktigt för den allmänna hälsan.",
        "Boosts Immune System: Radish microgreens are rich in vitamin C, which is essential for a healthy immune system. Vitamin C also helps the body absorb iron, which is important for overall health.",
      ],
      [
        "Stöder matsmältningshälsan: Rädisor innehåller fibrer, vilket hjälper till att stödja matsmältningshälsan och förhindrar förstoppning. De innehåller också enzymer som hjälper till med matsmältningen.",
        "Supports Digestive Health: Radish microgreens contain fiber, which helps support digestive health and prevent constipation. They also contain enzymes that aid in digestion.",
      ],
      [
        "Antiinflammatorisk: Rädisor innehåller antiinflammatoriska föreningar som kan hjälpa till att minska inflammationen i kroppen och minska risken för kroniska sjukdomar, såsom artrit.",
        "Anti-inflammatory: Radish microgreens contain anti-inflammatory compounds that may help reduce inflammation in the body and lower the risk of chronic diseases such as arthritis.",
      ],

      [
        "Sänker kolesterolnivåerna: Rädisor innehåller föreningar som kan hjälpa till att sänka kolesterolnivåerna och minska risken för hjärtsjukdomar.",
        "Lowers Cholesterol: Radish microgreens contain compounds that may help lower cholesterol levels and reduce the risk of heart disease.",
      ],
      [
        "Högt innehåll av näringsämnen: Rädisor är en koncentrerad näringskälla som innehåller höga halter av vitaminer och mineraler, såsom vitamin A, vitamin K, kalcium och järn.",
        "High in Nutrients: Radish microgreens are a concentrated source of nutrients, containing high levels of vitamins and minerals such as vitamin A, vitamin K, calcium, and iron.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'box'
  },
  {
    name: ["Senap", "Mustard"], //
    mustard: true,
    id: "mustard-tray",
    link: "mustard",
    latin: "Brassica juncea",
    tray: 320,
    size: [800],
    ean: ["17350127310606"],
    storeData: ["Kylvara", "Refrigeration"],
    storeTemp: 8,
    leadTime: 14,
    water: 3,
    shelfLife: "7-10",
    emissionFactor: 1,
    rateStore: 2,
    rateTaste: 1,
    rateSpicy: 2.5,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fmustard.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmustard3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmustard1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmustard2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmustard3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmustard4.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fmustard5.jpg?alt=media",
    ],
    subtitle: [
      "Rik på vitaminer, antioxidanter och mineraler. Med sin söta senapssmak gör den sig bra i sallader eller till kött.",
      "Rich in vitamins, antioxidants and minerals. With its sweet mustard taste, it works well in salads or with meat.",
    ],
    menuName: ["Senap - 20 x 40g", "Mustard - 20 x 40g"],
    color: [
      "Vit-gula stjälkar med gröna blad",
      "White & yellow stems with green leafs",
    ],
    flavor: ["Söt senapssmak", "Sweet mustard taste"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Senapsskott är det perfekta tillskottet till alla kök eller restauranger som vill höja sina rätter med en touch av djärv och syrlig smak. Våra senapsskott skördas vid toppen av färskhet, vilket gör dem till en näringsspäckad superfood som ger en smakupplevelse till varje måltid. Med en distinkt pepprig smak och en öm, krispig konsistens är dessa mikrogrönsaker perfekta för sallader, smörgåsar, soppor och mycket mer.",
        "Mustard microgreens, the perfect addition to any kitchen or restaurant seeking to elevate their dishes with a touch of bold and tangy flavor. Our mustard microgreens are harvested at the peak of freshness, making them a nutrient-packed superfood that adds a burst of flavor to any meal. With a distinct peppery taste and a tender, crisp texture, these microgreens are perfect for salads, sandwiches, soups, and more.",
      ],
      [
        "Dessa microgreens ger inte bara smak och konsistens till dina måltider utan ger också en mängd näringsfördelar. De har högt innehåll av antioxidanter, vitaminer och mineraler, inklusive vitamin C, vitamin K och kalium.",
        "Not only do these microgreens add flavor and texture to your meals, but they also bring a wealth of nutritional benefits. They are high in antioxidants, vitamins, and minerals, including vitamin C, vitamin K, and potassium.",
      ],
      [
        "Sammantaget är våra senapsskott det perfekta valet för alla som vill sätta en läcker och näringsrik touch på sina rätter. Med sin livfulla smak och många hälsofördelar kommer dessa microgreens garanterat att bli en stapelvara i ditt kök.",
        "Overall, our mustard microgreens are the perfect choice for anyone who wants to add a delicious and nutritious touch to their dishes. With their vibrant flavor and numerous health benefits, these microgreens are sure to become a staple in your kitchen.",
      ],
    ],
    kitchen: [
      [
        "Sallader: Senapsskott ger en utsökt pepprig smak till vilken sallad som helst. De passar särskilt bra tillsammans med andra grönsaker, såsom ruccola, spenat eller sallad.",
        "Salads: Mustard microgreens add a delicious peppery flavor to any salad. They pair particularly well with other greens, such as arugula, spinach, or lettuce.",
      ],
      [
        "Smörgåsar och wraps: Lägg till en handfull senapsskott till din smörgås eller wrap för extra smak och konsistens.",
        "Sandwiches and wraps: Add a handful of mustard microgreens to your sandwich or wrap for an extra pop of flavor and texture.",
      ],
      [
        "Soppor och grytor: Använd senapsskott för att garnera dina soppor eller grytor, eller lägg till dem i slutet för en extra smakupplevelse.",
        "Soups and stews: Use mustard microgreens to garnish your soups or stews, or add them in at the end for an extra burst of flavor.",
      ],
      [
        "Tacos och burritos: Senapsskott kan ge en fin crunch och smak till dina mexikanskinspirerade rätter.",
        "Tacos and burritos: Mustard microgreens can add a nice crunch and flavor to your Mexican-inspired dishes.",
      ],
      [
        "Smoothies och juicer: Prova att lägga till en handfull senapsskott till dina smoothies eller juicer för en näringstillskott och en unik smak.",
        "Smoothies and juices: Try adding a handful of mustard microgreens to your smoothies or juices for a boost of nutrition and a unique flavor.",
      ],
      [
        "Omeletter och frittatas: Tillsätt lite senapsskott till dina äggrätter för extra smak och näring.",
        "Omelets and frittatas: Add some mustard microgreens to your egg dishes to add some extra flavor and nutrition.",
      ],
    ],
    nutrientList: [
      [
        "Rik på antioxidanter: Senapsskott innehåller en mängd olika antioxidanter, som kan hjälpa till att neutralisera skadliga fria radikaler i kroppen och minska risken för kroniska sjukdomar.",
        "Rich in antioxidants: Mustard microgreens contain a variety of antioxidants, which can help to neutralize harmful free radicals in the body and reduce the risk of chronic diseases.",
      ],
      [
        "Högt innehåll av vitaminer och mineraler: Senapsskott är en bra källa till viktiga vitaminer och mineraler, såsom vitamin C, vitamin K, kalium, kalcium och järn.",
        "High in vitamins and minerals: Mustard microgreens are a good source of important vitamins and minerals, such as vitamin C, vitamin K, potassium, calcium, and iron.",
      ],
      [
        "Kan hjälpa till att minska inflammation: Föreningarna i senapsskott har antiinflammatoriska egenskaper, vilket kan hjälpa till att minska inflammation i kroppen och lindra symtom på tillstånd som artrit och astma.",
        "May help reduce inflammation: The compounds in mustard microgreens have anti-inflammatory properties, which may help to reduce inflammation in the body and alleviate symptoms of conditions such as arthritis and asthma.",
      ],
      [
        "Kan förbättra hjärthälsa: Senapsskott innehåller föreningar som har visat sig hjälpa till att sänka blodtrycket, minska kolesterolnivåerna och förbättra hjärthälsa.",
        "May improve heart health: Mustard microgreens contain compounds that have been shown to help lower blood pressure, reduce cholesterol levels, and improve overall heart health.",
      ],
      [
        "Kan stödja matsmältningen: Fibrerna och andra föreningar som finns i senapsskott kan hjälpa till att främja hälsosam matsmältning och förhindra problem som förstoppning och uppblåsthet.",
        "May support digestion: The fiber and other compounds found in mustard microgreens may help to promote healthy digestion and prevent issues such as constipation and bloating.",
      ],
      [
        "Kan ha anticancereffekter: Vissa studier har föreslagit att föreningarna som finns i senapsmikrogrönt kan ha anticanceregenskaper, vilket potentiellt kan hjälpa till att förhindra tillväxt och spridning av cancerceller.",
        "May have anticancer effects: Some studies have suggested that the compounds found in mustard microgreens may have anticancer properties, potentially helping to prevent the growth and spread of cancer cells.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'box'
  },
  {
    name: ["Krasse Mix #1", "Microgreens Mix #1"], //
    mustard: true,
    id: "cressMix-cups",
    link: "cressmix-cups",
    latin: " - ",
    tray: 280,
    size: [800],
    ean: ["7350127311804"],
    storeData: ["Kylvara", "Refrigeration"],
    leadTime: 14,
    storeTemp: 8,
    water: 3,
    shelfLife: "7-10",
    emissionFactor: 1.1,
    rateStore: 2,
    rateTaste: 2.5,
    rateSpicy: 2.5,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fasianmix.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix4.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix5.jpeg?alt=media",
    ],
    subtitle: [
      "En blandning av Tatsoi, Pak Choi, Röd Komatsuna, Kålrot & Rädisa som tillsammans skapar ljuva toner av Asien i både färg och smak.",
      "A mixture of Tatsoi, Pak Choi, Red Komatsuna, Cabbage & Radish that together create sweet tones of Asia in both color and taste.",
    ],
    menuName: ["Krassemix #1 - 12 krukor SRS", "Cress Mix #1 - 12 cups SRS"],
    color: [
      "En ljuvlig mix av grönt, lila & rött",
      "A vibrant mix of green, purple & red",
    ],
    flavor: ["Söt med peppriga inslag", "Sweet with hints of pepper"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Denna blandning är packad med en mängd olika smaker och näringsämnen, inklusive Tatsoi, Pak Choi, Rova, Komatsuna och Rädisa, vilket ger en mångsidig blandning av mikrogrönsaker att njuta av.",
        "The Asian Mix - This blend is packed with a variety of flavors and nutrients, including Tatsoi, Pak Choi, Turnip, Komatsuna, and Radish, providing a diverse mix of microgreens to enjoy.",
      ],
      [
        "Tatsoi, även känd som spenatsenap, är en bladgrönsak som innehåller mycket vitamin A, C och K, samt kalcium och järn. Dess peppriga smak ger en pigg kick till sallader, smörgåsar och röror.",
        "Tatsoi, also known as spinach mustard, is a leafy green that is high in vitamin A, C, and K, as well as calcium and iron. Its peppery flavor adds a zesty kick to salads, sandwiches, and stir-fries.",
      ],
      [
        "Pak Choi, även känd som kinakål, är en vanlig ingrediens i det asiatiska köket. Den är låg i kalorier och hög i fiber, vitamin C och vitamin K. Dess milda smak och krispiga konsistens gör den perfekt för röror, soppor och grytor.",
        "Pak Choi, also known as Chinese cabbage, is a common ingredient in Asian cuisine. It is low in calories and high in fiber, vitamin C, and vitamin K. Its mild flavor and crispy texture make it perfect for stir-fries, soups, and stews.",
      ],
      [
        "Mikrogrönt av kålrot ger en mild, söt och lätt bitter smak, vilket ger en uppfriskande smak och crunch till dina måltider. De är höga i vitamin C, K och fiber och kan användas i en mängd olika rätter.",
        "Turnip microgreens offer a mild, sweet, and slightly bitter taste, adding a refreshing flavor and crunch to your meals. They are high in vitamin C, K, and fiber, and can be used in a variety of dishes.",
      ],
      [
        "Komatsuna, även känd som japansk senapsspenat, innehåller mycket C-vitamin och järn. Dess smak är en kombination av spenat och senapsgrönsaker, med en syrlig och lite bitter smak. Den kan ätas rå i sallader eller tillagas i soppor och röror.",
        "Komatsuna, also known as Japanese mustard spinach, is high in vitamin C and iron. Its flavor is a combination of spinach and mustard greens, with a tangy and slightly bitter taste. It can be eaten raw in salads or cooked in soups and stir-fries.",
      ],
      [
        "Rädisor är rika på antioxidanter, C-vitamin och folat och ger en mild pepprig smak till varje maträtt. De är perfekta för smörgåsar, sallader och garnering av rätter.",
        "Radish microgreens are rich in antioxidants, vitamin C, and folate, and add a mild peppery flavor to any dish. They are perfect for sandwiches, salads, and garnishing dishes.",
      ],
      [
        "Vår asiatiska blandning är den perfekta mixen för dig som vill uppleva en blandning av smaker i samma förpackning. Odlade med precision och omsorg, ser vi till att våra mikrogröna är av högsta kvalitet och fräschhet. Prova vår Asiatiska Mix idag och njut av smaken av Asien i ditt eget kök!",
        "Our Asian Mix is the perfect blend for those who want to experience a mix of flavors and nutrients in one package. Grown with precision and care, we ensure that our microgreens are of the highest quality and freshness. Try our Asian Mix today and enjoy the taste of Asia in your own kitchen!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Asiatisk Mix är en bra tillsats till salladen. Dess variation av texturer och smaker kan lägga till ett extra lager av komplexitet och näring till din maträtt.",
        "Salads: The Asian Mix is a great addition to any salad. Its variety of textures and flavors can add an extra layer of complexity and nutrition to your dish.",
      ],
      [
        "Smörgåsar och wraps: Lägg till den asiatiska mixen på din favoritmacka eller wrap för extra crunch och smak.",
        "Sandwiches and wraps: Add the Asian Mix to your favorite sandwich or wrap for an extra crunch and flavor.",
      ],
      [
        "Wok-rätter: Asian Mix är en perfekt tillsats till wok-rätter. Dess milda och lätt bittra smak passar bra med en mängd olika såser och proteiner.",
        "Stir-fries: The Asian Mix is a perfect addition to stir-fries. Its mild and slightly bitter flavor pairs well with a variety of sauces and proteins.",
      ],
      [
        "Soppor: Lägg till den asiatiska mixen i soppor och grytor för en extra lager av smak och näring.",
        "Soups: Add the Asian Mix to soups and stews for an extra layer of flavor and nutrition.",
      ],
      [
        "Garnering: Använd Asiatisk Mix som garnering till valfri maträtt. Dess färgglada och unika utseende kan lägga till färg och intresse till din tallrik.",
        "Garnish: Use the Asian Mix as a garnish for any dish. Its colorful and unique appearance can add a pop of color and interest to your plate.",
      ],
      [
        "Smoothies: Tillsätt en handfull av den asiatiska mixen till ditt favoritsmoothierecept för en extra boost av näring och smak.",
        "Smoothies: Add a handful of the Asian Mix to your favorite smoothie recipe for an extra boost of nutrition and flavor.",
      ],
    ],
    nutrientList: [
      [
        "Rikt på vitaminer och mineraler: Var och en av mikrogrönsakerna i denna blandning är en stor källa till vitaminer och mineraler, som vitamin A, C, K och järn. Dessa näringsämnen är viktiga för att upprätthålla ett hälsosamt immunförsvar, starka ben och god syn.",
        "Rich in vitamins and minerals: Each of the microgreens in this blend is a great source of vitamins and minerals, such as vitamin A, C, K, and iron. These nutrients are essential for maintaining a healthy immune system, strong bones, and good vision.",
      ],
      [
        "Lågt kaloriinnehåll: Asiatiska mixen innehåller få kalorier, vilket gör dem till ett bra komplement till alla hälsosamma dieter. De är också höga i fiber, vilket hjälper till att hålla dig mätt och nöjd.",
        "Low in calories: The Asian Mix microgreens are low in calories, making them a great addition to any healthy diet. They are also high in fiber, which helps to keep you feeling full and satisfied.",
      ],
      [
        "Stödjer hjärthälsan: Kålrot innehåller föreningar som har visat sig ha en positiv effekt på hjärthälsan genom att hjälpa till att sänka kolesterolnivåerna.",
        "Supports heart health: Turnip microgreens contain compounds that have been shown to have a positive effect on heart health by helping to lower cholesterol levels.",
      ],
      [
        "Rik på antioxidanter: Rädisan i den asiatiska mixen innehåller särskilt många antioxidanter, som hjälper till att skydda dina celler från skador orsakade av fria radikaler.",
        "Antioxidant-rich: Radish microgreens in the Asian Mix are particularly high in antioxidants, which help to protect your cells from damage caused by free radicals.",
      ],
      [
        "Förbättrar matsmältningen: Pak Choi-mikrogrönt innehåller mycket fiber och kan hjälpa till att stödja en hälsosam matsmältning. De innehåller också föreningar som har visat sig ha en positiv effekt på tarmhälsan.",
        "Boosts digestion: Pak Choi microgreens are high in fiber and can help to support healthy digestion. They also contain compounds that have been shown to have a positive effect on gut health.",
      ],
      [
        "Hjälper till att reglera blodsockret: Tatsoi-mikrogrönt innehåller föreningar som kan hjälpa till att reglera blodsockernivåerna och kan vara fördelaktiga för personer med typ 2-diabetes.",
        "Helps to regulate blood sugar: Tatsoi microgreens contain compounds that can help to regulate blood sugar levels and may be beneficial for people with type 2 diabetes.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'cups'
  },
  {
    name: ["Krasse Mix #2", "Microgreens Mix #2"], //
    mustard: true,
    id: "cressMix2-cups",
    link: "cressmix-cups",
    latin: " - ",
    tray: 280,
    size: [800],
    ean: ["7350127311811"],
    storeData: ["Kylvara", "Refrigeration"],
    leadTime: 14,
    storeTemp: 8,
    water: 3,
    shelfLife: "7-10",
    emissionFactor: 1.1,
    rateStore: 2,
    rateTaste: 2.5,
    rateSpicy: 2.5,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fasianmix.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix4.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix5.jpeg?alt=media",
    ],
    subtitle: [
      "En blandning av Tatsoi, Pak Choi, Röd Komatsuna, Kålrot & Rädisa som tillsammans skapar ljuva toner av Asien i både färg och smak.",
      "A mixture of Tatsoi, Pak Choi, Red Komatsuna, Cabbage & Radish that together create sweet tones of Asia in both color and taste.",
    ],
    menuName: ["Krassemix #2 - 12 krukor SRS", "Cress Mix #2 - 12 cups SRS"],
    color: [
      "En ljuvlig mix av grönt, lila & rött",
      "A vibrant mix of green, purple & red",
    ],
    flavor: ["Söt med peppriga inslag", "Sweet with hints of pepper"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Denna blandning är packad med en mängd olika smaker och näringsämnen, inklusive Tatsoi, Pak Choi, Rova, Komatsuna och Rädisa, vilket ger en mångsidig blandning av mikrogrönsaker att njuta av.",
        "The Asian Mix - This blend is packed with a variety of flavors and nutrients, including Tatsoi, Pak Choi, Turnip, Komatsuna, and Radish, providing a diverse mix of microgreens to enjoy.",
      ],
      [
        "Tatsoi, även känd som spenatsenap, är en bladgrönsak som innehåller mycket vitamin A, C och K, samt kalcium och järn. Dess peppriga smak ger en pigg kick till sallader, smörgåsar och röror.",
        "Tatsoi, also known as spinach mustard, is a leafy green that is high in vitamin A, C, and K, as well as calcium and iron. Its peppery flavor adds a zesty kick to salads, sandwiches, and stir-fries.",
      ],
      [
        "Pak Choi, även känd som kinakål, är en vanlig ingrediens i det asiatiska köket. Den är låg i kalorier och hög i fiber, vitamin C och vitamin K. Dess milda smak och krispiga konsistens gör den perfekt för röror, soppor och grytor.",
        "Pak Choi, also known as Chinese cabbage, is a common ingredient in Asian cuisine. It is low in calories and high in fiber, vitamin C, and vitamin K. Its mild flavor and crispy texture make it perfect for stir-fries, soups, and stews.",
      ],
      [
        "Mikrogrönt av kålrot ger en mild, söt och lätt bitter smak, vilket ger en uppfriskande smak och crunch till dina måltider. De är höga i vitamin C, K och fiber och kan användas i en mängd olika rätter.",
        "Turnip microgreens offer a mild, sweet, and slightly bitter taste, adding a refreshing flavor and crunch to your meals. They are high in vitamin C, K, and fiber, and can be used in a variety of dishes.",
      ],
      [
        "Komatsuna, även känd som japansk senapsspenat, innehåller mycket C-vitamin och järn. Dess smak är en kombination av spenat och senapsgrönsaker, med en syrlig och lite bitter smak. Den kan ätas rå i sallader eller tillagas i soppor och röror.",
        "Komatsuna, also known as Japanese mustard spinach, is high in vitamin C and iron. Its flavor is a combination of spinach and mustard greens, with a tangy and slightly bitter taste. It can be eaten raw in salads or cooked in soups and stir-fries.",
      ],
      [
        "Rädisor är rika på antioxidanter, C-vitamin och folat och ger en mild pepprig smak till varje maträtt. De är perfekta för smörgåsar, sallader och garnering av rätter.",
        "Radish microgreens are rich in antioxidants, vitamin C, and folate, and add a mild peppery flavor to any dish. They are perfect for sandwiches, salads, and garnishing dishes.",
      ],
      [
        "Vår asiatiska blandning är den perfekta mixen för dig som vill uppleva en blandning av smaker i samma förpackning. Odlade med precision och omsorg, ser vi till att våra mikrogröna är av högsta kvalitet och fräschhet. Prova vår Asiatiska Mix idag och njut av smaken av Asien i ditt eget kök!",
        "Our Asian Mix is the perfect blend for those who want to experience a mix of flavors and nutrients in one package. Grown with precision and care, we ensure that our microgreens are of the highest quality and freshness. Try our Asian Mix today and enjoy the taste of Asia in your own kitchen!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Asiatisk Mix är en bra tillsats till salladen. Dess variation av texturer och smaker kan lägga till ett extra lager av komplexitet och näring till din maträtt.",
        "Salads: The Asian Mix is a great addition to any salad. Its variety of textures and flavors can add an extra layer of complexity and nutrition to your dish.",
      ],
      [
        "Smörgåsar och wraps: Lägg till den asiatiska mixen på din favoritmacka eller wrap för extra crunch och smak.",
        "Sandwiches and wraps: Add the Asian Mix to your favorite sandwich or wrap for an extra crunch and flavor.",
      ],
      [
        "Wok-rätter: Asian Mix är en perfekt tillsats till wok-rätter. Dess milda och lätt bittra smak passar bra med en mängd olika såser och proteiner.",
        "Stir-fries: The Asian Mix is a perfect addition to stir-fries. Its mild and slightly bitter flavor pairs well with a variety of sauces and proteins.",
      ],
      [
        "Soppor: Lägg till den asiatiska mixen i soppor och grytor för en extra lager av smak och näring.",
        "Soups: Add the Asian Mix to soups and stews for an extra layer of flavor and nutrition.",
      ],
      [
        "Garnering: Använd Asiatisk Mix som garnering till valfri maträtt. Dess färgglada och unika utseende kan lägga till färg och intresse till din tallrik.",
        "Garnish: Use the Asian Mix as a garnish for any dish. Its colorful and unique appearance can add a pop of color and interest to your plate.",
      ],
      [
        "Smoothies: Tillsätt en handfull av den asiatiska mixen till ditt favoritsmoothierecept för en extra boost av näring och smak.",
        "Smoothies: Add a handful of the Asian Mix to your favorite smoothie recipe for an extra boost of nutrition and flavor.",
      ],
    ],
    nutrientList: [
      [
        "Rikt på vitaminer och mineraler: Var och en av mikrogrönsakerna i denna blandning är en stor källa till vitaminer och mineraler, som vitamin A, C, K och järn. Dessa näringsämnen är viktiga för att upprätthålla ett hälsosamt immunförsvar, starka ben och god syn.",
        "Rich in vitamins and minerals: Each of the microgreens in this blend is a great source of vitamins and minerals, such as vitamin A, C, K, and iron. These nutrients are essential for maintaining a healthy immune system, strong bones, and good vision.",
      ],
      [
        "Lågt kaloriinnehåll: Asiatiska mixen innehåller få kalorier, vilket gör dem till ett bra komplement till alla hälsosamma dieter. De är också höga i fiber, vilket hjälper till att hålla dig mätt och nöjd.",
        "Low in calories: The Asian Mix microgreens are low in calories, making them a great addition to any healthy diet. They are also high in fiber, which helps to keep you feeling full and satisfied.",
      ],
      [
        "Stödjer hjärthälsan: Kålrot innehåller föreningar som har visat sig ha en positiv effekt på hjärthälsan genom att hjälpa till att sänka kolesterolnivåerna.",
        "Supports heart health: Turnip microgreens contain compounds that have been shown to have a positive effect on heart health by helping to lower cholesterol levels.",
      ],
      [
        "Rik på antioxidanter: Rädisan i den asiatiska mixen innehåller särskilt många antioxidanter, som hjälper till att skydda dina celler från skador orsakade av fria radikaler.",
        "Antioxidant-rich: Radish microgreens in the Asian Mix are particularly high in antioxidants, which help to protect your cells from damage caused by free radicals.",
      ],
      [
        "Förbättrar matsmältningen: Pak Choi-mikrogrönt innehåller mycket fiber och kan hjälpa till att stödja en hälsosam matsmältning. De innehåller också föreningar som har visat sig ha en positiv effekt på tarmhälsan.",
        "Boosts digestion: Pak Choi microgreens are high in fiber and can help to support healthy digestion. They also contain compounds that have been shown to have a positive effect on gut health.",
      ],
      [
        "Hjälper till att reglera blodsockret: Tatsoi-mikrogrönt innehåller föreningar som kan hjälpa till att reglera blodsockernivåerna och kan vara fördelaktiga för personer med typ 2-diabetes.",
        "Helps to regulate blood sugar: Tatsoi microgreens contain compounds that can help to regulate blood sugar levels and may be beneficial for people with type 2 diabetes.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'cups'
  },
  {
    name: ["Krasse Mix #3", "Microgreens Mix #3"], //
    mustard: true,
    id: "cressMix3-cups",
    link: "cressmix-cups",
    latin: " - ",
    tray: 280,
    size: [800],
    ean: ["7350127311811"],
    storeData: ["Kylvara", "Refrigeration"],
    leadTime: 14,
    storeTemp: 8,
    water: 3,
    shelfLife: "7-10",
    emissionFactor: 1.1,
    rateStore: 2,
    rateTaste: 2.5,
    rateSpicy: 2.5,
    icon: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flogo%2Fasianmix.png?alt=media",
    logo: "",
    url: "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix3.jpeg?alt=media",
    urlArr: [
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix1.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix2.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix3.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix4.jpeg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix5.jpeg?alt=media",
    ],
    subtitle: [
      "En blandning av Tatsoi, Pak Choi, Röd Komatsuna, Kålrot & Rädisa som tillsammans skapar ljuva toner av Asien i både färg och smak.",
      "A mixture of Tatsoi, Pak Choi, Red Komatsuna, Cabbage & Radish that together create sweet tones of Asia in both color and taste.",
    ],
    menuName: ["Krassemix #3 - 12 krukor SRS", "Cress Mix #3 - 12 cups SRS"],
    color: [
      "En ljuvlig mix av grönt, lila & rött",
      "A vibrant mix of green, purple & red",
    ],
    flavor: ["Söt med peppriga inslag", "Sweet with hints of pepper"],
    texture: ["Krispig och fräsch", "Crisp, fresh"],
    description: [
      [
        "Denna blandning är packad med en mängd olika smaker och näringsämnen, inklusive Tatsoi, Pak Choi, Rova, Komatsuna och Rädisa, vilket ger en mångsidig blandning av mikrogrönsaker att njuta av.",
        "The Asian Mix - This blend is packed with a variety of flavors and nutrients, including Tatsoi, Pak Choi, Turnip, Komatsuna, and Radish, providing a diverse mix of microgreens to enjoy.",
      ],
      [
        "Tatsoi, även känd som spenatsenap, är en bladgrönsak som innehåller mycket vitamin A, C och K, samt kalcium och järn. Dess peppriga smak ger en pigg kick till sallader, smörgåsar och röror.",
        "Tatsoi, also known as spinach mustard, is a leafy green that is high in vitamin A, C, and K, as well as calcium and iron. Its peppery flavor adds a zesty kick to salads, sandwiches, and stir-fries.",
      ],
      [
        "Pak Choi, även känd som kinakål, är en vanlig ingrediens i det asiatiska köket. Den är låg i kalorier och hög i fiber, vitamin C och vitamin K. Dess milda smak och krispiga konsistens gör den perfekt för röror, soppor och grytor.",
        "Pak Choi, also known as Chinese cabbage, is a common ingredient in Asian cuisine. It is low in calories and high in fiber, vitamin C, and vitamin K. Its mild flavor and crispy texture make it perfect for stir-fries, soups, and stews.",
      ],
      [
        "Mikrogrönt av kålrot ger en mild, söt och lätt bitter smak, vilket ger en uppfriskande smak och crunch till dina måltider. De är höga i vitamin C, K och fiber och kan användas i en mängd olika rätter.",
        "Turnip microgreens offer a mild, sweet, and slightly bitter taste, adding a refreshing flavor and crunch to your meals. They are high in vitamin C, K, and fiber, and can be used in a variety of dishes.",
      ],
      [
        "Komatsuna, även känd som japansk senapsspenat, innehåller mycket C-vitamin och järn. Dess smak är en kombination av spenat och senapsgrönsaker, med en syrlig och lite bitter smak. Den kan ätas rå i sallader eller tillagas i soppor och röror.",
        "Komatsuna, also known as Japanese mustard spinach, is high in vitamin C and iron. Its flavor is a combination of spinach and mustard greens, with a tangy and slightly bitter taste. It can be eaten raw in salads or cooked in soups and stir-fries.",
      ],
      [
        "Rädisor är rika på antioxidanter, C-vitamin och folat och ger en mild pepprig smak till varje maträtt. De är perfekta för smörgåsar, sallader och garnering av rätter.",
        "Radish microgreens are rich in antioxidants, vitamin C, and folate, and add a mild peppery flavor to any dish. They are perfect for sandwiches, salads, and garnishing dishes.",
      ],
      [
        "Vår asiatiska blandning är den perfekta mixen för dig som vill uppleva en blandning av smaker i samma förpackning. Odlade med precision och omsorg, ser vi till att våra mikrogröna är av högsta kvalitet och fräschhet. Prova vår Asiatiska Mix idag och njut av smaken av Asien i ditt eget kök!",
        "Our Asian Mix is the perfect blend for those who want to experience a mix of flavors and nutrients in one package. Grown with precision and care, we ensure that our microgreens are of the highest quality and freshness. Try our Asian Mix today and enjoy the taste of Asia in your own kitchen!",
      ],
    ],
    kitchen: [
      [
        "Sallader: Asiatisk Mix är en bra tillsats till salladen. Dess variation av texturer och smaker kan lägga till ett extra lager av komplexitet och näring till din maträtt.",
        "Salads: The Asian Mix is a great addition to any salad. Its variety of textures and flavors can add an extra layer of complexity and nutrition to your dish.",
      ],
      [
        "Smörgåsar och wraps: Lägg till den asiatiska mixen på din favoritmacka eller wrap för extra crunch och smak.",
        "Sandwiches and wraps: Add the Asian Mix to your favorite sandwich or wrap for an extra crunch and flavor.",
      ],
      [
        "Wok-rätter: Asian Mix är en perfekt tillsats till wok-rätter. Dess milda och lätt bittra smak passar bra med en mängd olika såser och proteiner.",
        "Stir-fries: The Asian Mix is a perfect addition to stir-fries. Its mild and slightly bitter flavor pairs well with a variety of sauces and proteins.",
      ],
      [
        "Soppor: Lägg till den asiatiska mixen i soppor och grytor för en extra lager av smak och näring.",
        "Soups: Add the Asian Mix to soups and stews for an extra layer of flavor and nutrition.",
      ],
      [
        "Garnering: Använd Asiatisk Mix som garnering till valfri maträtt. Dess färgglada och unika utseende kan lägga till färg och intresse till din tallrik.",
        "Garnish: Use the Asian Mix as a garnish for any dish. Its colorful and unique appearance can add a pop of color and interest to your plate.",
      ],
      [
        "Smoothies: Tillsätt en handfull av den asiatiska mixen till ditt favoritsmoothierecept för en extra boost av näring och smak.",
        "Smoothies: Add a handful of the Asian Mix to your favorite smoothie recipe for an extra boost of nutrition and flavor.",
      ],
    ],
    nutrientList: [
      [
        "Rikt på vitaminer och mineraler: Var och en av mikrogrönsakerna i denna blandning är en stor källa till vitaminer och mineraler, som vitamin A, C, K och järn. Dessa näringsämnen är viktiga för att upprätthålla ett hälsosamt immunförsvar, starka ben och god syn.",
        "Rich in vitamins and minerals: Each of the microgreens in this blend is a great source of vitamins and minerals, such as vitamin A, C, K, and iron. These nutrients are essential for maintaining a healthy immune system, strong bones, and good vision.",
      ],
      [
        "Lågt kaloriinnehåll: Asiatiska mixen innehåller få kalorier, vilket gör dem till ett bra komplement till alla hälsosamma dieter. De är också höga i fiber, vilket hjälper till att hålla dig mätt och nöjd.",
        "Low in calories: The Asian Mix microgreens are low in calories, making them a great addition to any healthy diet. They are also high in fiber, which helps to keep you feeling full and satisfied.",
      ],
      [
        "Stödjer hjärthälsan: Kålrot innehåller föreningar som har visat sig ha en positiv effekt på hjärthälsan genom att hjälpa till att sänka kolesterolnivåerna.",
        "Supports heart health: Turnip microgreens contain compounds that have been shown to have a positive effect on heart health by helping to lower cholesterol levels.",
      ],
      [
        "Rik på antioxidanter: Rädisan i den asiatiska mixen innehåller särskilt många antioxidanter, som hjälper till att skydda dina celler från skador orsakade av fria radikaler.",
        "Antioxidant-rich: Radish microgreens in the Asian Mix are particularly high in antioxidants, which help to protect your cells from damage caused by free radicals.",
      ],
      [
        "Förbättrar matsmältningen: Pak Choi-mikrogrönt innehåller mycket fiber och kan hjälpa till att stödja en hälsosam matsmältning. De innehåller också föreningar som har visat sig ha en positiv effekt på tarmhälsan.",
        "Boosts digestion: Pak Choi microgreens are high in fiber and can help to support healthy digestion. They also contain compounds that have been shown to have a positive effect on gut health.",
      ],
      [
        "Hjälper till att reglera blodsockret: Tatsoi-mikrogrönt innehåller föreningar som kan hjälpa till att reglera blodsockernivåerna och kan vara fördelaktiga för personer med typ 2-diabetes.",
        "Helps to regulate blood sugar: Tatsoi microgreens contain compounds that can help to regulate blood sugar levels and may be beneficial for people with type 2 diabetes.",
      ],
    ],
    cool: "cool1",
    ec: "ec1",
    hum: "hum1",
    light: "light1",
    ph: "ph1",
    pump: "pump1",
    spray: "spray1",
    temp: "temp1",
    waterTemp: "waterTemp1",
    type: 'cups'
  },
];


export const partnerInfo = {
  store: [
    {
      name: "Ica Maxi Högskolan",
      address: "Kristian IV:s väg 3, 302 50 Halmstad",
      image:
        "https://assets.icanet.se/t_epi6imagevault,f_auto/imagevaultfiles/id_131319/cf_259/ica_maxi.png?",
      url: "https://www.ica.se/butiker/maxi/halmstad/maxi-ica-stormarknad-hogskolan-2308/start/",
    },
    {
      name: "Ica Maxi Flygstaden",
      address: "Karlsrovägen 81, 302 41 Halmstad",
      image:
        "https://assets.icanet.se/t_epi6imagevault,f_auto/imagevaultfiles/id_131319/cf_259/ica_maxi.png?",
      url: "https://www.ica.se/butiker/maxi/halmstad/maxi-ica-stormarknad-flygstaden-11066/start/",
    },
    {
      name: "Ica Nära Frösakull",
      address: "Kungsvägen 35, 302 70 Halmstad",
      image:
        "https://assets.icanet.se/image/upload/v1556106962/EPI-assets/questmedia.webp",
      url: "https://www.ica.se/butiker/nara/halmstad/ica-nara-frosakull-11590/start/",
    },
    {
      name: "Ica Maxi Mellbystrand",
      address: "Söderleden 1, 312 61 Mellbystrand",
      image:
        "https://assets.icanet.se/t_epi6imagevault,f_auto/imagevaultfiles/id_131319/cf_259/ica_maxi.png?",
      url: "https://www.ica.se/butiker/maxi/laholm/maxi-ica-stormarknad-mellbystrand-2323/start/",
    },
  ],
  retail: [
    {
      name: "Grönsakshallen Sorunda Syd",
      address: "5246 Röinge, 281 92 Hässleholm",
      image:
        "https://cdn.cookielaw.org/logos/50614bc3-91ac-4b2f-84c3-fd243e4459a0/3b9020d9-b290-4a6f-a897-e41ffee2e5a0/1d95fd3c-0443-47c3-8eaf-9aaee9bd8741/ghlogo.png",
      url: "https://www.gronsakshallen.se/kontakta-oss/kontakt-syd",
    },
    {
      name: "Hembergs",
      address: "Svetsaregatan 18, 302 50 Halmstad",
      image:
        "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fpartner%2Fhembergs.png?alt=media",
      url: "http://www.hembergs.se",
    },
    {
      name: "Martin & Servera",
      address: "Fraktgatan 2, 305 92 Holm",
      image:
        "https://media.martinservera.se/f_auto,q_auto/logo/martin_servera_kvadrat.png",
      url: "https://www.martinservera.se",
    },
  ],
  other: [
    {
      name: "Lindas Ekocafe",
      address: "Röinge Kronogården 321, 305 94 Halmstad",
      image:
        "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fpartner%2Flindasekocafe.jpg?alt=media",
      url: "https://lindasekocafe.se",
    },
    {
      name: "Reko Ring Flygstaden Halmstad",
      address: "Kundvägen 12, Halmstad",
      image:
        "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fpartner%2Frekoringflyg.jpg?alt=media",
      url: "https://www.facebook.com/groups/218492036195132/",
    },
    {
      name: "Reko Ring Folkparken Halmstad",
      address: "Folkparken, Halmstad",
      image:
        "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fpartner%2Frekoringfolk.jpg?alt=media",
      url: "https://www.facebook.com/groups/rekoringfolkparken/",
    },
    {
      name: "Reko Ring Granngården Halmstad",
      address: "Gjutaregatan 12, Halmstad",
      image:
        "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fpartner%2Frekoringgrann.jpg?alt=media",
      url: "https://www.facebook.com/groups/REKOringHalmstad/",
    },
  ],
};

export const monitorArray: MonitorOuterArrayIF = [
  {
    title: "Städning",
    data: [
      {
        type: "date",
        valueType: "",
        name: "Städa Toalett",
        id: "clean-toilet",
      },
      {
        type: "date",
        valueType: "",
        name: "Städa Hylla #1",
        id: "clean-system1",
      },
      {
        type: "date",
        valueType: "",
        name: "Städa Hylla #2",
        id: "clean-system2",
      },
      {
        type: "date",
        valueType: "",
        name: "Städa Mörker #1",
        id: "clean-dark1",
      },
      {
        type: "date",
        valueType: "",
        name: "Städa Mörker #2",
        id: "clean-dark2",
      },
      {
        type: "date",
        valueType: "",
        name: "Städa Kyl #1",
        id: "clean-cool1",
      },
      {
        type: "date",
        valueType: "",
        name: "Städa Kyl #2",
        id: "clean-cool2",
      },
      {
        type: "date",
        valueType: "",
        name: "Städa Kyl #3",
        id: "clean-cool3",
      },
    ],
  },
  {
    title: "System",
    data: [
      {
        type: "date",
        valueType: "",
        name: "Byta Vatten Hylla #1",
        id: "water-system1",
      },
      {
        type: "date",
        valueType: "",
        name: "Byta Vatten Hylla #2",
        id: "water-system2",
      },
      {
        type: "date",
        valueType: "",
        name: "Kalibrera pH Hylla #1",
        id: "calibrate-ph1",
      },
      {
        type: "date",
        valueType: "",
        name: "Kalibrera pH Hylla #2",
        id: "calibrate-ph2",
      },
      {
        type: "date",
        valueType: "",
        name: "Kalibrera EC Hylla #1",
        id: "calibrate-ec1",
      },
      {
        type: "date",
        valueType: "",
        name: "Kalibrera EC Hylla #2",
        id: "calibrate-ec2",
      },
    ],
  },
  {
    title: "Manuella Tester",
    data: [
      {
        type: "value",
        valueType: "pH",
        name: "Manuellt pH-test System #1",
        id: "ph-test1",
      },
      {
        type: "value",
        valueType: "pH",
        name: "Manuellt pH-test System #2",
        id: "ph-test2",
      },
      {
        type: "value",
        valueType: "EC",
        name: "Manuellt EC-test System #1",
        id: "ec-test1",
      },
      {
        type: "value",
        valueType: "EC",
        name: "Manuellt EC-test System #2",
        id: "ec-test2",
      },
      {
        type: "value",
        valueType: "°C",
        name: "Manuell temperatur Kyl #1",
        id: "coolTemp-test1",
      },
      {
        type: "value",
        valueType: "°C",
        name: "Manuell temperatur Kyl #2",
        id: "coolTemp-test2",
      },
      {
        type: "value",
        valueType: "°C",
        name: "Manuell temperatur Kyl #3",
        id: "coolTemp-test3",
      },
      {
        type: "value",
        valueType: "°C",
        name: "Manuell temperatur Hylla #1",
        id: "temp-test1",
      },
      {
        type: "value",
        valueType: "°C",
        name: "Manuell temperatur Hylla #2",
        id: "temp-test2",
      },
      {
        type: "value",
        valueType: "°C",
        name: "Manuell temperatur Lokal",
        id: "temp-test3",
      },
    ],
  },
];
