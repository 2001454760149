import React, { useEffect, useState } from "react";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

import { firebaseDB } from "../firebase/firestore";

import { ActivePlantIF } from "../interfaces/Interfaces";
import "./dashboard.css";

type TsProps = {
  plant: ActivePlantIF;
};

const plantedByArr = [
  "Ann-Helen",
  "Adrian",
  "Jimmy",
  "Ann-Helen & Adrian",
  "Ann-Helen & Jimmy",
  "Adrian & Jimmy",
  "Ann-Helen, Adrian & Jimmy",
];

const ChangePlantedBy = ({ plant }: TsProps) => {
  const updatePlantedBy = async (newName: string) => {
    if (plant.plantedBy === newName) {
      return;
    }

    await updateDoc(
      doc(firebaseDB, "plants", plant.id, "plantData", plant.firebaseId),
      {
        plantedBy: newName,
      }
    );
  };

  return (
    <div className="AddPlantDialogTop">
      {plantedByArr.map((name, index) => (
        <Button
          onClick={() => updatePlantedBy(name)}
          key={name + index}
          size="small"
          color="inherit"
          variant={plant.plantedBy === name ? "contained" : "outlined"}
          sx={{ borderRadius: "14px", margin: "5px" }}
        >
          {name}
        </Button>
      ))}
    </div>
  );
};

export default ChangePlantedBy;
