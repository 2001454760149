import React, { useEffect, useState, ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getDatabase, ref as refDB, set } from "firebase/database";

import { firebaseStorage, firebaseDatabase } from "../../../components/firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import { firebaseAuth } from "../../../components/firebase/auth";
import "./documents.css";

function DocumentsUpload() {
  const [user, loading, error] = useAuthState(firebaseAuth);

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [loadedFile, setLoadedFile] = useState<File | null>(null);
  const [uploadLoading, setUpload] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };
  const handleLoad = (event: File) => {
    console.log(event);
    setLoadedFile(event);
  };

    

  const handleLoadSave = async () => {
    if (loadedFile === null) {
      return;
    }

    try {
      setUpload(true);
      const tempId = Date.now();
      const storageRef = ref(firebaseStorage, "documents/" + loadedFile.name);

      const upload = await uploadBytes(storageRef, loadedFile);
      const uploadUrl = await getDownloadURL(upload.ref);

      const tempObj = {
        name: name,
        fileName: loadedFile.name,
        id: tempId,
        url: uploadUrl,
        category: category,
      };

      await set(refDB(firebaseDatabase, "documents/" + tempId), tempObj);

      setName('')
      setCategory('')
      setLoadedFile(null)
      setUpload(false);
    } catch (error) {
      console.log(error);
      setUpload(false);
    }
  };

  return (
    <div className="DocumentsUploadOuter">
      <div className="DarkPlantsHeader">
        <p className="PlantListTitle">Ladda upp</p>
      </div>
      <div className="DocumentsUploadInputOuter">
        <TextField
          sx={{ width: "40%", margin: 0 }}
          required
          id="outlined-required"
          label="Namn"
          value={name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
          size="small"
        />
        <FormControl sx={{ width: "25%", margin: 0 }} size="small">
          <InputLabel id="demo-simple-select-label">Kategori</InputLabel>
          <Select value={category} label="Kategori" onChange={handleChange}>
            <MenuItem value="VERT.">VERT.</MenuItem>
            <MenuItem value="Näring">Näring</MenuItem>
            <MenuItem value="Rengöring">Rengöring</MenuItem>
            <MenuItem value="Växtvård">Växtvård</MenuItem>
            <MenuItem value="Övrigt">Övrigt</MenuItem>
          </Select>
        </FormControl>
        <IconButton
          sx={{ width: "10%", margin: 0 }}
          color="inherit"
          component="label"
        >
          <input
            hidden
            type="file"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              //@ts-ignore
              handleLoad(e.target.files[0])
            }
          />
          <AttachFileIcon />
        </IconButton>
        <Button
          sx={{ width: "20%", margin: 0 }}
          color="inherit"
          variant="outlined"
          disabled={
            name === "" ||
            category === "" ||
            loadedFile === null ||
            uploadLoading
          }
          onClick={handleLoadSave}
        >
          {uploadLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Spara"
          )}
        </Button>
      </div>
    </div>
  );
}

export default DocumentsUpload;
