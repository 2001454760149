import React from "react";
import "./Misc.css";

export default function Terms() {
  return (
    <div className="RoutesOuter">
      <div className="RoutesInner">
        <p className="RoutesTitle">TERMS AND CONDITIONS</p>
        <p className="RoutesSubTitle">Last Updated: [JANUARY 2025]</p>
        <p className="RoutesTextTitle">1. ACCEPTANCE OF TERMS</p>
        <p className="RoutesText">
          By accessing or using the VERT. platform (the "Platform"), including
          any content or services offered by VERT.farm Sverige AB.
          ("VERT."), you agree to be bound by these Terms and Conditions (the
          "Terms"). If you do not agree to these Terms, you may not use the
          Platform.
        </p>

        <p className="RoutesTextTitle">2. USE OF THE PLATFORM</p>
        <p className="RoutesText">
          2.1. Eligibility: You must be at least 18 years old and have the legal
          capacity to enter into these Terms to use the Platform.
          <br />
          <br />
          2.2. User Accounts: You may be required to create an account to access
          certain features of the Platform. You are responsible for maintaining
          the confidentiality of your account information and for all activities
          that occur under your account.
          <br />
          <br />
          2.3. User-Generated Content: The Platform allows users to create
          listings, post reviews, and submit other content ("User Content"). You
          retain ownership of your User Content, but you grant VERT. a
          non-exclusive, royalty-free, worldwide, perpetual license to use,
          reproduce, modify, adapt, publish, translate, distribute, and display
          your User Content in connection with the Platform and VERT's
          business.
        </p>

        <p className="RoutesTextTitle">3. USER CONDUCT</p>
        <p className="RoutesText">
          3.1. Prohibited Content: You may not post or submit any User Content
          that is unlawful, harmful, threatening, abusive, harassing,
          defamatory, vulgar, obscene, libelous, invasive of another's privacy,
          hateful, or racially, ethnically, or otherwise objectionable.
          <br />
          <br />
          3.2. Intellectual Property: You may not post or submit any User
          Content that infringes upon the intellectual property rights of
          others.
          <br />
          <br />
          3.3. Other Prohibited Activities: You may not use the Platform to
          engage in any activity that is harmful to VERT. or its users,
          including but not limited to:
          <br />
          <br />
          Impersonating any person or entity Hacking or attempting to gain
          unauthorized access to the Platform Introducing viruses or other
          malicious code Interfering with the proper functioning of the Platform
          Engaging in any activity that violates these Terms
        </p>

        <p className="RoutesTextTitle">4. VERT'S RIGHTS</p>
        <p className="RoutesText">
          4.1. Moderation: VERT. reserves the right to moderate and remove any
          User Content that violates these Terms.
          <br />
          <br />
          4.2. Termination of Access: VERT. may terminate or suspend your
          access to the Platform at any time for any reason, including but not
          limited to your violation of these Terms.
        </p>

        <p className="RoutesTextTitle">5. DISCLAIMER OF WARRANTIES</p>
        <p className="RoutesText">
          The Platform And All Content And Services Offered Through The Platform
          Are Provided "As Is" And "As Available" Without Warranty Of Any Kind,
          Either Express Or Implied, Including But Not Limited To The Implied
          Warranties Of Merchantability, Fitness For A Particular Purpose, And
          Non-Infringement. VERT. Does Not Warrant That The Platform Will Be
          Uninterrupted Or Error-Free, That Defects Will Be Corrected, Or That
          The Platform Or The Server That Makes It Available Are Free Of Viruses
          Or Other Harmful Components.
        </p>

        <p className="RoutesTextTitle">6. LIMITATION OF LIABILITY</p>
        <p className="RoutesText">
          In No Event Shall VERT. Be Liable For Any Direct, Indirect,
          Incidental, Special, Consequential, Or Exemplary Damages Arising Out
          Of Or In Connection With The Use Of Or Inability To Use The Platform,
          Including But Not Limited To Damages For Loss Of Profits, Goodwill,
          Use, Data, Or Other Intangible Losses, Even If VERT. Has Been Advised
          Of The Possibility Of Such Damages.
        </p>

        <p className="RoutesTextTitle">7. INDEMNIFICATION</p>
        <p className="RoutesText">
          You agree to indemnify and hold harmless VERT. and its affiliates,
          officers, directors, employees, agents, and licensors from and against
          any and all claims, liabilities, damages, losses, costs, expenses, or
          fees (including reasonable attorneys' fees) arising out of or in
          connection with your use of the Platform or your violation of these
          Terms.
        </p>

        <p className="RoutesTextTitle">8. DATA PROTECTION AND PRIVACY</p>
        <p className="RoutesText">
          8.1. General Data Protection Regulation (GDPR): VERT. is committed to
          complying with the GDPR and all applicable data protection laws. We
          collect, process, and store personal data in accordance with our
          Privacy Policy, which is incorporated into these Terms by reference.
          <br />
          <br />
          8.2. User Rights: You have the right to access, rectify, erase,
          restrict processing, object to processing, and data portability
          concerning your personal data. You can exercise these rights by
          contacting us through the information provided in our Privacy Policy.
          <br />
          <br />
          8.3. Data Security: VERT. implements appropriate technical and
          organizational measures to protect your personal data against
          unauthorized access, disclosure, alteration, or destruction.
        </p>

        <p className="RoutesTextTitle">
          9. GOVERNING LAW AND DISPUTE RESOLUTION
        </p>
        <p className="RoutesText">
          These Terms shall be governed by and construed in accordance with the
          laws of Thailand. Any dispute arising out of or in connection with
          these Terms shall be resolved through binding arbitration in
          accordance with the rules of the Thai Arbitration Institute.
        </p>

        <p className="RoutesTextTitle">10. CHANGES TO THESE TERMS</p>
        <p className="RoutesText">
          VERT. reserves the right to modify these Terms at any time. Any
          changes to these Terms will be effective immediately upon posting on
          the Platform.
          <br />
          <br />
          IMPORTANT NOTICE: By using the VERT. platform, you acknowledge that
          you have read, understood, and agreed to these Terms and Conditions,
          including our Privacy Policy and the provisions regarding data
          protection and privacy.
        </p>
      </div>
    </div>
  );
}
