import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../firebase/auth";
import { useDeviceState } from "../utils/globalStates";

import MenuDrawer from "./MenuDrawer";

import "./Header.css";

function Header() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [authModal, setAuthModal] = useState(false);
  const deviceState = useDeviceState();

  useEffect(() => {
    if (user !== null) {
      setAuthModal(false);
    }
  }, [user, authModal]);

  const styles = {
    drawerButton: {
      color: "white",
      backgroundColor: "transparent",
      position: "fixed",
      zIndex: 9999,
      top: "24px",
      right: "calc(5% - 1px)",
      transition: "transform 0.3s ease",
      borderRadius: "8px",
      padding: "3px",
      height: "35px",
      width: "35px",
      "@media (min-width: 900px)": {
        right: "20px",
      },
    },
  };

  return (
    <div className="Header">
      {user !== null && !loading && (
        <IconButton
          sx={{
            ...styles.drawerButton,
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        >
          {isDrawerOpen ? (
            <ArrowForwardIosOutlinedIcon color="inherit" />
          ) : (
            <MenuIcon color="inherit" />
          )}
        </IconButton>
      )}

      <Drawer
        anchor={"right"}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{
          zIndex: 9998,
          transition: "transform 0.3s ease",
          "& .MuiDrawer-paper": {
            backgroundColor: "#44a99a",
          },
        }}
      >
        <div className="appMenu">
            <IconButton
          sx={{
            ...styles.drawerButton,
            backgroundColor: "transparent",
            right: "calc(5% - 1px)",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        >
          {isDrawerOpen ? (
            <ArrowForwardIosOutlinedIcon color="inherit" />
          ) : (
            <MenuIcon color="inherit" />
          )}
        </IconButton>
          <MenuDrawer setIsDrawerOpen={setIsDrawerOpen} />
        </div>
      </Drawer>
    </div>
  );
}

export default Header;
