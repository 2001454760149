import React, { useEffect, useState } from "react";
import {
  getAuth,
  signOut,
  browserSessionPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { collectionGroup, query, where, getDocs } from "firebase/firestore";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@mui/material/Tabs";

import DashBoardActive from "./DashboardActive";
import DashBoardHistory from "./DashboardHistory";
import DashBoardHarvest from "./DashboardHarvest";
import DashBoardOrders from "./DashboardOrders";
import DashBoardDocuments from "./DashboardDocuments";
import DashBoardControl from "./DashboardControl";
import { firebaseAuth } from "../../components/firebase/auth";
import { firebaseDB } from "../../components/firebase/firestore";
import { plantInfoNew } from "../../components/utils/enums";
import Labels from "./labels/Labels";
import "./dashboard.css";

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#44a99a',
    },
  },
});

function DashBoard() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState("1");
  const [authLoading, setAuthLoading] = useState(true);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (user === null && loading === false) {
      navigate("/login");
    } else if (user !== null && loading === false) {
      setAuthLoading(false);
    }
  }, [loading]);

  return (
    <div className="DashBoard">
      {authLoading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className="DashBoardInner">
          <div className="DashBoardInnerTabs">
            <TabContext value={tabValue}>
              <ThemeProvider theme={theme}>
                <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{maxWidth: '90%'}}
                indicatorColor={'primary'}
              >
                <Tab label="Etiketter" value="1" />
                <Tab label="Aktiva" value="8" />
                <Tab label="Skördat" value="2" />
                <Tab label="Levererat" value="3" />
                <Tab label="Beställningar" value="4" />
                <Tab label="Egenkontroll" value="5" />
                <Tab label="Historik" value="6" />
                <Tab label="Dokumentation" value="7" />
              </Tabs>
              </ThemeProvider>
              <TabPanel
                sx={{
                  width: "100%",
                  padding: 0,
                  maxWidth: "1200px",
                }}
                value="8"
              >
                <DashBoardActive />
              </TabPanel>
              <TabPanel
                sx={{
                  width: "100%",
                  padding: 0,
                  maxWidth: "1200px",
                }}
                value="2"
              >
                <DashBoardHarvest />
              </TabPanel>
              <TabPanel
                sx={{
                  width: "100%",
                  padding: 0,
                  maxWidth: "1200px",
                }}
                value="3"
              >
                <DashBoardActive />
              </TabPanel>
              <TabPanel
                sx={{
                  width: "100%",
                  padding: 0,
                  paddingTop: "20px",
                  maxWidth: "1200px",
                }}
                value="4"
              >
                <DashBoardOrders />
              </TabPanel>
              <TabPanel
                sx={{
                  width: "100%",
                  padding: 0,
                  paddingTop: "20px",
                  maxWidth: "1200px",
                }}
                value="5"
              >
                <DashBoardControl />
              </TabPanel>
              <TabPanel
                sx={{
                  width: "100%",
                  padding: 0,
                  paddingTop: "20px",
                  maxWidth: "1200px",
                }}
                value="6"
              >
                <DashBoardHistory />
              </TabPanel>
              <TabPanel
                sx={{
                  width: "100%",
                  padding: 0,
                  paddingTop: "20px",
                  maxWidth: "1200px",
                }}
                value="7"
              >
                <DashBoardDocuments />
              </TabPanel>
              <TabPanel
                sx={{
                  width: "100%",
                  padding: 0,
                  paddingTop: "20px",
                  maxWidth: "1200px",
                }}
                value="1"
              >
                <Labels />
              </TabPanel>
            </TabContext>
          </div>
        </div>
      )}
    </div>
  );
}

export default DashBoard;
