import { useEffect, useState } from "react";
import logo from "../../static/social.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useAuthState } from "react-firebase-hooks/auth";
import CircularProgress from "@mui/material/CircularProgress";
import { firebaseAuth } from "../../components/firebase/auth";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import {
  getAuth,
  signInWithCustomToken,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../components/firebase/firebaseFunctions";
import "./auth.css";

const textfieldStyle = {
  marginBottom: "20px",
  color: "#44a99a",
  borderColor: "#44a99a",
  justifySelf: "center",
  opacity: 0.99,
  "& label.Mui-focused": {
    color: "#44a99a",
    borderColor: "#44a99a",
  },
  "& .Mui-disabled": {
    color: "#44a99a",
    borderColor: "#44a99a",
  },
  "& .MuiInputLabel-root.Mui-disabled": {
    color: "#44a99a",
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    "& fieldset": {
      borderColor: "#44a99a",
    },
    "& input": {
      borderColor: "#44a99a",
      WebkitTextFillColor: "unset",
    },
    "& label": {
      color: "#44a99a",
    },
    color: "#44a99a",
    borderColor: "#44a99a",
  },
  "& label": {
    color: "#44a99a",
    borderColor: "#44a99a",
  },
  "& textarea": {
    color: "#000",
    borderColor: "#44a99a",
  },
  "& input": {
    color: "#000",
    borderColor: "#44a99a",
  },
  "& .MuiInput-underline:after": {
    borderColor: "#44a99a",
    color: "#44a99a",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#44a99a",
      color: "#44a99a",
    },
    "&:hover fieldset": {
      borderColor: "#44a99a",
      color: "#44a99a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#44a99a",
      color: "#44a99a",
    },
  },
};

const otpInputStyle = {
  container: {
    marginBottom: "10px",
    width: "calc(100%)",
    display: "flex",
    justifyContent: "space-between",
  },
  input: {
    width: "12%",
    fontSize: "1.5em",
    aspectRatio: "1/1",
    textAlign: "center",
    borderRadius: "5px",
    border: "1px solid #44a99a",
    margin: "0px",
    "&:focus": {
      borderColor: "#44a99a",
    },
    "&:hover": {
      borderColor: "#44a99a",
    },
  },
};

function AuthView() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();

  const [username, setUsername] = useState("");

  const [authLoading, setAuthLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtp, setIsOtp] = useState(false);
  const [expired, setExpired] = useState(0);
  const [countdownTicker, setCountdownTicker] = useState('0:00');

  const initLogin = async () => {
    setAuthLoading(true);

    try {
      const response = await httpsCallable(
        firebaseFunctions,
        "auth-init_cloud_login"
      )({
        email: username,
      });
      console.log(response);
      setExpired(response.data.timestamp + 300000);
      // Set otpBrowser to local storage
      localStorage.setItem("otpBrowser", response.data.browserOtp);
      setIsOtp(true);
      setAuthLoading(false);
    } catch (error) {
      console.error(error);
      setErrorMessage("An error occurred. Please try again.");
      setAuthLoading(false);
    }
  };

  const verifyOtp = async () => {
    setAuthLoading(true);

    try {
      const otpBrowser = localStorage.getItem("otpBrowser");
      if (!otpBrowser) {
        console.error("No otpBrowser found");
        setErrorMessage("Auth error. Please try again.");
        setAuthLoading(false);
        return;
      }
      const response = await httpsCallable(
        firebaseFunctions,
        "auth-verify_cloud_login"
      )({
        email: username,
        otp: otp,
        browserOtp: otpBrowser,
      });
      console.log(response);
      if (response.data.token) {
        await signInWithCustomToken(firebaseAuth, response.data.token);
      } else {
        setErrorMessage("Auth error. Please try again.");
        setAuthLoading(false);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("An error occurred. Please try again.");
      setAuthLoading(false);
    }
  }

  useEffect(() => {
    console.log("Check user");
    if (user === null && loading === false) {
      console.log("no user");
      setAuthLoading(false);
    } else if (user !== null && loading === false) {
      console.log("user found");
      navigate("/dashboard");
    }
  }, [loading]);

  useEffect(() => {
    if (user !== null) {
      navigate("/dashboard");
    }
  }, [user]);

  // Countdown timer for OTP when expired isnt 0, Display as minutes:seconds
  useEffect(() => {
    if (expired !== 0) {
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = expired - now;
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setCountdownTicker(minutes + ":" + (seconds < 10 ? "0" : "") + seconds);
        if (distance < 0) {
          setCountdownTicker("0:00");
          setExpired(0);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [expired]);

  return (
    <div className="AuthView">
      <img src={logo} className="LoginLogo" alt="logo" />
      {authLoading ? (
        <CircularProgress color="inherit" />
      ) : isOtp ? (
        <div className="AuthViewForm">
          <div className="OtpOuter">
            <OtpInput
              value={otp}
              onChange={setOtp}
              containerStyle={otpInputStyle.container}
              inputStyle={otpInputStyle.input}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
            />
          </div>
          <Button
            disabled={otp === "" || otp.length < 6}
            color="inherit"
            className="AuthViewButton"
            variant="outlined"
            onClick={verifyOtp}
          >
            Login
          </Button>
          <p className="InfoText">
              Please enter the OTP sent to your email within <b>{countdownTicker}</b>
          </p>
        </div>
      ) : (
        <div className="AuthViewForm">
          <TextField
            sx={textfieldStyle}
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            type="email"
            size="small"
            className="AuthViewInput"
            label="Email"
            variant="outlined"
            placeholder="Please enter your email to continue"
          />
          <Button
            disabled={username === ""}
            onClick={initLogin}
            color="inherit"
            className="AuthViewButton"
            variant="outlined"
          >
            Continue
          </Button>
        </div>
      )}
      {errorMessage !== "" ? <p>{errorMessage}</p> : null}
    </div>
  );
}

export default AuthView;
