import React, { useEffect, useState, ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getDatabase, ref as refDB, set } from "firebase/database";

import DocumentItem from "./DocumentItem";
import { firebaseStorage, firebaseDatabase } from "../../../components/firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import { DocumentArrayIF } from "../../../components/interfaces/Interfaces";
import "./documents.css";

type TsProps = {
  documents: DocumentArrayIF;
  headerTitle: string;
};
function DocumentList(props:TsProps) {
  const [user, loading, error] = useAuthState(firebaseAuth);


  return (
    <div className="DocumentList">
      <p className="DocumentListTitle">{props.headerTitle}</p>
      <div className="DocumentListInner">
        {props.documents.map((document, docIndex) => (
            <DocumentItem key={'doc' + docIndex} document={document}/>
        ))}
      </div>
    </div>
  );
}

export default DocumentList;
