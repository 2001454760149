import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import CircularProgress from "@mui/material/CircularProgress";

import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import { firebaseDatabase } from "../../components/firebase/firestore";
import {
  DocumentArrayIF,
  SortedDocumentArrayIF,
  SortedDocumentIF,
} from "../../components/interfaces/Interfaces";
import DocumentsUpload from "./documents/DocumentsUpload";
import DocumentList from "./documents/DocumentList";
import { firebaseAuth } from "../../components/firebase/auth";
import "./dashboard.css";

let outerLoading = false;
function DashBoardDocuments() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();

  const [documentArr, setDocumentArr] = useState<DocumentArrayIF>([]);
  const [sortedArr, setSortedArr] = useState<SortedDocumentArrayIF>([]);
  const [documentLoading, setDocumentLoading] = useState(true);

  useEffect(() => {
    const docRef = ref(firebaseDatabase, "documents/");
    const unsubscribe = onValue(docRef, (snapshot) => {
      outerLoading = true;
      let tempDocs: DocumentArrayIF = [];
      const docData = Object.entries(snapshot.val());

      for (let i = 0; i < docData.length; i++) {
        //@ts-ignore
        tempDocs.push(docData[i][1]);
      }
      setDocumentArr(tempDocs);
      setDocumentLoading(false);
      outerLoading = false;
    });

    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let tempSorted: SortedDocumentArrayIF = [];
    if (documentArr.length > 0) {
      for (let i = 0; i < documentArr.length; i++) {
        const findName = tempSorted.findIndex((e) => e.name === documentArr[i].category);
        if (findName > -1) {
          tempSorted[findName].documents.push(documentArr[i])
        } else {
            tempSorted.push({
                documents: [documentArr[i]],
                name: documentArr[i].category
            })
        }
      }
      setSortedArr(tempSorted)
    }
  }, [documentArr]);

  return (
    <div className="DashBoardTabInner">
      <div className="DashBoardDocumentsOuter">
        <DocumentsUpload />
        <div className="DarkPlantsHeader">
          <p className="PlantListTitle">Dokument</p>
        </div>
        {documentLoading ? (<CircularProgress color="inherit" />):(
            sortedArr.map((itemList, listIndex) => (
                <DocumentList key={'list' + listIndex} documents={itemList.documents} headerTitle={itemList.name}/>
            ))
        )}
      </div>
    </div>
  );
}

export default DashBoardDocuments;
