import React, {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Header from "./components/header/Header";
import Start from "./views/start/Start";
import AuthView from "./views/auth/AuthView";
import Terms from "./views/misc/Terms";
import Privacy from "./views/misc/Privacy";
import AuthListeners from "./components/listeners/AuthListeners";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/*" element={<Start />} />
        <Route path="/login" element={<AuthView />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <AuthListeners />
    </div>
  );
}

export default App;
