import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import { firebaseAuth } from "../../../components/firebase/auth";
import ReactPDF, {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
  Font,
} from "@react-pdf/renderer";
// @ts-ignore
import { saveAs } from "file-saver";
import moment from "moment";
// @ts-ignore
import QRCode from "qrcode";

import {
  ActivePlantIF,
  PlantArrayIF,
  LabelArrayIF,
  LabelIF,
  PlantInfoIF,
} from "../../../components/interfaces/Interfaces";
import { plantInfoNew } from "../../../components/utils/enums";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";
import "./labels.css";
import { set } from "firebase/database";
// @ts-ignore
import PapyrusFont from "../../../static/fonts/papyrus.ttf";
// @ts-ignore
import NoteworthyFont from "../../../static/fonts/noteworthy-bold.ttf";
// @ts-ignore
import RobotoItalicFont from "../../../static/fonts/Roboto-Italic.ttf";

Font.register({
  family: 'Papyrus',
  fonts: [
    {
      src: PapyrusFont,
    },
  ],
});
Font.register({
  family: 'Noteworthy',
  fonts: [
    {
      src: NoteworthyFont,
    },
  ],
});
Font.register({
  family: 'Roboto-Italic',
  fonts: [
    {
      src: RobotoItalicFont,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: "#fff",
  },
  section: {
    height: "50%",
    width: "25%",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    height: "100%",
    width: "100%",
  },
  date: {
    position: "absolute",
    top: "13%",
    fontSize: "11px",
    paddingLeft: "15px",
    transform: "rotate(180deg)",
  },
  qr: {
    position: "absolute",
    top: "40.5%",
    transform: "rotate(180deg)",
    width: "34px",
    height: "34px",
  },
  qrText: {
    position: "absolute",
    top: "39.95%",
    transform: "rotate(180deg)",
    width: "30.5px",
    fontSize: "3.15px",
    fontWeight: 700,
  },
  overlay: {
    position: "absolute",
    top: "39.5%",
    width: "70px",
    height: "45px",
    backgroundColor: "#fff",
  },
  overlay2: {
    position: "absolute",
    top: "17.9%",
    width: "100%",
    height: "90px",
    backgroundColor: "#fff",
  },
  infoTitle: {
    position: "absolute",
    top: "33.2%",
    transform: "rotate(180deg)",
    width: "75%",
    fontSize: "7px",
    fontWeight: 700,
    textAlign: "center",
    color: '#519989',
    fontFamily: 'Papyrus',
  },
  infoText: {
    position: "absolute",
    top: "22%",
    transform: "rotate(180deg)",
    width: "75%",
    fontSize: "5.7px",
    fontWeight: 500,
    textAlign: "center",
  },
  mustard: {
    position: "absolute",
    top: "18.5%",
    transform: "rotate(180deg)",
    width: "70%",
    fontSize: "5px",
    fontWeight: 800,
    textAlign: "center",
    color: '#519989',
  },
  noMustard: {
    position: "absolute",
    top: "18.5%",
    transform: "rotate(180deg)",
    width: "70%",
    fontSize: "4.5px",
    fontWeight: 800,
    textAlign: "center",
    color: '#519989',
  },
  infoOverlay: {
    position: "absolute",
    top: "80%",
    width: "100%",
    height: "20%",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
    alignItems: "center",
  },
  mainTitle: {
    fontSize: "18.5px",
    fontWeight: 700,
    textAlign: "center",
    color: '#5E5E5E',
    fontFamily: 'Noteworthy',
  },
  mainText: {
    fontSize: "7px",
    fontWeight: 500,
    textAlign: "center",
    color: '#5E5E5E',
    fontFamily: 'Roboto-Italic',
    width: '88%',
  },
  logoOverlay: {
    position: "absolute",
    top: "58%",
    width: "100%",
    height: "22%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  logo: {
    width: "54%",
    marginTop: "10px",
  },
});

const stylesBox = StyleSheet.create({
  page: {
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: "#fff",
  },
  section: {
    height: "50%",
    width: "25%",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    height: "100%",
    width: "100%",
  },
  date: {
    position: "absolute",
    top: "20%",
    fontSize: "17px",
    transform: "rotate(-90deg)",
  },
  qr: {
    position: "absolute",
    top: "4%",
    left: "1%",
    transform: "rotate(-90deg)",
    width: "42px",
    height: "42px",
  },
  qrText: {
    position: "absolute",
    top: "8%",
    left: "48px",
    transform: "rotate(-90deg)",
    width: "42px",
    fontSize: "3.8px",
    fontWeight: 700,
  },
  overlay: {
    position: "absolute",
    top: "4%",
    left: "0%",
    width: "55px",
    height: "55px",
    backgroundColor: "#fff",
  },
});

const stylesCups = StyleSheet.create({
  page: {
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: "#fff",
  },
  section: {
    height: "50%",
    width: "25%",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    height: "100%",
    width: "100%",
  },
  date: {
    position: "absolute",
    top: "16%",
    fontSize: "15px",
    transform: "rotate(-90deg)",
    paddingTop: "18px",
  },
  qr: {
    position: "absolute",
    top: "4%",
    left: "1%",
    transform: "rotate(-90deg)",
    width: "42px",
    height: "42px",
  },
  qrText: {
    position: "absolute",
    top: "8%",
    left: "48px",
    transform: "rotate(-90deg)",
    width: "42px",
    fontSize: "3.8px",
    fontWeight: 700,
  },
  overlay: {
    position: "absolute",
    top: "4%",
    left: "0%",
    width: "55px",
    height: "55px",
    backgroundColor: "#fff",
  },
  mustard: {
    position: "absolute",
    top: "20.5%",
    left: "32px",
    transform: "rotate(-90deg)",
    width: "60px",
    fontSize: "4.5px",
    fontWeight: 800,
    textAlign: "center",
  },
});

function Labels() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();

  const [labelArr, setLabelArr] = useState<LabelArrayIF>([]);
  const [menuArr, setMenuArr] = useState<PlantArrayIF>(plantInfoNew);
  const [date, setDate] = useState<number | null>(null);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (value: PlantInfoIF) => {
    const arrCopy = [...labelArr];
    arrCopy.push({
      value: value,
      id: value.menuName[0],
      amount: 1,
    });
    setLabelArr(arrCopy);
    console.log(arrCopy);

    const arrCopy2 = [...menuArr];
    const index2 = arrCopy2.findIndex(
      (item) => item.menuName[0] === value.menuName[0]
    );
    arrCopy2.splice(index2, 1);
    setMenuArr(arrCopy2);
  };

  const onDelete = (id: string) => {
    const arrCopy = [...labelArr];
    const index = arrCopy.findIndex((item) => item.id === id);
    arrCopy.splice(index, 1);
    setLabelArr(arrCopy);

    const arrCopy2 = [...menuArr];
    const index2 = plantInfoNew.findIndex((item) => item.menuName[0] === id);
    arrCopy2.push(plantInfoNew[index2]);
    setMenuArr(arrCopy2);
  };

  const onIncrease = (id: string) => {
    const arrCopy = [...labelArr];
    const index = arrCopy.findIndex((item) => item.id === id);
    if (arrCopy[index].amount < 500) {
      arrCopy[index].amount += 1;
    }
    setLabelArr(arrCopy);
  };

  const onDecrease = (id: string) => {
    const arrCopy = [...labelArr];
    const index = arrCopy.findIndex((item) => item.id === id);
    if (arrCopy[index].amount > 1) {
      arrCopy[index].amount -= 1;
    }
    setLabelArr(arrCopy);
  };

  const onAmountChange = (value: string, id: string) => {
    const arrCopy = [...labelArr];
    const index = arrCopy.findIndex((item) => item.id === id);
    if (value === "") {
      arrCopy[index].amount = 1;
      setLabelArr(arrCopy);
      return;
    }
    if (parseInt(value) < 1) {
      arrCopy[index].amount = 1;
      setLabelArr(arrCopy);
      return;
    }
    if (parseInt(value) > 500) {
      arrCopy[index].amount = 500;
      setLabelArr(arrCopy);
      return;
    }
    arrCopy[index].amount = parseInt(value);
    setLabelArr(arrCopy);
  };

  const createPdf = async () => {
    setPdfLoading(true);
    setErrorMessage("");

    let tempArr = [];

    const parsedDate = moment(date).format("YYYY-MM-DD");

    for (let i = 0; i < labelArr.length; i++) {
      const urlString = `https://vert.farm/farm/product/${labelArr[i].value.link}?date=${date}`;
      const qrUrl = await QRCode.toDataURL(urlString, {
        errorCorrectionLevel: "M",
        margin: 2,
        width: 100,
      });
      for (let j = 0; j < labelArr[i].amount; j++) {
        if (labelArr[i].value.type === "bag") {
          tempArr.push(
            <View style={styles.section} key={labelArr[i].id + j}>
              <Image
                style={styles.img}
                src={`https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/labels%2F${labelArr[i].value.id}.png?alt=media`}
              />
              <Text style={styles.date}>{parsedDate}</Text>
              <View style={styles.overlay} />
              <Image style={styles.qr} src={qrUrl} />
              <Text style={styles.qrText}>SPÅRA INNEHÅLLET</Text>
              <View style={styles.overlay2} />
              <Text style={styles.infoTitle}>Obesprutade grödor med minsta miljöpåverkan</Text>
              <Text style={styles.infoText}>Eftersom all vår odling sker inomhus i en kontrollerad miljö, kan vi eliminera behovet av bekämpningsmedel. Den hydroponiska odlingstekniken vi använder minskar förbrukningen utav vatten med 90%, vilket tillsammans med vår närhet till konsumenterna minskar påverkan på miljön.</Text>
              {labelArr[i].value.mustard ? (
                <Text style={styles.mustard}>INNEHÅLLER SENAP</Text>
              ) : (
                <Text style={styles.mustard}>KAN INNEHÅLLA SPÅR AV SENAP</Text>
              )}
              <View style={styles.infoOverlay} >
                <Text style={styles.mainTitle}>{labelArr[i].value.name[0]}</Text>
                <Text style={styles.mainText}>{labelArr[i].value.subtitle[0]}</Text>
              </View>
              <View style={styles.logoOverlay} >
                <Image
                style={styles.logo}
                src={labelArr[i].value.icon}
              />
              </View>
            </View>
          );
        }
        if (labelArr[i].value.type === "box") {
          tempArr.push(
            <View style={stylesBox.section} key={labelArr[i].id + j}>
              <Image
                style={stylesBox.img}
                src={`https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/labels%2F${labelArr[i].value.id}-box.png?alt=media`}
              />
              <Text style={stylesBox.date}>{parsedDate}</Text>
              <View style={stylesBox.overlay} />
              <Image style={stylesBox.qr} src={qrUrl} />
            </View>
          );
        }
        if (labelArr[i].value.type === "cups") {
          tempArr.push(
            <View style={stylesCups.section} key={labelArr[i].id + j}>
              <Image
                style={stylesCups.img}
                src={`https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/labels%2F${labelArr[i].value.id}.png?alt=media`}
              />
              <Text style={stylesCups.date}>{parsedDate}</Text>
              <Text style={stylesCups.mustard}>INNEHÅLLER SENAP</Text>
            </View>
          );
        }
      }
    }

    let MyDocument = (
        <Document>
          <Page size="A4" style={stylesBox.page}>
            {tempArr}
          </Page>
        </Document>
      );
    //<Image style={styles.qr} src={item.qrCode}/>

    const blob = await pdf(MyDocument).toBlob();
    saveAs(blob, `VERTfarm--${parsedDate}.pdf`);
    setPdfLoading(false);
  };

  const calculatePapers = () => {
    let temp = 0;
    for (let i = 0; i < labelArr.length; i++) {
      temp += labelArr[i].amount;
    }
    return (temp / 8).toFixed(3);
  };

  return (
    <div className="DashBoardTabInner">
      <div className="Labels">
        <div className="LabelsHeader">
          <FormControl
            sx={{
              width: "25%",
              margin: 0,
            }}
            size="small"
          >
            <InputLabel id="demo-simple-select-label">Lägg till</InputLabel>
            <Select label="Lägg till">
              {menuArr.map((item) => (
                <MenuItem
                  key={item.menuName[0]}
                  onClick={() => handleChange(item)}
                >
                  {item.menuName[0]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {labelArr.length > 0 ? (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Datum"
                value={date}
                onChange={(newValue) => {
                  //@ts-ignore
                  setDate(newValue.valueOf());
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          ) : null}
        </div>
        <div className="LabelArr">
          {labelArr.map((item) => (
            <div className="LabelArrItem" key={item.id}>
              <div className="LabelArrItemLogo">
                <img
                  className="LabelArrItemLogoImg"
                  src={item.value.icon}
                  alt=""
                />
              </div>
              <div className="LabelArrItemFirst">
                <p className="LabelArrItemTitle">{item.value.menuName[0]}</p>
                <div className="LabelArrItemPicker">
                  <IconButton
                    onClick={() => onDecrease(item.id)}
                    color="inherit"
                    size="large"
                  >
                    <RemoveIcon />
                  </IconButton>
                  <TextField
                    sx={{ width: "60px", textAlign: "center" }}
                    value={item.amount}
                    size="small"
                    type="text"
                    inputMode="numeric"
                    onChange={(event) =>
                      onAmountChange(event.target.value, item.id)
                    }
                  />
                  <IconButton
                    onClick={() => onIncrease(item.id)}
                    color="inherit"
                    size="large"
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </div>
              <div className="LabelArrItemLast">
                <IconButton
                  onClick={() => onDelete(item.id)}
                  color="inherit"
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
        {labelArr.length > 0 ? (
          <p className="LabelArrItemTitle">Antal A4: {calculatePapers()}</p>
        ) : null}
        <Button
          disabled={labelArr.length < 1 || date === null || pdfLoading}
          variant="outlined"
          onClick={createPdf}
          color="inherit"
        >
          Skapa PDF
        </Button>
        {errorMessage !== "" ? (
          <p className="LabelArrItemTitle">{errorMessage}</p>
        ) : null}
      </div>
    </div>
  );
}

export default Labels;
