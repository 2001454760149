import React, { useEffect } from "react";
import { useAuthStates } from "../utils/globalStates";
import { firebaseAuth } from "../firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";

export default function AuthListeners() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const authStates = useAuthStates((state) => state);

  // Check user role in custom claims after auth is loaded, sign out if not owner or admin
  useEffect(() => {
    if (user) {
      console.log(user);
      user.getIdTokenResult().then((idTokenResult) => {
        console.log(idTokenResult);
        if (
          idTokenResult.claims.role !== "owner" &&
          idTokenResult.claims.role !== "admin"
        ) {
          firebaseAuth.signOut();
        } else {
          authStates.setRole(idTokenResult.claims.role);
          authStates.setUserDataLoaded(true);
        }
      });
    }
  }, [user]);

  return <></>;
}
