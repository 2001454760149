import { create } from "zustand";

interface DeviceState {
  isMobile: boolean;
  setMobile: (bool: boolean) => void;
  deviceWidth: number;
  setDeviceWidth: (width: number) => void;
}


interface AuthStates {
  role: string;
  setRole: (role: string) => void;
  userDataLoaded: boolean;
  setUserDataLoaded: (bool: boolean) => void;
}

export const useDeviceState = create<DeviceState>((set) => ({
  isMobile: true,
  setMobile: (newState) => set((state) => ({ isMobile: newState })),
  deviceWidth: 600,
  setDeviceWidth: (width) => set((state) => ({ deviceWidth: width })),
}));

export const useAuthStates = create<AuthStates>((set) => ({
  role: "",
  setRole: (role) => set((state) => ({ role: role })),
  userDataLoaded: false,
  setUserDataLoaded: (bool) => set((state) => ({ userDataLoaded: bool })),
}));