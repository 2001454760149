import React, { useEffect, useState } from "react";
import {
  collection,
  addDoc,
  query,
  orderBy,
  limit,
  doc as fsDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import HeatMap from '@uiw/react-heat-map';

import {
  MonitorIF,
  MonitorDBIF,
} from "../../../components/interfaces/Interfaces";
import { firebaseAuth } from "../../../components/firebase/auth";
import { firebaseDB } from "../../../components/firebase/firestore";
import moment from "moment";
import 'moment/locale/sv'
import "./control.css";

moment.locale('sv');

type TsProps = {
  item: MonitorIF;
};
function ControlDateItem({ item }: TsProps) {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();

  const [dbData, setDbData] = useState<MonitorDBIF | null>(null);
  const [valueData, setValueData] = useState<any[]>([]);
  const [firebaseId, setFirebaseId] = useState("");

  const registerDate = async () => {
    console.log(Date.now());

    const tempValue = Date.now();
    await addDoc(collection(firebaseDB, "monitor", item.id, "monitorData"), {
      id: item.id,
      value: tempValue,
      date: tempValue,
    });
  };

  const deleteDate = async () => {
    await deleteDoc(
      fsDoc(firebaseDB, "monitor", item.id, "monitorData", firebaseId)
    );
  };

  useEffect(() => {
    const q = query(
      collection(firebaseDB, "monitor", item.id, "monitorData"),
      orderBy("date", "desc"),
      limit(20)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let count = 0;
      let tempArr: any[] = [];
      querySnapshot.forEach((snap) => {
        if (count === 0) {
          setDbData({
            id: snap.data().id,
            date: snap.data().date,
            value: snap.data().value,
          });
          setFirebaseId(snap.id);
        }
        count += 1;
        tempArr.push({
          date: moment(snap.data().date).format("YYYY/MM/DD"),
          count: 10,
        });
      });
      setValueData(tempArr);
    });
    return () => unsubscribe();
  }, []);

  console.log(dbData);
  return (
    <div className="ControlItemMain">
      <div className="ControlItemOuter">
      <div className="ControlItemInner">
        <p className="ControlItemTitle">{item.name}</p>
        {dbData !== null ? (
          <p className="ControlItemText">{moment(dbData.date).format("YYYY-MM-DD - HH:mm") + ' - ' + moment(dbData.date).fromNow()}</p>
        ) : null}
      </div>
      {dbData !== null && Date.now() - Number(dbData.date) > 60000 ? (
        <Button
          onClick={registerDate}
          size="small"
          color="inherit"
          variant="outlined"
        >
          REGISTRERA
        </Button>
      ) : null}
      {dbData === null ? (
        <Button
          onClick={registerDate}
          size="small"
          color="inherit"
          variant="outlined"
        >
          REGISTRERA
        </Button>
      ) : null}
      {dbData !== null && Date.now() - Number(dbData.date) < 60000 ? (
        <Button
          onClick={deleteDate}
          size="small"
          color="inherit"
          variant="outlined"
        >
          ÅNGRA
        </Button>
      ) : null}
    </div>
    {valueData.length > 0 ? (
      <HeatMap
        value={valueData}
        width={'100%'}
        startDate={new Date(moment(Date.now() - 8536000000).format("YYYY/MM/DD"))}
        legendCellSize={0}weekLabels={['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör']}
      />) : null}
    
    </div>
  );
}

export default ControlDateItem;
